<template>
	<div class="contenter">
		<el-carousel height="500px" :autoplay="false">
			<el-carousel-item v-for="(item,index) in exhibition_iamge" :key="index">
				<img class="carous-img" :src="item.exhibition_iamge" @click="toZg(item.exhibition_url)"/>
			</el-carousel-item>
		</el-carousel>
		<div class="cloudliststy">
			<!-- 切换类型 -->
			<div class="cloudliststy-top">
				<div v-for="(item,index) in cloudtypelist" :key="index" @click="cloudtype(item.type,index)"
					:class="tabindex==index ? 'cloudliststy-top-xh' : 'cloudliststy-top-xhwei'">{{item.name}}</div>
			</div>
			<!-- 详情列表 -->
			<div class="cloudliststy-bot">
				<div class="cloudliststy-bot-xh" v-for="(item,index) in lists" :key="index" @click="toZg(item.exhibition_url)">
					<img class="cloudliststy-bot-xh-img" :src="item.home_image" />
					<!-- <div class="cloudliststy-bot-xh-con">
						<img class="cloudliststy-bot-xh-tx"
							src="https://mingjiahome.zhyell.com/cloudhall/touxiang.png" />
						<div class="cloudliststy-bot-xh-jj">
							<div class="cloudliststy-bot-xh-name">郭海博</div>
							<div class="cloudliststy-bot-xh-nick">著名铁板浮雕艺术家</div>
						</div>
						<div class="shuxian"></div>
						<div class="cloudliststy-bot-xh-jies">
							郭海博，山西省浑源人。中国工艺美术大师，中国工美行业艺术大师，正高级工艺美术师，正高级美术编审，国家艺术基金专家库专家。中国美术家协会会员，中国工艺美术协会会员，中国民间文艺家协会会员。
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				type:"",
				tabindex: 0,
				cloudtypelist: [{
					type:null,
					index: 0,
					name: "全部"
				}, {
					type:0,
					index: 1,
					name: "河北文化展馆"
				}, {
					type:1,
					index: 2,
					name: "名人名家展馆"
				}, ],
				lists:[],
				exhibition_iamge:[]
			}
		},
		created() {
			this.getList('')
		},
		methods: {
			getList(type){
				let _this = this;
				this.$http.get(this.constant.loadurl.get_exhibition, {
					params: {
						type:type
					}
				}).then(res => {
					if (res.data.code == 0) {
						console.log(res.data)
						_this.lists = res.data.data.exhibition
						_this.exhibition_iamge = res.data.data.exhibition_iamge
					} else {
						
					}
				});
			},
			toZg(url){//展馆跳转
				// window.location.href = url
				window.open(url, '_blank');
			},
			// 点击云展馆类型进行切换
			cloudtype(type,index) {
				console.log(index)
				console.log(type)
				this.tabindex = index
				this.getList(type)
			}
		}
	}
</script>

<style >
	.container {
		margin: 0 auto;
	}

	.contenter {
		max-width: inherit;
		margin: 0;
		display: block !important;
	}

	/* 轮播样式 */
	.contenter .carous-img {
		width: 100%;
		height: 500px;
	}

	.contenter .el-carousel__button {
		width: 30px !important;
		height: 30px !important;
		background: url("https://mingjiahome.zhyell.com/cloudhall/lunbo.png") no-repeat !important;
		background-size: 100% 100% !important;
		opacity: 1 !important;
	}

	.contenter .el-carousel__indicator.is-active button {
		width: 30px !important;
		height: 30px !important;
		background: url("https://mingjiahome.zhyell.com/cloudhall/lunbohover.png") no-repeat !important;
		background-size: 100% 100% !important;
	}

	.contenter .el-carousel__arrow {
		display: none;
	}

	/* 分类 */
	.cloudliststy {
		margin: 40px auto;
		max-width: 1200px;
	}

	.cloudliststy-top {
		display: flex;
		border-bottom: 1px solid #333333;
		line-height: 51px;
	}

	.cloudliststy-top-xhwei {
		padding: 0px 30px;
		cursor: pointer;
		font-size: 18px;
	}

	.cloudliststy-top-xh {
		padding: 0px 30px;
		background-color: #333333;
		color: #fff;
		cursor: pointer;
		font-size: 18px;
	}

	/* 详情列表循环 */
	.cloudliststy-bot {
		margin-top: 20px;
	}

	.cloudliststy-bot-xh {
		margin: 40px 0px;
		cursor: pointer;
		box-shadow: 0px 0px 35px 0px rgba(121, 121, 121, 0.17);
	}

	.cloudliststy-bot-xh-img {
		width: 100%;
		height: 410px;
		object-fit: contain;
	}

	.cloudliststy-bot-xh-con {
		display: flex;
		padding: 20px 30px;
	}

	.cloudliststy-bot-xh-tx {
		width: 64px;
		height: 64px;
		border-radius: 32px;
		background-color: #432820;
	}

	.cloudliststy-bot-xh-jj {
		margin: 0px 20px;
		text-align: left;
		width: 20%;
	}

	.cloudliststy-bot-xh-name {
		font-size: 18px;
		margin: 8px 0px 5px 0px;
	}

	.cloudliststy-bot-xh-nick {
		font-size: 14px;
	}

	.shuxian {
		width: 1px;
		height: 60px;
		background-color: #D5D5D5;
	}

	.cloudliststy-bot-xh-jies {
		text-align: left;
		margin-left: 30px;
		color: #666;
		font-size: 14px;
		line-height: 30px;
	}
</style>