<template>
	<div class="content">
		<div class="gobacksty">
			<div @click="tztop" class="gobacksty-top">作品欣赏</div>
			<div class="gobacksty-detail">作品详情</div>
		</div>
		<!-- 详情 -->
		<div class="zpdetailtop">
			<!-- 左 -->
			<div>
				<div class="zpdetop-left" id="imgPre" @mouseleave="seeEnd">
					<!-- 原图 -->
						<div class="zpdetop-l-bot" ref="imgPre" v-if='detailArr.info'>
							<img :src="detailArr.info.images[0]" class='zpdetop-l-bot-img' />
							<!-- 遮罩层 -->
							<div class="topMask1" @mouseenter="seeBegin($event)" @mousemove="move($event)"></div>
							<!--鼠标放大镜模块-->
						</div>
						<div ref="move" v-show="isShow" class="move" :style="cursorMask"></div>
				</div>
				<!-- 放大区域  -->
				<!-- v-if="isShow" -->
				<div  id="seeDetail1"  v-show="isShow" ref="bigImg">
					<img :src="Bigurl" alt="" :style="imgMove">
				</div>
			</div>


			<!-- 右 -->
			<div class="zpdetop-rig">
				<div class="zpdetop-rig-top" v-if='detailArr.info'>
					<div style="font-weight: bold;font-size: 22px; white-space: nowrap;">{{detailArr.info.title}}</div>
					<!-- <div>作者: {{ detailArr.info.master.name }}</div> -->
					<!-- <div>风格：{{ detailArr.info.style }}</div> -->
					<!-- <div>时间：{{detailArr.info.creation_time}}</div> -->
					<div>题材: {{detailArr.info.theme}}</div>
					<!-- <div>材质: {{ detailArr.info.material }}</div> -->
					<!-- <div>尺寸: {{ detailArr.info.size }}</div> -->
					<div>标签：{{ detailArr.info.works_cate.name }}</div>
					<div>介绍：{{detailArr.info.desc}}</div>
				</div>
				<div class="zpdetop-rig-bot">
					<div class="zpdetop-r-bot-grgw" @click="homeMj(detailArr.info.master.id)">个人官网></div>
					<div style="text-align: center;" v-if='detailArr.info'>
						<img class="zpdetop-r-bot-img" :src="detailArr.info.master.profiles_img" />
						<div class="zpdetop-r-bot-tit">{{ detailArr.info.master.name }}</div>
						<div class="zpdetop-r-bot-con">{{ detailArr.info.master.lable }}</div>
					</div>
					<div class="zpdetop-r-bot-more">
						<div class="zpdetop-r-bot-more-l">相关作品</div>
						<div class="zpdetop-r-bot-more-r">更多></div>
					</div>
					<div class="zpdetop-r-bot-moreimg" v-if="detailArr.related">
						<img v-for="(item,index) in detailArr.related" :key="index" :src="item.images[0]" @click='xgzp(item)' />
					</div>
				</div>
			</div>
		</div>
		<!-- 作品推荐 -->
		<div class="zptjlist">
			<div class="zptjlist-tit">—— 为你推荐 ——</div>
			<div class="zptjlist-list">
				<div class="xsSwiper swiper">
					<img class="zptjlist-list-img1" src="https://mingjiahome.zhyell.com/wzDetail/zuo.png" />
					<div class="swiper-wrapper swiper-wrapper-zuopin">
						<div class="swiper-slide  sListZp2 sListZp3" v-for="(item, index) in detailArr.works" :key="index"
							@click='xgzp(item)'>
							<img :src="item.images[0]" alt="" class='zptjlist-xh-img'>
							<div class="zptjlist-xh-cont">{{item.title}}</div>
							<div class="zptjlist-xh-cont zptjlist-names">{{item.master.name}}</div>
						</div>
						<div v-show='detailArr.works.length < 1' style='text-align: center;width: 100%;'>
							<el-empty description="暂无作品"></el-empty>
						</div>
					</div>
					<img class="zptjlist-list-img2" src="https://mingjiahome.zhyell.com/wzDetail/you.png" />
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Swiper from 'swiper/bundle'
	import 'swiper/swiper-bundle.css'
	export default {
		data() {
			return {
				currentPage: 1, //当前页数
				id: '',
				detailArr: [],
				url: '',
				Bigurl: '', //放大镜区域的图片资源
				//上面两个是初始值
				urlIndex: -1, //urlIndex用于在图片列表中
				isShow: 0,
				cursorMask: {
					transform: ''
				}, //使用CSS3控制原图上鼠标预览区域的移动
				imgMove: {
					transform: ''
				}, //使用
				imageSizeW:'',
				imageSizeH:''
			}
		},
		mounted() {
			if (this.$route.query.id) {
				this.id = this.$route.query.id
			}
			this.works_info()
		},
		methods: {
			//详情数据
			works_info() {
				this.$http.get(this.constant.loadurl.get_works_info + '?id=' + this.id).then(res => {
					this.detailArr = res.data.data
					this.$nextTick(() => {
						var swiper = new Swiper('.swiper', {
							slidesPerView: 4,
							// slidesPerView: 4.5,
							spaceBetween: 4,
							navigation: {
								nextEl: '.zptjlist-list-img2',
								prevEl: '.zptjlist-list-img1',
							},

						});
					})
				})
			},
			xgzp(r) {
				this.id = r.id
				this.works_info()
			},
			homeMj(id) {
				this.$router.push({
					path: '/FamousIndex',
					query: {
						id: id
					}
				})

			},
			// 返回上一页
			tztop() {
				this.$router.push({
					name: 'worklist'
				})
			},
			imgSize(){
				var img = new Image()
				let width = ""
				img.src =  this.detailArr.info.images[0]
				this.imageSizeW = img.width
				this.imageSizeH = img.height
			},
			move(e) {
				
				 
				var x = e.offsetX - 100;
				var y = e.offsetY - 100;
				if (x < 0) {
					x = 0;
				} else if (x > 599) {
					x = 599;
				}

				if (y < 0) {
					y = 0;
				}else if (y > 430) {
					y = 430;
				}
				this.cursorMask.transform = `translate(${x}px, ${y}px)`;
				this.imgMove.transform = `translate(-${2*x}px, -${2*y}px)`; //向反方向成比例移动
			},
			seeBegin(e) {
				// this.imgSize()
				this.Bigurl = this.detailArr.info.images[0];
				this.isShow = 1;
			},
			seeEnd() {
				console.log('鼠标移除')
				//鼠标移出原图区域时，清空相关信息
				// default
				this.Bigurl = " ";
				this.left = 0;
				this.top = 0;
				this.isShow = 0;
			}
		}
	}
</script>
<style scoped>
	.content {
		padding: 0px 0px 50px 0px;
		max-width: 1200px;
	}

	.gobacksty {
		max-width: 1200px;
		/* margin: 30px auto; */
		margin: 30px auto;
		height: 21px;
		line-height: 21px;
		display: flex;
		font-size: 14px;
		cursor: pointer;
	}

	.gobacksty-top {
		background-color: #F6F6F6;
		color: #000;
		padding: 0px 20px;
	}

	.gobacksty-detail {
		background: url("https://mingjiahome.zhyell.com/jdba/btn2.png?e=1690855556&token=MRD8xdDk5PZlBXaqUXd2Bsb9UpawP7NevFYBSWW5:0E65RhLsQpu5Mbl5vjrEgitq0uY=") no-repeat;
		background-size: 100% 100%;
		width: 114px;
		height: 21px;
		text-align: center;
		color: #fff;
	}

	/* 详情 */
	.zpdetailtop {
		position: relative;
		margin: 0 auto;
		display: flex;
		width: 100%;
		justify-content: space-between;
	}

	.zpdetop-left {
		position: relative;
		background-color: #F8F8F8;
		/* width: 880px; */
		height: 600px;
		/* 	width:599px; */
		padding: 40px 50px;
	}

	.zpdetop-l-top {
		display: flex;
		font-size: 14px;
		color: #000;
		line-height: 36px;
		justify-content: flex-end;
	}

	.zpdetop-l-top img {
		width: 36px;
		height: 36px;
		margin: 0px 3px;
	}

	.zpdetop-l-bot {
		background-color: #FFFFFF;
		box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.23);
		/* padding: 20px; */
		/* margin: 30px 0px 0px 0px; */
		height: 599px;
	}

	.zpdetop-l-bot-img {
		/* width: 720px; */
		width:599px; 
		height: 100%;
		object-fit: contain;
	}

	.zpdetop-rig {
		width: 307px;
	}

	.zpdetop-rig-top {
		/* width: 100%; */
		padding: 20px;
		background: #FFFFFF;
		box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.15);
	}

	.zpdetop-rig-top div {
		font-size: 16px;
		color: #333;
		line-height: 30px;
	}

	.zpdetop-rig-bot {
		/* width: 100%; */
		padding: 20px;
		margin-top: 20px;
		background: #FFFFFF;
		box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.15);
		position: relative;
	}

	.zpdetop-r-bot-grgw {
		background: rgba(186, 54, 53, 0.1);
		color: #BA3635;
		font-size: 14px;
		line-height: 25px;
		text-align: center;
		width: 80px;
		position: absolute;
		top: 20px;
		right: 10px;
	}

	.zpdetop-r-bot-img {
		width: 79px;
		height: 80px;
		margin-top: 20px;
	}

	.zpdetop-r-bot-tit {
		font-size: 18px;
		color: #000;
		line-height: 40px;
		font-weight: bold;
	}

	.zpdetop-r-bot-con {
		font-size: 14px;
		color: #000;
		line-height: 40px;
	}

	.zpdetop-r-bot-more {
		display: flex;
		justify-content: space-between;
	}

	.zpdetop-r-bot-more-l {
		font-size: 16px;
		color: #000;

	}

	.zpdetop-r-bot-more-r {
		font-size: 14px;
		color: #999;
	}

	.zpdetop-r-bot-moreimg {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		text-decoration: none;
		padding: 0px 5px;
	}

	.zpdetop-r-bot-moreimg img {
		margin-top: 20px;
		height: 120px;
		width: 120px;
	}

	/* 作品推荐list */
	.zptjlist {
		margin: 40px 0px;
		/* text-align: center; */
	}

	.zptjlist-tit {
		color: #000;
		font-size: 24px;
		line-height: 40px;
		margin-bottom: 30px;
		text-align: center;
	}

	.zptjlist-list {
		display: flex;
		position: relative;
	}

	.xsSwiper {
		overflow: hidden;
	}

	.zptjlist-xh-img {
		height: 280px;
		width: 270px;
		object-fit: contain;
	}

	.sListZp3 {
		margin: 0;
	}

	.zptjlist-xh-cont {
		text-align: left;
		font-size: 18px;
		color: #333;
		margin: 5px 0px;
		font-family: '宋体';
	}

	.zptjlist-names {
		font-size: 15px;
		padding: 5px 8px;
	}

	.zptjlist-list-img1 {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: -50px;
		width: 30px;
		height: 30px;
		align-items: center;
		z-index: 100;
	}

	.zptjlist-list-img2 {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: -50px;
		width: 30px;
		height: 30px;
		z-index: 100;
	}





	#seeDetail1 {
		/*注意定位，父相子绝*/
		position: absolute;
		background-color: aliceblue;
		width:599px;
		height: 800px;
		top: 0%;
		left: 60%;
		overflow: hidden;
		z-index: 111;
		/*隐藏图片溢出查看框之外的部分*/
		/* border: 2px solid #f90; */
		background-position: 0 0;
		background-repeat: no-repeat;
	}

	#seeDetail1 img {
		position: absolute;
		/*这里注意下，初始时候将查看框内待放大图的左上角定位与查看框的左顶点处，也即其定位原点处*/
		top: 0;
		left: 0;
		background-repeat: no-repeat;

		/*我自己的图片设置的宽高是450×450，所以这里放大部分的图片的宽高设置为原图的四倍*/
		/*这个倍数需要注意，后面进行坐标变换的时候要用上*/
		width: 1700px;
		height: 1600px;
		object-fit: contain;
	}

	/*鼠标查看区域 —— 也即需要放大局部放大查看的区域*/
	.move {
		position: absolute;

		/*初始定位*/
		left: 0;
		top: 0;

		width: 200px;
		height: 200px;
		/* cursor: move; */
		/* background: rgba(142, 223, 255, 0.407); */
		/* background: rgba(142, 223, 255, 0.407); */
	}

	.topMask1 {

		/*覆盖在放大图的原图表面上的一个遮罩层*/
		width: 599px;
		height: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 5;
	}
</style>