<template>
   <div class="continuer">
      <div class="sx">
         <!-- <div class="bor_bottom">
            <el-radio-group v-model="title_name" text-color="#fff" fill="#333333" size="medium" :border="false"
               @input="ChangeTitle">
               <el-radio-button label="名人名家"></el-radio-button>
               <el-radio-button label="名家作品"></el-radio-button>
               <el-radio-button label="名家工作室"></el-radio-button>
            </el-radio-group>
         </div> -->
         <div class="screenDiv">
            <div class="mj_shaixuan">
               <div class="mjsx">名人名家筛选</div>
               <div class='clear' @click='clearCate'>清除筛选条件</div>
            </div>
            <div class="allFenLei">
               <div class="FenLeiBox">
                  <div class="tag">分类:</div>
                  <div>
                     <el-radio-group v-model="title_name" text-color="#fff" fill="#A53124" size="small" :border="false">
                        <div v-for="(item, idx) in fenleiArr" :key="idx" class="LeiName" @input="ChangeTitle(item)">
                           <el-radio-button :label="item.name"></el-radio-button>
                        </div>
                     </el-radio-group>
                  </div>
               </div>
               <div class="FenLeiBox top_bottom">
                  <div class="tag">地区:</div>
                  <div>
                     <el-radio-group v-model="city_name" text-color="#fff" fill="#A53124" size="small" :border="false">
                        <div v-for="(item, idx) in cityArr" :key="idx" class="LeiName" @input="ChangeCity(item)">
                           <el-radio-button :label="item.text"></el-radio-button>
                        </div>
                     </el-radio-group>
                  </div>
               </div>
               <div class="FenLeiBox">
                  <div class="tag">索引:</div>
                  <div>
                     <el-radio-group v-model="Letter" text-color="#fff" fill="#A53124" size="small" :border="false">
                        <div v-for="(item, idx) in LetterArr" :key="idx" class="LeiName" @input="ChangeLetter(item)">
                           <el-radio-button :label="item"></el-radio-button>
                        </div>
                     </el-radio-group>
                  </div>
               </div>
            </div>
         </div>
         <div class="mrList">
            <div class="allData">
               共
               <span>{{ total }} </span>
               条数据
            </div>
            <div class="ListDiv_mj" ref='listMj' >
               <div v-for="(item, index) in NameOrZpList" :key="index" :class="item.name ? 'yuansu' :'defaults' " @click="detailMj(item)"
                  v-show="NameOrZpList.length > 0"  >
                  <img :src="item.profiles_img" alt="" >
                  <div>{{ item.name }}</div>
               </div>
							 <div v-show='NameOrZpList.length < 1' style='text-align: center;width: 100%;'>
								 <el-empty description="暂无数据"></el-empty>
							 </div>
            </div>
         </div>
         <div class="FenYe">
            <el-pagination :current-page='page' :page-size='limit' prev-text="上一页" :background="true" next-text="下一页"
               :page-sizes="pageCount" :total="total" @current-change='currentChange'>
            </el-pagination>
         </div>
      </div>
      <Footnote></Footnote>
   </div>
</template>

<script>
import Footnote from '@/components/Footnote.vue'
import cityList from '@/config/city.json'
export default {
   components: {
      Footnote
   },
   name: "index",
   data() {
      return {
         title_name: '全部',// 作品分类
         city_name: '全部',//城市名称
				 searchname:'',
         cityCode: '', // 市
				 province_code:'',//省
         titleid: '',
         Letter: '全部',
				 page:1,
				 limit:30,
         pageCount: [10, 20, 30, 40, 50],
         fenleiArr: [],
         cityArr: [],
         LetterArr: ['全部', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
         NameOrZpList: [],
				 total:0
      }
   },
   created() {
	

   },
   mounted() {
		
		 this.cityArr = [...new Set(cityList)]
		 this.mrmj_cate()
		 // 首次进入
		 if(this.$route.params.searchText || this.$route.params.Citycode || this.$route.params.Scode ){
		 	this.searchname = this.$route.params.searchText
			this.cityCode = this.$route.params.Citycode
			for(var i =0; i < this.cityArr.length; i++){
				 if( this.$route.params.Scode == this.cityArr[i].value ){
					 this.city_name = this.cityArr[i].text
				 }
			}
		 }else{
		 	this.searchname = ''
			this.cityCode = ''
			
		 }
		 this.mrmj_home()
    // 在次搜索
			this.$bus.$on('handleName',(params)=>{
			 if(params.search || params.code){
				 this.searchname = params.search
				 this.cityCode = params.code
				 for(var i =0; i < this.cityArr.length; i++){
					 if( params.Scode == this.cityArr[i].value ){
						 this.city_name = this.cityArr[i].text
					 }
				 }
				 this.mrmj_home() // 筛选之后的列表
			 }
		})
		// this.mrmj_home()
   },

   methods: {
      detailMj(res_link){
				if(res_link.id){
					this.$router.push({path:'FamousIndex',query:{id:res_link.id}})
				}
      },
      //分类
      mrmj_cate() {
         this.$http.get(this.constant.loadurl.get_master_cate, {

         }).then(res => {
            if (res.data.code == 0) {
               this.fenleiArr = res.data.data
               var fl = {
                  name: '全部',
                  id: ''
               }
               this.fenleiArr.unshift(fl) 
               var cityDefault = {
                  text: '全部',
                  value: '' 
               }
               this.cityArr.unshift(cityDefault)
            }
         })
      },
      //条件筛选
      mrmj_home() {
				this.NameOrZpList = []
				let Name_code ;
				if(this.Letter == '全部'){
					Name_code = ''
				}else{
					Name_code = this.Letter
				}
         this.$http.post(this.constant.loadurl.get_master_home, {
            cate_id: this.titleid,
            city_code: this.cityCode,
						province_code:this.province_code,
					
            name_code: Name_code,
						name:this.searchname,
						page:this.page,
						limit:this.limit
         }).then(res => {
            if (res.data.code == 0 && res.data.data.total > 0) {
               this.NameOrZpList = res.data.data.data
							 this.total = res.data.data.total
							 let obj  = {
							 	name:'',
							 	profiles_img:'https://img1.baidu.com/it/u=1218531695,776446823&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=800'
							 }
							 
							 for(var i = 0 ; i < this.NameOrZpList.length ; i++ ){
							 	if(this.NameOrZpList.length % 6 !=0){
									this.NameOrZpList.push(obj)
							 	}
							 }
            }
         })
      },
      // 作品类
      ChangeTitle(res) {
				this.searchname = ' '
				if(res.id == '全部'){
					this.titleid = ''
				}else{
					this.titleid = res.id
				}
				this.mrmj_home()
      },
      // 省市
      ChangeCity(city) {
				this.searchname = ''
				this.cityCode = ' '
				this.province_code = ''
				if(city.value == ''){
					this.province_code = ''
				}else{
					this.province_code = city.value
				}
         this.mrmj_home()
      },
      // 索引
      ChangeLetter(letter) {
				this.searchname = ' '
				if(letter == '全部'){
					 
					this.Letter = ' '
				}else{
					 
					this.Letter = letter
				}
        this.mrmj_home()
      },
				
			clearCate(){
				this.title_name= '全部',// 作品分类
				this.city_name= '全部',//城市名称
				this.Letter = ' '
				this.cityCode = ' '
				this.titleid = ' '
				this.searchname = ' '
				this.mrmj_home()
			},
			currentChange(next){
				this.page = next
				if(this.Letter == '全部'){
					this.Letter = ' '
				}
				this.mrmj_home()
			}
   },
   created() {

   },
};
</script>

<style >
.continuer {}

.sx {
   padding: 30px 22rem;
}

.bor_bottom {
   padding: 0 !important;
   border-bottom: 2px solid #333333;
}

.screenDiv {
   margin-top: 30px;
   padding: 30px 20px;
   border: 1px solid #E3E3E3;
}

.mj_shaixuan {
   display: flex;
   justify-content: space-between;

}

.mjsx {
   font-size: 16px;
   font-weight: bold;
}

.clear {
	cursor: pointer;
   padding: 5px 10px;
   background: #D8D8D8;
}

.FenLeiBox {
   display: flex;
}

.top_bottom {
   margin: 30px 0px;
}

.tag {
   padding: 5px 20px 5px 0px;
   font-size: 14px;
   white-space: nowrap;
}

.el-radio-group {
   width: 100%;
   display: flex !important;
   flex-wrap: wrap !important;
   align-items: center !important;
   font-size: 15px !important;
}

.mrList {}

.allData {
   margin: 20px 0px;
   text-align: right;
}

.allData span {
   color: #BA3635;
}

.ListDiv_mj {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
}

.yuansu {
   margin-bottom: 20px;
   text-align: center;
   background: #FFFFFF;
   cursor: pointer;
   box-shadow: -4px 6px 14px 2px rgba(33, 18, 13, 0.08);
}
.defaults{
	cursor: auto;
	box-shadow: 0 0 0 0;
	margin-bottom: 20px;
	text-align: center;
	background: #FFFFFF;
}
.defaults img{
 width: 11.625rem;
 height: 218px;
}
.yuansu img {
   width: 11.625rem;
   height: 218px;
}

.yuansu div {
   padding: 15px 0px;
}

.FenYe {
   margin: 30px 0px;
   text-align: center;
}

.el-radio-button .el-radio-button__inner {
   border: 1px solid transparent !important;
}
</style>