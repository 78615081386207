<template>
  <div class="content">
    <div>
      <!-- 顶部导航栏 -->
      <div class="content-top">
        <div class="content-top-con">
          <div class="dhksty1">司法认证，权威有效</div>
          <div class="dhksty2">区块链+可信时间戳技术</div>
          <div class="dhksty3">可追溯，防篡改</div>
          <img class="content-top-con-img"
            src="https://mingjiahome.zhyell.com/jdba/shuziren.png" />
          <div class="content-top-con-btn">我要鉴定</div>
        </div>
      </div>
      <!-- 版权作品 -->
      <div class="liststy">
        <div class="liststy-top">
          <div class="liststy-top-left">
            <div class="shutiao"></div>
            <div class="liststy-top-left-tit">版权作品</div>
  <!--          <div style="display: flex;margin-left: 10px;">
              <div v-for="(item,index) in Math.ceil(qychanglist.length/12)" :key="index" @click="changeyq(index)"
                :class="bqtabindex==index+1 ? 'yqchange' : 'yqchangewei'"></div>
              <div class="bqchangeleftaRig" @click="leftclick">{{'<'}}</div>
              <div class="bqchangeleftaRig" @click="rightclick">{{'>'}}</div>
            </div> -->
          </div>
          <div class="liststy-top-rig">查看更多>></div>
        </div>
        <div class="liststy-bot">
          <div class="liststy-bot-xh">
            <div class="liststy-bot-sty" v-for="(item,index) in 15" :key="index" @click="qzDetail(item)">
              <img class="liststy-bot-xh-img"
                src="https://mingjiahome.zhyell.com/jdba/ban1.png"
                alt="">
              <div class="liststy-bot-xh-tit"><span style="font-size: 15px;">《南北合》</span><span
                  style="font-size:12px;">许荷英饰碧莲公主</span></div>
              <div class="liststy-bot-xh-con">
                <div style="color: #E21F3E;font-size: 15px;margin-right: 10px;">已上链</div>
                <div style="color: #999999;font-size: 12px;line-height: 23px;">编号：767867878</div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- 名人名家 -->
      <div class="liststy">
        <div class="liststy-top">
          <div class="liststy-top-left">
            <div class="shutiao"></div>
            <div class="liststy-top-left-tit">名人名家</div>
          </div>
          <div class="liststy-top-rig">查看更多>></div>
        </div>
        <div class="liststy-bot">
          <div class="liststy-bot-xh">
            <div class="liststy-bot-mrmj" v-for="(item,index) in 12" :key="index">
              <img class="liststy-bot-imgmrmj"
                src="https://mingjiahome.zhyell.com/jdba/ming2.png"
                alt="">
              <div class="liststy-bot-mrmj-tit">许荷英</div>
              <div class="liststy-bot-mrmj-cont">鉴定备案作品 <span class="liststy-bot-mrmj-num">82</span>件</div>
              <div class="liststy-bot-mrmj-cont">在线鉴定作品 <span class="liststy-bot-mrmj-num">12</span>件</div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        // bqtabindex: 1,//当前页数
        // bqsizes:"12",//一页多少条
      }
    },
    methods: {
      // 点击进入详情
      qzDetail() {
        this.$router.push({
          name: 'copyrightDetail'
        })
      },
      // 版权切换分页
      // changeyq(index) {
      //   this.bqtabindex = index+1
      // },
      // leftclick() {
      //   if (this.bqtabindex == 1) {
      //     this.bqtabindex = Math.ceil(this.qychanglist.length / 12)
      //   } else {
      //     this.bqtabindex = this.bqtabindex - 1
      //   }
      // },
      // rightclick() {
      //   if (this.bqtabindex == Math.ceil(this.qychanglist.length / 12)) {
      //     this.bqtabindex = 1
      //   } else {
      //     this.bqtabindex = this.bqtabindex + 1
      //   }
      // }
    }
  }
</script>

<style scoped>
  .content {
    min-width: 1900px;
  }

  .content-top {
    background: url("https://mingjiahome.zhyell.com.com/jdba/banner.png") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 360px;
  }

  .content-top-con {
    background: rgba(255, 255, 255, 0.8);
    width: 508px;
    height: 240px;
    border-radius: 20px;
    margin: 0 auto;
    position: relative;
    top: 60px;
    color: #333;
    font-size: 12px;
  }

  .dhksty1 {
    background: url("https://mingjiahome.zhyell.com.com.png") no-repeat;
    background-size: 100% 100%;
    width: 144px;
    height: 37px;
    text-align: center;
    position: absolute;
    top: 70px;
    left: 50px;
    line-height: 34px;
  }

  .dhksty2 {
    background: url("http://ryfw51f4p.hn-bkt.clouddn.com/jdba/dhright.png?e=1690854644&token=MRD8xdDk5PZlBXaqUXd2Bsb9UpawP7NevFYBSWW5:TlYC4m-zy6ebIpgM1_Piw-xbYG8=") no-repeat;
    background-size: 100% 100%;
    width: 167px;
    height: 37px;
    text-align: center;
    position: absolute;
    top: 30px;
    right: 40px;
    line-height: 34px;
  }

  .dhksty3 {
    background: url("http://ryfw51f4p.hn-bkt.clouddn.com/jdba/dhright.png?e=1690854644&token=MRD8xdDk5PZlBXaqUXd2Bsb9UpawP7NevFYBSWW5:TlYC4m-zy6ebIpgM1_Piw-xbYG8=") no-repeat;
    background-size: 100% 100%;
    width: 130px;
    height: 37px;
    text-align: center;
    position: absolute;
    top: 100px;
    right: 60px;
    line-height: 34px;
  }

  .content-top-con-img {
    width: 130px;
    height: 162px;
    margin: 0 auto;
    position: relative;
    top: 20px;
    left: 36%;
  }

  .content-top-con-btn {
    background: url("http://ryfw51f4p.hn-bkt.clouddn.com/jdba/btn.png?e=1690780068&token=MRD8xdDk5PZlBXaqUXd2Bsb9UpawP7NevFYBSWW5:AlQ7FuLMTRQ3wLohUT43vbJsi1Y=") no-repeat;
    background-size: 100% 100%;
    width: 314px;
    height: 54px;
    margin: 0 auto;
    position: relative;
    top: 0px;
    color: #fff;
    font-size: 18px;
    line-height: 54px;
    font-weight: bold;
    text-align: center;
  }

  .liststy {
    margin: 80px auto;
    max-width: 1200px;
  }

  .liststy-top {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #E6E6E6;
    /* padding-bottom: 20px; */
  }

  .liststy-top-left {
    display: flex;
  }

  .shutiao {
    width: 5px;
    height: 22px;
    background-color: #000000;

  }

  .liststy-top-left-tit {
    color: #333;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    margin-left: 10px;
  }

  .yqchange {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #A53124;
    margin: 8px 5px 0px 5px;
  }

  .yqchangewei {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #F2F2F2;
    margin: 8px 5px 0px 5px;
  }

  .bqchangeleftaRig {
    background-color: #A53124;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    line-height: 20px;
    font-weight: bold;
    text-align: center;
    margin: 3px 5px 0px 10px;
    cursor: pointer;
  }

  .liststy-top-rig {
    color: #333;
    font-size: 14px;
    line-height: 22px;
  }

  .liststy-bot {
    margin-top: 30px;
  }

  .liststy-bot-xh {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-decoration: none;


  }

  .liststy-bot-sty {
    background-color: #F6F6F6;
    border-radius: 20px;
    width: 226px;
    color: #333;
    margin-bottom: 20px;
  }

  .liststy-bot-xh-img {
    width: 226px;
    height: 214px;
  }

  .liststy-bot-xh-tit {
    margin: 10px 0px;
    text-align: left;
  }

  .liststy-bot-xh-con {
    display: flex;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  /* 名人名家部分css */
  .liststy-bot-mrmj {
    width: 180px;
    background-color: #F6F6F6;
    margin-bottom: 20px;
    border-radius: 10px;
    color: #333;
  }

  .liststy-bot-imgmrmj {
    width: 180px;
    height: 200px;

  }

  .liststy-bot-mrmj-tit {
    font-size: 15px;
    font-weight: bold;
    margin: 10px 0px 5px 0px;
  }

  .liststy-bot-mrmj-cont {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .liststy-bot-mrmj-num {
    color: #FF0101;
  }
</style>
