<!-- 顶部组件 -->
<template>
	<div >
		<nav class="topNavBox" v-if='false'>
			<div class="left_box">
			</div>
			<div class="right_box">
				<span class="icon_clumn">|</span>
				<div>数字人讲解</div>
				<span class="icon_clumn">|</span>
				<div>无障碍浏览</div>
				<span class="icon_clumn">|</span>
				<div>登录</div>
			</div>
		</nav>
		<div>
		<div class="container">
			<div class="search_logoCity">
				<div class="logo">
					<img src="https://mingjiaoss.zhyell.com/logosy.png" alt="">
					<!-- <img src="https://mingjiahome.zhyell.com/logo.png" alt=""> -->
				</div>
				<div class="city1">
					<span class="el-dropdown-link">
						<i class="el-icon-location el-icon--right" style="color:red;"></i>
					</span>
					<el-cascader v-model="city_value" :props="cityProps" :options="city_options"
						@change="handleChange"></el-cascader>
				</div>
			</div>
			<div class="search">
				<div class="mrmj">
					<el-dropdown trigger="click" @command="names">
						<span class="el-dropdown-link">
							<span style="display: inline-block; min-width:3.125rem; color: #000000;">{{ userName }}</span>
							<i class="el-icon-caret-bottom el-icon--right" style="color:#000000; padding-left:5px;"></i>
						</span>
						<el-dropdown-menu slot="dropdown" :append-to-body="false" >
							<el-dropdown-item command="名人名家">名人名家</el-dropdown-item>
							<!-- <el-dropdown-item command="其他">其他</el-dropdown-item> -->
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<div>
					<el-input placeholder="请输入内容" v-model="input3" class="input-with-select">
						<div slot="append" class="BtnClick" @click="searchChange">搜索</div>
					</el-input>
				</div>
			</div>
		</div>
		</div>
		<div class="navigationContainer">
			<el-menu :default-active="$route.path" class="el-menu-demo el-list" mode="horizontal" @select="handleSelect"
				background-color="#000" text-color="#fff" active-text-color="#ffffff" :router="true">
				<el-menu-item v-for='(item,index) in eleMeunList' :index='item.index' :key='index'>
					<div v-if='item.isImg == 0'>{{item.name}}</div>
					<img v-if='item.isImg == 1' class="yzgImg" :src="item.name" alt="">
				</el-menu-item>
			</el-menu>
		</div>
		<div>
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import cityJson from "@/config/city.json"
	export default {
		data() {
			return {
				msg: 'jaja',
				cityName: '石家庄',
				userName: '名人名家',
				activeIndex2: '/',
				input3: '',
				city_options: cityJson,
				city_value: '',
				Scode:'',
				cityProps: {
					label: 'text',
					value: "value",
					children: 'children'
				},
				cityCode: '',
				eleMeunList: [{
						index: '/',
						name: '首页',
						isImg: '0'
					},
					{
						index: '/MingRen',
						name: '名人名家',
						isImg: '0'
					},
					{
						index: '/cloudHall',
						isImg: '0',
						name:'云展馆'
						// name: 'https://mingjiahome.zhyell.com/yunzhanguan.png'
					},
					{
						index: '/worksAppreciate',
						name: '名家讲堂',
						isImg: '0'
					},
					{
						index: '/worklist',
						name: '作品欣赏',
						isImg: '0'
					},
					{
						index: '/shoppingmall',
						name: '线上商城',
						isImg: '0'
					},
					{
						index: '/ztactivities',
						name: '专题活动',
						isImg: '0'
					},
					{
						index: '/information',
						name: '资讯',
						isImg: '0'
					},
					{
						index: '/liveBroadcast',
						name: '直播',
						isImg: '0'
					}
				]
			}
		},
		watch: {

		},
		mounted() {

		},
		methods: {
			handleSelect(r, e) {
				this.activeIndex2 = r
			},
			ChangeCity(command) {
				this.cityName = command
			},
			names(res) {
				console.log( '');
			},
			handleChange(value) {
				this.cityCode = value[1]
				this.Scode = value[0]
				// console.log(value);
			},
			searchChange() {
				
				if (this.$route.path == '/MingRen') {
					let obj = {
						search:this.input3,
						code :this.cityCode,
						Scode:this.Scode
					}
					this.$bus.$emit('handleName',obj)
					
				} else {
					this.$router.push({
						name: 'MingRen',
						params: {
							searchText: this.input3,
							Citycode: this.cityCode,
							Scode:this.Scode
						}
					})
				}
				// this.input3 = ' '
			},
			 destroyed() {
			    this.$bus.$off('handleName'); // 组件销毁之前销毁监听事件
			  }
			
		}
	}
</script>
<style>
	.topNavBox {
		display: flex;
		justify-content: space-between;
		background: #EBEBEB;
	}

	.left_box,
	.right_box {
		display: flex;
		font-size: .875rem;
		color: #333333;
	}

	.left_box,
	.right_box div {
		white-space: nowrap;
	}

	.icon_clumn {
		padding: 0rem .5rem;
	}

	.container {
	min-height: 120px;
	/* 		max-width: 120rem; */
		width: 60%;
		/* padding: 0rem 22.9375rem; */
		/* background-image: url('http://ryfw51f4p.hn-bkt.clouddn.com/bj-ding.png'); */
		background-repeat: no-repeat;
		background-size: 100% 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.search_logoCity {
		display: flex;
		align-items: center;
	}
	
	.mrmj {
		padding: 0 10px;
	}
	
	.el-input-group__append {
		/* width: 5rem !important; */
		height: 2.7rem !important;
		color: #ffffff !important;
		background-color: #e95006 !important;
		/* background-color: #BB2929 !important; */
	}

	.BtnClick {
		cursor: pointer;
	}

	.city1 {
		display: flex;
		align-items: center;
		width: 14rem;
		/* height: 2.5rem; */
		margin-left: 6rem;
		margin-right: 3rem;
		border: 1px solid #E7E6E6;
		border-radius: 20px;
	}

	.city1 .el-cascader .el-input {
		width: 92% !important;
	}
	.logo img {
		width: 10.4375rem;
		height: 3.3125rem;
		/* width: 20.4375rem; */
		/* height: 4.6875rem; */
		object-fit: fill;
	}

	.search {
		display: flex;
		align-items: center;
		background: #ffffff;
		position: relative;
		border: 1px solid #e8e8e8;
	}

	.navigationContainer {
		/* width: 100%; */
		/* min-width: 100rem; */
		/* padding:0rem 22.9375rem ; */
	}

	.navigationContainer .el-menu-demo {
		/* padding: 0rem 22.9375rem; */
	}

	.el-list {
		display: flex !important;
		justify-content: space-between !important;
	}

	.yzgImg {
		width: 4rem;
		height: 2rem;
	}

	.el-menu-item.is-active {
		background-color: #e95006 !important;
		border-bottom: 0px !important;
	}

	.el-menu-item {
		padding: 0 2.25rem !important;
	}

	.el-dropdown-link {
		cursor: pointer;
		color: #409EFF;
	}

	.el-icon-arrow-down {
		font-size: 12px;
	}

	.demonstration {
		display: block;
		color: #8492a6;
		font-size: 14px;
		margin-bottom: 20px;
	}

	.el-dropdown {
		white-space: nowrap;
		vertical-align: top;
	}

	.el-dropdown+.el-dropdown {
		margin-left: 15px;
	}

	.el-icon-arrow-down {
		font-size: 12px;
	}

	.el-input__inner {
		border-radius: 0 !important;
		border: 0 !important;
	}

	.el-radio-button {
		padding: 0 !important;
	}
</style>