var baseUrl = "https://mingjiadev.zhyell.com/api/";

export default {
	title: '名家官网',
	loadurl: {
		get_search_master:baseUrl + 'home/get_search_master',		// 首页搜索
		get_live_list: baseUrl + 'home/get_live_list', //获取直播列表
    get_shop_list: baseUrl + 'home/get_shop_list', //获取线上商城作品列表
    get_shop_cate_list: baseUrl + 'home/get_shop_cate_list', //获取线上商城分类列表
    get_shop_info: baseUrl + 'home/get_shop_info', //获取线上商城作品详情
    get_index: baseUrl + 'home/get_index', //获取首页数据
    get_master: baseUrl + 'home/get_master', //首页名家分类筛选
    get_master:baseUrl + 'home/get_master',// 名人名家筛选
    get_banner_images:baseUrl + 'home/get_banner_images',//首页轮播图
    get_master_cate:baseUrl + 'home/get_master_cate',// 名家分类
    get_master_home:baseUrl + 'home/get_master_home',// 名人名家多条件筛选
    get_works_list:baseUrl + 'home/get_works_list',//名家作品欣赏列表
    get_works_info: baseUrl + 'home/get_works_info', //获取名家官网作品详情
    get_ming_index:baseUrl + 'home/get_ming_index',//名家官网首页
    get_ming_da:baseUrl + 'home/get_ming_da',//名家官网档案
    get_ming_works:baseUrl + 'home/get_ming_works',// 名家官网作品
    get_ming_works_info:baseUrl + 'home/get_ming_works_info',// 名家作品详情
    get_banner_images: baseUrl + 'home/get_banner_images', //获取商城/首页轮播图列表
    get_article: baseUrl + 'home/get_article', //热门资讯
		get_ming_article:baseUrl +'home/get_ming_article',// 相关资讯
		get_ming_article_info:baseUrl + 'home/get_ming_article_info',// 资讯详情
    get_article_info: baseUrl + 'home/get_article_info', //热门资讯详情
		get_ming_hall:baseUrl + 'home/get_ming_hall',// 名家官网讲堂
		get_activity:baseUrl + 'home/get_activity',//专题活动列表
		get_activity_info:baseUrl + "home/get_activity_info",//专题活动详情
		get_ming_cate:baseUrl + "home/get_ming_cate",//获取名家讲堂分类
		get_hall:baseUrl + "home/get_hall",//名家讲堂列表
		get_hall_info:baseUrl + "home/get_hall_info",//名家讲堂详情
		get_exhibition:baseUrl + "home/get_exhibition",//云展馆
	}
}
