<template>
  <div class="contenter">
    <el-carousel height="500px" :autoplay="false" style="margin-top: 30px;">
      <el-carousel-item v-for="item in banners" :key="item.id">
        <img class="appcarous-img" :src="item.cover" @click="qzDetail(item.id)"/>
      </el-carousel-item>
    </el-carousel>
    <div class="workliststy">
      <div class="workliststy-top">
        <div class="workliststy-top-left">
          <img class="workliststy-top-left-img" src="https://mingjiahome.zhyell.com/cloudhall/lunbohover.png" />
          <div class="workliststy-top-left-tit">名家讲堂</div>
        </div>
        <div class="workliststy-top-rig">
          <div v-for="(item,index) in worktypelist" :key="index" @click="typework(item.id,index)"
            :class="tabindex==index ? 'workliststy-top-rig-tjlsitwei' : 'workliststy-top-rig-tjlsit'">
            {{item.name}}
          </div>
        </div>
      </div><strong></strong>
      <div class="workliststy-bot" v-if="lists.length != 0">
        <div class="workliststy-bot-sty" v-for="(item,index) in lists" :key="index" @click="qzDetail(item.id)">
          <img class="workliststy-bot-xh-img" :src="item.cover" alt="">
          <div class="workliststy-bot-xh-tit">{{item.title}}</div>
          <!-- <div class="workliststy-bot-xh-name" v-html="item.course_description"></div> -->
        </div>
      </div>
      <div class="workliststy-bot" v-else>
        暂无更多数据
      </div>
      <el-pagination background layout="total, prev, pager, next" :total="total" :page-size="pageSize"
        @current-change="currentChange" @size-change="handleSizeChange" :current-page="currentPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        banners: [],
        tabId: 0,
        tabindex: 0,
        lists: [],
        worktypelist: [],
        pageSize: 8,
        currentPage: 1,
        total: 0,
      }
    },
    mounted() {
      this.getCate()

    },
    methods: {
      getCate() { //获取
        let _this = this;
        this.$http.get(_this.constant.loadurl.get_ming_cate).then(res => {
          if (res.data.code == 0) {
            _this.worktypelist = res.data.data
            _this.tabId = res.data.data[0].id
            _this.getList(res.data.data[0].id)
            // _this.ztLists = res.data.data.activity
            // console.log(_this.ztLists)
          } else {

          }
        });
      },
      // 点击作品类型进行切换
      typework(id, index) {
        this.tabindex = index
        this.getList(id)
      },
      getList(id) {
        let _this = this;
        var num;
        if (id == 9) {
          num = ''
        } else {
          num = id
        }
        this.$http.get(this.constant.loadurl.get_hall, {
          params: {
            hall_type_id: num,
            page: _this.currentPage,
            limit: _this.pageSize
          }
        }).then(res => {
          if (res.data.code == 0) {
            _this.lists = res.data.data.hall.data
            _this.total = res.data.data.hall.total
            _this.banners = res.data.data.cover
            // _this.ztLists = res.data.data.activity
            // console.log(_this.ztLists)
          } else {

          }
        });


      },
      //点击作品进入详情页
      qzDetail(id) {
        this.$router.push({
          name: 'worksAppDetail',
          params: {
            id: id
          }
        })
      },

      currentChange(e) {
        this.currentPage = e;
        this.getCate(this.tabId)
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.getCate(this.tabId)
      },
    }
  }
</script>

<style scoped>
  .contenter {
    display: block !important;
  }

  /* 分页 */
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #333333 !important;
    color: #fff !important;
  }

  .contenter {
    max-width: 1200px;
    margin: 0 auto;
  }

  .appcarous-img {
    width: 100%;
    height: 500px;
  }

  .workliststy {
    margin: 50px auto;
    text-align: center;
  }

  .workliststy-top {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #E6E6E6;
    padding-bottom: 20px;
  }

  .workliststy-top-left {
    display: flex;
  }

  .workliststy-top-left-img {
    width: 30px;
    height: 30px;

  }

  .workliststy-top-left-tit {
    color: #333;
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    margin-left: 10px;
  }

  .workliststy-top-rig {
    display: flex;
  }

  .workliststy-top-rig-tjlsit {
    color: #333;
    font-size: 14px;
    line-height: 22px;
    background: rgba(0, 0, 0, 0.08);
    margin: 0px 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
.el-carousel__item img{
	object-fit: cover;
}
  .workliststy-top-rig-tjlsitwei {
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    background-color: #252525;
    margin: 0px 5px;
    padding: 5px 10px;
    cursor: pointer;
  }

  .workliststy-bot {
    margin-top: 30px;
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    text-decoration: none;
  }

  .workliststy-bot-sty {
    /* background-color: #F6F6F6; */
    /* border-radius: 10px; */
    width: 286px;
    color: #333;
    margin-bottom: 20px;
    margin-left: 13px;
    cursor: pointer;
  }

  .workliststy-bot-xh-img {
    width: 100%;
    height: 12.5rem;
		object-fit: contain;
  }

  .workliststy-bot-xh-tit {
    margin: 10px;
    font-size: 16px;
    text-align: left;
    color: #333;
  }

  .workliststy-bot-xh-name {
    color: #666;
    font-size: 16px;
    text-align: left;
    margin: 10px;
  }
</style>
