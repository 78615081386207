<template>
  <div class="content">
    <div class="gobacksty">
      <div @click="tztop" class="gobacksty-top">线上商城</div>
      <div class="gobacksty-detail">商品列表</div>
    </div>
    <div class="jpliststy-bot">
      <div class="jpliststy-bot-sty" v-for="(item,index) in worklist" :key="index" @click="qzDetail(item)">
        <img class="jpliststy-bot-xh-img"
          :src="item.images[0]"
          alt="">
        <div class="jpliststy-bot-xh-tit">{{item.title}}</div>
        <div class="jpliststy-bot-xh-name">
          <div class="bot-xh-name-pri">￥{{item.current_price}}</div>
          <div class="bot-xh-name-scx">￥{{item.original_price}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        id: '',
        worklist:[]
      }
    },
    created() {
      this.id = this.$route.params.id
      this.getlist(this.id);//获取线上商城作品列表
    },
    methods: {
      // 获取线上商城作品列表
      getlist(id){
        this.$http.get(this.constant.loadurl.get_shop_list, {params:{
            cate_id:id
          }}).then(res => {
          if (res.data.code == 0) {
            this.worklist=res.data.data
        	 } else {
            this.$message({
              message: res.data.msg || res.data.message,
              type: "warning"
            })
          }
        });
      },
      qzDetail(item){
        this.$router.push({
          name:'MallDetail',
          params:{
            id:item.id
          }
        })
      },
      // 返回上一页
      tztop() {
        this.$router.push({
          name: 'shoppingmall'
        })
      },
    }
  }
</script>

<style scoped>
  .content{
    max-width: 1200px;
    margin: 0 auto;
  }
  .gobacksty {
    max-width: 1200px;
    /* margin: 30px auto; */
    margin: 30px auto;
    height: 21px;
    line-height: 21px;
    display: flex;
    font-size: 14px;
    cursor: pointer;
  }

  .gobacksty-top {
    background-color: #F6F6F6;
    color: #000;
    padding: 0px 20px;
  }

  .gobacksty-detail {
    background: url("https://mingjiahome.zhyell.com/flag.png") no-repeat;
    background-size: 100% 100%;
    width: 114px;
    height: 21px;
    text-align: center;
    color: #fff;
  }

  .jpliststy-bot {
    /* margin-top: 10px; */
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    text-decoration: none;
  }

  .jpliststy-bot-sty {
    background-color: #F6F6F6;
    /* border-radius: 10px; */
    width: 226px;
    color: #333;
    margin-bottom: 20px;
    margin-left: 10px;
  }

  .jpliststy-bot-xh-img {
    width: 100%;
    height: 214px;
		object-fit: contain;
  }

  .jpliststy-bot-xh-tit {
    margin: 10px;
    font-size: 14px;
    text-align: left;
    color: #333;
  }
  .jpliststy-bot-xh-name{
    display: flex;
    margin: 0px 0px 10px 10px;
  }
  .bot-xh-name-pri{
    color: #E21F3E;
    font-size: 14px;
    font-weight: bold;
    margin-right: 20px;
  }
  .bot-xh-name-scx{
    font-size: 12px;
     text-decoration: line-through;
     color: #999;
     margin-top: 2px;
  }
</style>