<template>
  <div class="content">
    <div class="vedioxhsty">
      <div class="vedioxhsty-left" >
				<video class="vediosty-video"   v-if='hall.hall_course' controls>
          <source :src="hall.hall_course[0].video_url" type="video/mp4">
        </video>
				<div class="vedioxhsty-left-tit" v-if='this.index_hall_course'> {{this.index_hall_course}} </div>
        <div class="vedioxhsty-left-tit" v-if="this.index_hall_course == '' && this.index_hall_course ">第1节：{{ hall.hall_course[0].title}}</div>
      </div>
      <div class="vedioxhsty-rig">
        <div class="vedioxhsty-rig-tit" v-if='hall.hall'>{{hall.hall.title}}</div>
        <div class="vedioxhsty-rig-num">共{{vediolist.length}}节</div>
        <div class="hengxian"></div>
        <div class="vedioxhsty-rig-xh">
          <div class="vedioxhsty-rig-xhlist"
            v-for="(item, index) in vediolist.slice((currentPage - 1) * 2, currentPage * 2)" :key="index"
            @click="selectVdetail(index,item)">
           <video :class="tabindex == item.index ? 'vedioxhsty-rig-imgwei' : 'vedioxhsty-rig-img'">
              <source :src="item.video_url" type="video/mp4">
            </video>
            <div class="vedioxhsty-rig-tit">第{{index+1}}节：{{ item.title }}</div>
          </div>
          <div style="margin: 0 10px;">
            <el-button @click="vedioup" class="vedioxhsty-btn" type="primary" icon="el-icon-arrow-up"></el-button>
            <el-button @click="vediodown" class="vedioxhsty-btn" type="primary" icon="el-icon-arrow-down"></el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 名家简介 -->
    <div class="titSty-jj">
      <div class="titSty-jj-top">
        <div class="titSty-jj-toptit">名家简介</div>
      </div>
      <div class="jtjssty-grjj">
        <img class="jtjssty-grjj-img" v-if='hall.hall' :src="hall.hall.master_image">
        <div class="jtjssty-grjj-rig">
					<div v-if='hall.hall' v-html='hall.hall.master_description' class='content_textEdit'></div>
          <!-- <div class="jtjssty-grjj-rig-top">郭海博，山西省浑源人</div>
          <div class="jtjssty-grjj-rig-cont">
            中国工艺美术大师，中国工美行业艺术大师，正高级工艺美术师，正高级美术编审，国家艺术基金专家库专家。中国美术家协会会员，中国工艺美术协会会员，中国民间文艺家协会会员。河北省省级非物质文化遗产项目“郭氏铁板浮雕艺术”传承人。河北省民间文艺家协会副主席，河北省工艺美术协会副理事长，河北省文学艺术研究会副会长。中国民间文艺家协会第七次、第九次、第十次全国代表大会代表。政协河北省第九届、第十届、第十一届、第十二届委员。
          </div> -->
        </div>
      </div>
    </div>
    <!-- 讲堂目录 -->
 <div class="titSty-jj">
      <div class="titSty-jj-top">
        <div class="titSty-jj-toptit">讲堂目录</div>
      </div>
      <div class="mululist">
        <h3>目录</h3>
        <p v-for=" (item, index) in vediolist" :key="index">第{{index+1}}节：{{item.title}}</p>
      </div>
    </div>
    <div class="titSty-jj">
      <div class="titSty-jj-top">
        <div class="titSty-jj-toptit">课程简介</div>
      </div>
			<div v-if="hall.hall" v-html='hall.hall.course_description' class='content_textEdit'></div>
     <!-- <div style="font-size: 18px;font-weight: bold;text-align: left;line-height: 40px;">铁板浮雕公开课</div>
      <p style="text-indent: 35px;text-align: left;line-height: 40px;font-size: 18px;">
        铁板浮雕是以铁锤、铁錾为画笔，以铁板为画纸，通过锤锻、錾刻、抛磨、烧色等工序完成的一种雕塑艺术。铁板浮雕的最大特色是：可利用铁板原色及铁板特质通过锤锻、抛磨等手段使作品升华至形象生动、呼之欲出的艺术境界。最为难得的是，它能产生黑白相间或有色彩点缀的素描般的视觉效果。其金属质感，尤其是那独有的雕塑语言所表现出的特殊肌理，别有一种其他材质无法比拟的独特之美。铁板浮雕艺术，在传承的基础上有很大的创新，并得到发展，充分体现了传统手工艺的艺术价值，这不仅是研究中国传统手工艺史的鲜活材料，更是研究中国金属雕塑艺术的“活化
      </p>
      <p style="text-indent: 35px;text-align: left;line-height: 40px;font-size: 18px;">
        铁板浮雕是以铁锤、铁錾为画笔，以铁板为画纸，通过锤锻、錾刻、抛磨、烧色等工序完成的一种雕塑艺术。铁板浮雕的最大特色是：可利用铁板原色及铁板特质通过锤锻、抛磨等手段使作品升华至形象生动、呼之欲出的艺术境界。最为难得的是，它能产生黑白相间或有色彩点缀的素描般的视觉效果。其金属质感，尤其是那独有的雕塑语言所表现出的特殊肌理，别有一种其他材质无法比拟的独特之美。铁板浮雕艺术，在传承的基础上有很大的创新，并得到发展，充分体现了传统手工艺的艺术价值，这不仅是研究中国传统手工艺史的鲜活材料，更是研究中国金属雕塑艺术的“活化
      </p> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectVedio: {},
      tabindex: 0, //选中高亮index
      currentPage: 1,//当前页数
      vediolist: [],
			m_id:'',
			hall:[],
			index_hall_course:'',
    }
  },
  created() {
    this.selectVedio = this.vediolist[this.tabindex]
  },
  mounted(){
		if(this.$route.query.id,'-this.$route.query.id'){
			this.m_id =  this.$route.query.id,'-this.$route.query.id' 
		}
		this.mjhalldata()
  },
  methods: {
		mjhalldata(){
			this.$http.get(this.constant.loadurl.get_ming_hall + '?m_id='+this.m_id
			).then(res=>{
				this.hall = res.data.data
				this.vediolist = res.data.data.hall_course
			})
		},
    //  选中视频详情
    selectVdetail(index,item) {
      this.tabindex = item.index
			this.index_hall_course = '第'+ toNumber(index)+1 + '节课'+item.title 
      this.selectVedio = this.vediolist[this.tabindex]
    },
    //点击视频列表向上选择
    vedioup() {
      if (this.currentPage == 1) {
        this.currentPage = Math.ceil(this.vediolist.length / 2)
      } else {
        this.currentPage = this.currentPage - 1
      }
    },
    //点击视频列表向下选择
    vediodown() {
      if (this.currentPage == Math.ceil(this.vediolist.length / 2)) {
        this.currentPage = 1
      } else {
        this.currentPage = this.currentPage + 1
      }
    }
  }
}
</script>

<style scoped>
.content {
  max-width: 1200px;
  margin: 30px auto;
}

/* 视频播放css */
.vedioxhsty {
  max-width: 1200px;
  margin: 0px auto;
  background-color: #333333;
  display: flex;
  justify-content: space-between;
}

.vedioxhsty-left {
  text-align: left;
  margin: 30px;
}

.vediosty-video {
  /* margin-bottom: 200px; */
  width: 870px;
  height: 487px;
  border: 1px solid #fff;
}

.vedioxhsty-left-tit {
  color: #fff;
  font-size: 18px;
  margin-top: 20px;
}

.vedioxhsty-rig {
  text-align: left;
  background-color: #484848;
  padding: 20px 18px;
}

.vedioxhsty-rig-tit {
  font-size: 16px;
  color: #fff;
}

.vedioxhsty-rig-num {
  color: #999999;
  font-size: 16px;
  margin: 10px 0px 18px 0px;
}

.hengxian {
  height: 1px;
  background-color: #4E4E4E;
}

.vedioxhsty-rig-xh {
  /* margin-top: 20px; */
}

.vedioxhsty-rig-xhlist {
  margin: 20px 0px;
}

.vedioxhsty-rig-img {
  width: 231px;
  height: 129px;
  border: 1px solid #fff;
}

.vedioxhsty-rig-imgwei {
  width: 231px;
  height: 129px;
  border: 1px solid red;
}

.vedioxhsty-rig-tit {
  font-size: 16px;
  color: #fff;
  margin-top: 5px;
}

.vedioxhsty-btn {
  /* margin: 0px 5px; */
  background-color: #222222;
  border: none;
  border-radius: 15px;
  width: 100px;
  margin-top: 30px;
}

/* 名家讲堂内容 */
.titSty-jj {
  width: 1200px;
  margin: 30px auto;
  /* margin-bottom: 30px; */
}

.titSty-jj-top {
  border-bottom: 1px solid #333333;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.titSty-jj-toptit {
  background-color: #333;
  color: #fff;
  font-size: 18px;
  text-align: center;
  line-height: 60px;
  width: 140px;
}

/* 简介 */
.jtjssty-grjj {
  display: flex;
  width: 100%;
  margin: 30px 0px;

}

.jtjssty-grjj-img {
  width: 295px;
  height: 250px;
}

.jtjssty-grjj-rig {
  background-color: #F4F4F4;
  color: #333;
  text-align: left;
  padding: 20px;
}
.content_textEdit {
	line-height: 2rem !important;
}
.jtjssty-grjj-rig
.jtjssty-grjj-rig-top {
  font-size: 18px;
  margin-bottom: 20px;
}

.jtjssty-grjj-rig-cont {
  font-size: 16px;
  line-height: 35px;
}

/* 目录样式 */
.mululist {
  color: #333;
  font-size: 18px;
}

.mululist p {
  line-height: 40px;
}</style>