<template>
  <div class="content">
    <!-- 顶部 -->
    <div class="huodongsty">
      <!-- 轮播 -->
      <div class="huodongsty-left">
        <el-carousel class="lunboSty" height='487px'>
          <el-carousel-item class="lunboSty-item" v-for="(item,index) in carousel" :key="index"  @click="zxDetail(item)">
            <img :src="item" class="lunboSty-img" />
            <div class="lunboSty-item-tit">{{item.title}}</div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 公告活动 -->
      <div class="huodongsty-rig">
        <div class="huodongsty-rig-tit">活动公告</div>
        <ul class="huodongsty-rig-list">
          <li v-for="(item,index) in article_new" :key="index"><span style="font-weight: bold;" @click='zxDetail(item)'>{{item.type}}丨</span>{{item.title}}
</li>
        </ul>
      </div>
    </div>
    <!-- 相关咨讯 -->
    <div class="rmzxsty">
      <div class="zxtitSty-jj-top">
        <div class="titSty-jj-toptit">相关咨讯</div>
      </div>
      <div class="rmzxsty-list">
        <!-- 循环 -->
        <div v-for="(item,index) in rmzxList" :key="index" class="rmzxsty-list-xhzx" :class="index+1==5 ? '' : 'zxbotm'">
          <div class="rmzxsty-list-xh-left">
            <img :src="item.res_image"/>
          </div>
          <div class="rmzxsty-list-xh-rig">
            <!-- 第一行 -->
            <div class="rmzxsty-list-xh-one">
              <div style="display: flex;">
                <!-- <div class="xh-one-left">活动</div> -->
                <div class="xh-one-tit">{{item.title}}| {{item.source}}</div>
              </div>
              <div style="display: flex;">
                <!-- <div class="xh-one-rig" @click='copy(item.id)'>分享<i class="el-icon-share el-icon--right"></i></div> -->
                <div class="xh-one-rig" @click="zxDetail(item)">查看详情></div>
              </div>
            </div>
            <!-- 第二行 -->
            <div class="rmzxsty-list-xh-two">
              <div>{{item.write_time.split(' ')[0]}}</div>
              <div>来源：{{item.source}}</div>
             <!-- <div>阅读：332</div>
              <div>编辑：付园园</div> -->
            </div>
            <!-- 第三行 -->
            <div class="rmzxsty-list-xh-thr" v-html="item.content.replace(/<[^<>]+>/g, '').replace(/&nbsp;/gi, '')">
            </div>
          </div>
        </div>
      </div>
    <!--  <el-pagination background layout="total, prev, pager, next" :total="30" :page-size="pageSize"
        @current-change="currentChange" @size-change="handleSizeChange" :current-page="currentPage">
      </el-pagination> -->
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        pageSize: 10,
        currentPage: 1,
        rmzxList: [], //资讯列表
				article_new:[],//活动公告列表
         
        carousel: [], //轮播列表
				
        tatol: 0,
				mId:'',
      }
    },
		mounted(){
			if(this.$route.query.id) {
				 this.mId  = this.$route.query.id
			}
			this.xgzx()
		},
    methods: {
			// 
			xgzx(){
				// get_ming_article
				this.$http.get(this.constant.loadurl.get_ming_article+'?m_id='+ this.mId).then(res=>{
					console.log(res.data.data,'相关资讯')
					if (res.data.code == 0) {
						this.rmzxList = res.data.data.article // 资讯
						this.article_new = res.data.data.article_new // 最新资讯
						this.carousel = res.data.data.carousel // 轮播
					} else {
						this.$message({
							message: res.data.msg || res.data.message,
							type: "warning"
						})
					}
				})
			},
      currentChange(e) {
        this.currentPage = e;
      },
      handleSizeChange(val) {
        this.pageSize = val;
      },
      // 相关资讯跳转详情
      zxtzDe(item){
				console.log(item,'item')
        // this.$router.push({
        //   path: '/mjzxDetail',query:{id:item.id}
        // })
      },
			// 复制当前网页链接 			 分享
			copy(id) {
				var domUrl = document.createElement("input");
			
				// domUrl.value = window.location.href;
				domUrl.value = window.location.href.split('#')[0] + '#/inforDetail/' + id
			
				console.log(domUrl.value)
				domUrl.id = "creatDom";
			
				document.body.appendChild(domUrl);
			
				domUrl.select(); // 选择对象
			
				document.execCommand("Copy"); // 执行浏览器复制命令
			
				let creatDom = document.getElementById("creatDom");
			
				creatDom.parentNode.removeChild(creatDom);
			
				this.$message({
				  message: "链接已复制",
				  type: "success"
				})
			},
			// 点击跳转资讯详情
			zxDetail(item) {
				this.$router.push({
					path: '/mjzxDetail',
					query: {
						id: item.id
					}
				})
			}
    }
  }
</script>

<style>
  /* 分页 */
  .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #333333 !important;
    color: #fff !important;
  }
  .content{
    max-width: 1200px;
    margin: 30px auto;
  }
  .huodongsty{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .huodongsty-left{
    width: 870px;
    height: 487px;
  }
  .lunboSty {
     margin: 0 auto;
     width: 100%;
     cursor: pointer;
   }

   .lunboSty-item {
     width: 100%;
   }

   .lunboSty-img {
     width: 100%;
   }

   .lunboSty-item-tit {
     background: rgba(0, 0, 0, 0.5);
     color: #fff;
     height: 50px;
     line-height: 50px;
     position: absolute;
     bottom: 0px;
     left: 0px;
      text-align: left;
     font-size: 18px;
     width: 100%;
     padding-left: 20px;
   }
   .huodongsty-left .el-carousel__button {
     width: 16px !important;
     height: 16px !important;
     background-color: #999;
     position: relative;
     right: -360px;
     bottom: 5px;
   }

   .huodongsty-left .el-carousel__indicator.is-active button {
     width: 16px !important;
     height: 16px !important;
     background-color: #fff;
    position: relative;
    right: -360px;
    bottom: 5px;
   }
   .huodongsty-left .el-carousel__arrow{
     display: none;
   }
  .huodongsty-rig{
    width: 312px;
    text-align: left;
    background-color: #F7F7F7;
  }
  .huodongsty-rig-tit{
    background-color: #333333;
    color: #fff;
    font-size: 18px;
    line-height: 53px;
    padding-left: 20px;
  }
  .huodongsty-rig-list{
      font-size: 14px;
      color: #333;
      padding-right: 25px;
  }
  .huodongsty-rig-list li{
    margin: 20px 0px;
    line-height: 25px;
  }

  /* 热门资讯 */
  .rmzxsty{
    margin: 30px 0px;
    text-align: center;
  }
  .zxtitSty-jj-top {
    border-bottom: 1px solid #333333;
    /* margin-bottom: 30px; */
    display: flex;
    justify-content: space-between;
  }

  .titSty-jj-toptit {
    background-color: #333;
    color: #fff;
    font-size: 18px;
    text-align: center;
    line-height: 60px;
    width: 140px;
  }
  .rmzxsty-list{
  }
  .rmzxsty-list-xhzx{
    padding: 40px 0px 40px 0px;
    display: flex;
  }
  .zxbotm{
      border-bottom: 1px solid #E6E6E6;
  }
  .rmzxsty-list-xh-left{
    width: 260px;
    height: 170px;
  }
  .rmzxsty-list-xh-rig{
    margin-left: 30px;
    width: 1000px;

  }
  .rmzxsty-list-xh-one{
    display: flex;
    justify-content: space-between;
    height: 19px;
    line-height: 19px;
  }
  .xh-one-left{
    width: 36px;
    color: #fff;
    background-color: #000;
    font-size: 12px;
    text-align: center;
  }
  .xh-one-tit{
    font-weight: bold;
    color: #333;
    font-size: 16px;
    margin-left: 20px;
    cursor: pointer;
  }
  .xh-one-rig{
    color: #333;
    font-size: 14px;
    margin-left:20px;
    cursor: pointer;
  }
  .rmzxsty-list-xh-two{
    color: #333;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    margin: 15px 0px;
  }
  .rmzxsty-list-xh-two div{
    margin-right: 30px;
  }
  .rmzxsty-list-xh-thr{
    color: #333;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
  }
</style>