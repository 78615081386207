<template>
  <div class="home">
    <div class='divBox'>
      <!-- <topNavigation></topNavigation> -->
      <div :class="isfixTab ==true?'NumAi': 'NumAi_none'" id='kf_ai'   @click='AImessage'>
				<img src="https://mingjiaoss.zhyell.com/szrmt.png" alt="">
        <!-- <img class="renwu" src="https://mingjiahome.zhyell.com/index/ren1.png" alt=""> -->
        <!-- <img class="xingzhuang" src="https://mingjiahome.zhyell.com/index/xingzhuang.png" alt=""> -->
        <!-- <div class="tags">数字人讲解</div> -->
      </div>
      <!-- 轮播图 -->
      <div class="IndexS_box">
        <el-carousel height="500px" v-for="(item, index) in swiperArr" :key="index" v-show="item.type == 1"
          >
          <el-carousel-item v-for="(imgs, idx) in item.url.split(',')" :key="idx">
            <img :src="imgs" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 列表 -->
      <div class="ListBox">
        <!-- left -->
        <div class="leftBox">
          <!-- 名家档案 -->
          <div class="top_header">
            <div class="titleNickname">
              <div><img src="https://mingjiahome.zhyell.com/index/icon-11.png" alt=""></div>
              <div class="g_famousFamilyArchives">名家档案</div>
            </div>
            <div>
              <!-- <div class="More" @click='dangAnAll'>查看更多>></div> -->
            </div>
          </div>
        <!--  <div class="oneLinebox" v-if="index_list.archives">
            <div class="line-one" @click="goTo(index_list.archives[0].id)">
              <img :src="index_list.archives[0].archives_img" alt="">
              <div class="introduce" v-html="index_list.archives[0].profiles.slice(0,40)+'....'"></div>
            </div>
          </div> -->
          <div class="threeListBox">
            <div class="Dline Dline_two" v-for="(item, index) in index_list.archives" :key="index" 
              @click="goTo_two(item)">
              <img :src="item.archives_img" alt="">
              <div class="introduce" v-html="item.profiles"></div>
            </div>
          </div>
          <!-- 云展馆 -->
          <div class="top_header">
            <div class="titleNickname yunDiv">
              <div><img src="https://mingjiahome.zhyell.com/index/icon-yun.png" alt=""></div>
            </div>
            <div @click='allZhanGuan'>
              <div class="More">查看更多>></div>
            </div>
          </div>
          <div class="about-index">
            <div class="container1">
              <div class="fine-grained">
                    <!--  <div class="box" :class="elIndex == i ? 'eleactive' : ''" v-for="(ele, i) in index_list.exhibition"
                        :key="i" @mouseenter="enter(i, ele)" @mouseleave="out(i)" @click="ounds(ele)">
												<img :src="ele.home_image">
                      </div> -->
									 <el-carousel   direction="horizontal" :autoplay="true" height="200px" trigger='click'>
											<el-carousel-item v-for="(ele, i) in index_list.exhibition" :key='i'  >
												<img :src="ele.home_image" class='imgs_sfq' @click='ounds(ele)'>
											</el-carousel-item>
										</el-carousel>
              </div>
            </div>
          </div>
          <!-- 名人名家 -->
          <div class="top_header">
            <div class="titleNickname">
              <div><img src="https://mingjiahome.zhyell.com/index/icon-11.png" alt=""></div>
              <div class="g_famousFamilyArchives">名人名家</div>
            </div>
            <div>
              <div class="More">查看更多>></div>
            </div>
          </div>
          <div class="radio_Nameworks">
            <div class="radioBox">
              <el-radio-group v-model="user_name" text-color="#fff" fill="#A53124" size='mini' :border="false">
                <div v-for="(item, index) in index_list.cate" :key="index" @input="changeName(item)" class='index_raido_text'>
                  <el-radio-button :label="item.name" ></el-radio-button>
                </div>
              </el-radio-group>
            </div>
            <div class="ZpList">
              <div v-for="(item, index) in NameOrZpList" :key="index" :class="item.name?'DyBox' : 'mjflex'" @click='mrmjDetail(item)'>
                <img :src='item.profiles_img' alt="" >
                <div>{{ item.name }}</div>
              </div>
							<div v-show='NameOrZpList.length < 1' style='text-align: center;width: 100%;'>
								 <el-empty description="暂无数据"></el-empty>
							</div>
            </div>
            <!-- 作品欣赏 -->
            <div class="top_header">
              <div class="titleNickname">
                <div><img src="https://mingjiahome.zhyell.com/index/icon-11.png" alt=""></div>
                <div class="g_famousFamilyArchives">作品欣赏</div>
              </div>
              <div>
                <div class="More" @click="allZp">查看更多>></div>
              </div>
            </div>
            <div class="ZpXs">
              <div class="Dlist_box">
                <div class="line-one-list">
                  <div v-for="(item, index) in index_list.works" :key="index" v-show="index < 2" @click="ZP_one_two(item)">
                    <img :src="item.images[0]" alt="">
                    <div>{{ item.title }}</div>
                  </div>
                </div>
                <div class="line-two-list">
                  <div v-for="(item, index) in index_list.works" :key="index" v-show="index != 0 && index != 1" @click="look_zuopin(item)">
                    <img :src="item.images[0]" alt="">
                    <div>{{ item.title }}</div>
                  </div>
                  <!-- <div v-for="n in count" :key="n.i"></div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 右边布局 -->
        <div class="rightCoutinuer">
          <div class="right_header">
            <div class="titleNickname">
              <div><img src="https://mingjiahome.zhyell.com/index/icon-11.png" alt=""></div>
              <div class="g_famousFamilyArchives">热门资讯</div>
            </div>
            <div>
              <div class="More" @click="allZiXun">查看更多>></div>
            </div>
          </div>
          <div class="MJ_Zx" v-for="(item, index) in index_list.news" :key="index" @click="detailZx(item)">
            <div class="imgs">
              <img :src="item.res_image" alt="">
            </div>
            <div class="title_date">
              <div class="jieshao1"  >{{item.title}}</div>
              <div class="gzs_datax">
                <div class="gzs">{{ item.type }}</div>
                <div class="timer">{{ item.write_time | moment("from") }}</div>
              </div>
            </div>
          </div>
          <div class="rightListWz">

            <!-- 广告 -->
            <!-- <div class="guanggao">
              <img
                src="https://mingjiahome.zhyell.com/index/guanggao%20%20%281%29.png"
                alt="">
              <div>广告</div>
            </div> -->
            <!-- 热门直播 -->
            <div class="right_header">
              <div class="titleNickname">
                <div><img src="https://mingjiahome.zhyell.com/index/icon-11.png" alt=""></div>
                <div class="g_famousFamilyArchives">热门直播</div>
              </div>
              <div>
                <div class="More" @click="allLive()">查看更多>></div>
              </div>
            </div>
            <div class="zhibo">
              <div class="zhiboList" v-for="(item, index) in index_list.live" :key="index">
                <img :src="item.broadcast_iamge" alt="">
                <!-- <div>不在之前</div> -->
                <div class="huifang">{{ item.status }}</div>
              </div>

            </div>
            <!-- 名家讲堂 -->
            <div class="right_header">
              <div class="titleNickname">
                <div><img src="https://mingjiahome.zhyell.com/index/icon-11.png" alt=""></div>
                <div class="g_famousFamilyArchives">名家讲堂</div>
              </div>
              <div>
                <div class="More" @click="AllJiangTang">查看更多>></div>
              </div>
            </div>
            <div class="jiangtang">
              <div class="divJiangtang" v-for="(item, index) in index_list.hall" :key="index" v-show="item.cover" @click="MingJiaJiangTanLink(item)">
                <img class="zanting" src="https://mingjiahome.zhyell.com/index/btn-bofang.png" alt="">
                <img class="mp4s" :src="item.cover" alt="">
              </div>
            </div>
            <!-- 坚定备案 -->
            <!--<div class="right_header">
              <div class="titleNickname">
                <div><img src="https://mingjiahome.zhyell.com/index/icon-11.png" alt=""></div>
                <div class="g_famousFamilyArchives">鉴定备案</div>
              </div>
              <div>
                <div class="More">查看更多>></div>
              </div>
            </div>
            <div class="jiandingBox">
              <div class="jianding">
                <div>
                  <img src="https://mingjiahome.zhyell.com/index/mingren1.png" alt="">
                  <div class="jdName">郝友友</div>
                </div>
                <div>
                  <img src="https://mingjiahome.zhyell.com/index/mingren2.png" alt="">
                  <div class="jdName">袁淑梅</div>
                </div>
                <div>
                  <img src="https://mingjiahome.zhyell.com/index/mingren3.png" alt="">
                  <div class="jdName">刘秀荣</div>
                </div>
              </div>
              <div class="jianding">
                <div>
                  <img src="https://mingjiahome.zhyell.com/index/mingren1.png" alt="">
                  <div class="jdName">郝友友</div>
                </div>
                <div>
                  <img src="https://mingjiahome.zhyell.com/index/mingren2.png" alt="">
                  <div class="jdName">袁淑梅</div>
                </div>
                <div>
                  <img src="https://mingjiahome.zhyell.com/index/mingren3.png" alt="">
                  <div class="jdName">刘秀荣</div>
                </div>
              </div>
            </div>
            <div class="myJd">我要鉴定</div>-->
            <div>
              <div class="right_header">
                <div class="titleNickname">
                  <div><img src="https://mingjiahome.zhyell.com/index/icon-11.png" alt=""></div>
                  <div class="g_famousFamilyArchives">专题活动</div>
                </div>
                <div>
                  <div class="More" @click="AllHuoDong">查看更多>></div>
                </div>
              </div>
              <div class="zhuantiBox">
                <div class="elZt" v-for="(item, index) in index_list.activity" :key="index" @click="HuoDongDetail(item)">
                  <img :src="item.image" alt="">
                  <div class="el_title">{{ item.title }}</div>
                  <!-- <div class="el_content" v-html="item.content"></div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="shopBox">
        <div class="shop_header">
          <div class="titleNickname">
            <div><img src="https://mingjiahome.zhyell.com/index/icon-11.png" alt=""></div>
            <div class="g_famousFamilyArchives">线上商城</div>
          </div>
          <div>
            <div class="More" @click="shopAll">查看更多>></div>
          </div>
        </div>
        <div class="shopList">
          <div class="line-shop" v-for="(item, index) in index_list.shop" :key="index" @click="shopDetail(item)">
            <div class="shopImg">
              <img :src="item.images[0]" alt="">
            </div>
            <div class="shopName">{{ item.title }}</div>
            <div class="money">
              <div class="yj" v-if='item.original_price'>￥ {{ item.original_price }}</div>
              <div class="zh">￥ {{ item.current_price }}</div>
            </div>
          </div>
        </div>
      </div>
      <Footnote></Footnote>
    </div>
  </div>
</template>
<script>
const dayjs = require("dayjs");
import topNavigation from '@/components/topNavigation.vue'
import Footnote from '@/components/Footnote.vue'
export default {
  name: 'HomeView',
  components: {
    Footnote
  },
  data() {
    return {
      index_list: [],// 首页数据
      MjArr: [],//名人名家分类
      MjList: [],// 名家列表
      elIndex: 0,
      user_name: '全部',
      NameOrZpList: [],//名人名家
      swiperArr: [],//首页轮播图
      lineNum: 4,
      count: 0,
      mjNum: 6,
			// NumAi:false,
			isfixTab:false,
    }
  },
  computed: {

  },
 
  mounted() {
	
    var lei = {
      name: '推荐',
      id: '0',
			isfixTab:false
    }
    //首页数据
    this.$http.get(this.constant.loadurl.get_index, {
    }).then(res => {
      this.index_list = res.data.data
      var worksList = this.index_list.works.filter((item, index) => index > 1)
      // this.count = worksList.length % this.lineNum
      var lei = {
        name: '全部',
        id: '0'
      }
      this.index_list.cate.unshift(lei)
      
      var liveList = res.data.data.live.forEach(item => {
        if (dayjs().isBefore(item.broadcast_start)) {
          return item.status = '未开始直播'
        } else if (dayjs().isBefore(item.broadcast_end && dayjs().isAfter(item.broadcast_start))) {
          return item.status = '直播中'
        } else {
          return item.status = '直播结束'
        }
      });
    });
    this.changeName(lei)
    this.swiperList()
	 window.addEventListener('scroll', this.handleTabFix, true)
  },
  methods: {
    //轮播图
    swiperList() {
      this.$http.get(this.constant.loadurl.get_banner_images, {
				params:{
					type: 1,
					is_mobile:1,
				}
      }).then(res => {
        this.swiperArr = res.data.data
      })
    },
    // 鼠标事件
    enter(i, ele) {
      this.elIndex = i;
    },
    out(i) {
      // this.imgindex = -1
    },
    ounds(ele) {
			window.location.href=ele.exhibition_url
    },
    // 名家列表
    changeName(res) {
      this.$http.post(this.constant.loadurl.get_master, {
        cate_id: res.id
      }).then(res => {
        if (res.data.code == 0) {
					this.NameOrZpList = res.data.data
					let obj  = {
						name:'',
						profiles_img:'https://img1.baidu.com/it/u=1218531695,776446823&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=800'
					}
          for(var i = 0 ; i < this.NameOrZpList.length ; i++ ){
						if(this.NameOrZpList.length % 6 !=0){
							this.NameOrZpList.push(obj)
						}
					}
        }
      })
    },
    goTo(items) {
      this.$router.push({ path: "/FamousIndex", query: { id: items } })
    },
    goTo_two(res) {
      this.$router.push({ path: "/FamousIndex", query: { id: res.id } })
    },
    allLive(){
      this.$router.push({ path: "/liveBroadcast"})
    },
		// 先分别获得id为testNavBar的元素距离顶部的距离和页面滚动的距离
		// 比较他们的大小来确定是否添加fixedNavbar样式
			handleTabFix() {
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				 
				
				scrollTop > 900 ? this.isfixTab = true : this.isfixTab = false
			},
	
		//数字人跳转
		AImessage(){
			window.location.href='https://digitai.zhyell.com/#/digtai?key=7wqfjy '
		},
    //热门资讯跳转
    detailZx(item){
      this.$router.push({name:'inforDetail',params:{id:item.id}})
    },
    //更多资讯
    allZiXun(){
      this.$router.push({name:'information'})
    },
		// 名人名家
		mrmjDetail(i){
			if(i.id){
				this.$router.push({name:'FamousIndex',query:{id:i.id}})
			}
		},
    // 名家讲堂更多
    AllJiangTang(){
      this.$router.push({name:'worksAppreciate'})
    },
		//  更多展馆
		allZhanGuan(){
			this.$router.push({name:'cloudHall'})
		},
    // 名家讲堂详情、
    MingJiaJiangTanLink(item){
      this.$router.push({name:'worksAppDetail',params:{id:item.id}})
    },
    //更多活动
    AllHuoDong(){
      this.$router.push({name:'ztactivities'})
    },
    // 专题活动详情
    HuoDongDetail(res){
      this.$router.push({name:'ztDetail',params:{detailId:res.id}})
    },
    // 作品欣赏查看更多
    allZp(){
      this.$router.push({path:'/worklist'}) 
    },
    // 第一行的
    ZP_one_two(res){
      this.$router.push({path:'/workdetail',query:{id:res.id}}) 
    },
    // 其余的
    look_zuopin(res){
      this.$router.push({path:'/workdetail',query:{id:res.id}}) 
    },
    // 商城更多
    shopAll(){
      this.$router.push({name:'shoppingmall'})
    },
    // 线上商城
    shopDetail(res){
      this.$router.push({name:'MallDetail',params:{id:res.id}})
    }
  }
}
</script>
<style >
.home {
  position: relative;
}

.IndexS_box {
	margin-top:-1px;
  /* height: 700px; */
  overflow: hidden;
}
.el-carousel__button {
		width: 30px !important;
		height: 30px !important;
		background: url("https://mingjiaoss.zhyell.com/lb2.png") no-repeat !important;
		background-size: 100% 100% !important;
		opacity: 1 !important;
}

.el-carousel__indicator.is-active button {
	width: 30px !important;
	height: 30px !important;
	background: url("https://mingjiaoss.zhyell.com/lb1.png") no-repeat !important;
	background-size: 100% 100% !important;
}

.S_box img {
  height: 100%;
  width: 100%;
}
.NumAi_none{
	position: fixed;
	opacity: 0;
	right: 1rem;
	transition:  all .5s;
}
.NumAi {
  position: fixed;
  top: 25%;
	transition:  all .5s;
  right: 1rem;
  z-index: 12552;
  height: 12.375rem;
  width: 12.375rem;
  background-color: #fff;
  border-radius: 50%;
	cursor: pointer;
	box-shadow: 0 2px 16px #ccc, 0 0 1px #ccc, 0 0 1px #ccc;  
	
}
.NumAi img{
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.renwu {

  height: 12rem;
  width: 12rem;
}

.xingzhuang {
  position: absolute;
  left: 2.1875rem; 
  top: 2.5rem;
}

.tags {
  position: absolute;
  bottom: 1.875rem;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 0.875rem;
	white-space: nowrap;
}

.ListBox {
  display: flex;
  justify-content: center;
  /* padding: 0rem 22.9375rem; */
}

.leftBox {
	margin-right: 2.5rem;
  width: 48.375rem;
}

.top_header {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #E6E6E6;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0px;
}

.titleNickname {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.titleNickname img {
  height: 30px;
  width: 30px;
}

.g_famousFamilyArchives {
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #333333;
  padding-left: 10px;
}

.More {
  white-space: nowrap;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
}
.index_raido_text > .is-active > .el-radio-button__inner{
	font-size: 1rem;
}
.oneLinebox {
  margin-top: 20px;
  cursor: pointer;
}

.oneLinebox>.line-one {
  position: relative;
}

.line-one>div p {
  margin: 0;
  padding: 0;
}

.introduce {
  position: absolute;
  left: 0;
  bottom: 3px;
  color: #fff;
  background-color: rgb(0, 0, 0, .5);
  padding: 5px 2.5%;
  width: 95%;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.introduce p {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.oneLinebox>div>img {
  height: 25rem;
  width: 48.375rem;
	object-fit: fill;
}

.threeListBox {
  display: flex;
	flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
}

.Dline {
  position: relative;
}

.Dline>div p {
  padding: 0;
  margin: 0;
}

.Dline>div {
  font-size: 16px;
}

.Dline>img {
  height: 328px;
  width: 244px;
}

.Dline_two {
  cursor: pointer;
	margin:10px 0px;
}

.Dline_two img {
	object-fit: cover;
  height: 328px;
  width: 244px;

}

.yunDiv {
  width: 48.375rem;
  overflow: hidden;
  margin-top: 1.25rem;
}

.yunDiv div>img {
  height: 2.9375rem;
  width: 7.25rem;
}
.about-index{
	height:13rem;
}
.container1 {
  width: 48.375;
  /* height: 37.5rem; */
  margin: 20px auto 20px auto;
  overflow: hidden;
}

/*手风琴样式*/
.or-container {
  /* display: flex; */
  width: 100%;
  box-sizing: border-box;
  height: 100%;
}
.imgs_sfq{
	cursor: pointer;
	/* height: rem; */
	width: 100%;
	margin: top 10px;
	object-fit: contain;
}

/* .or-container:before {
  background-color: rgba(0, 0, 0, 0.4);
} */

.box {
  flex: 1;
  overflow: hidden;
  transition: .5s;
  line-height: 0;
  margin: 0px 2px;
  position: relative;
}

.box>img {
  width: 100%;
  height: calc(100%);
  -o-object-fit: cover;
  object-fit: cover;
  transition: .5s;
  position: relative;
  cursor: pointer;
}

.eleactive {
  flex: 1 1 34%;
}

.eleactive>img {
  width: 100%;
  height: 100%;
	object-fit: fill;
}

.ZpList {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.DyBox {
	width: 7.5rem;
  font-size: 14px;
  text-align: center;
	cursor: pointer;
}
.mjflex{
	height: 0;
	width: 7.5rem;
	cursor: auto;
}
.DyBox img {
	object-fit: contain;
  width: 7.5rem;
  height: 8.75rem;
}

.el-radio-button {
  padding: 0 5px;
}

.line-one-list,
.line-two-list {
  display: flex;
  justify-content: space-between;
}

.line-two-list {
  align-content: flex-start;
  overflow: hidden;
}

.line-two-list>div {
  /* flex-basis: calc(25% - 10px); */
  /* 设置元素的初始大小，这里假设每行显示3个元素 */
  /* margin: 5px; */
}



.line-two-list {
  margin-top: 30px;
}

.line-one-list>div img {
  /* height: 366px; */
  /* width: 370px; */
	height: 22.875rem;
	width: 23.125rem;
	object-fit: contain;
}

.line-two-list>div img {

	height: 15.75rem;
	width: 10.875rem;
	object-fit: contain;
}

.line-one-list>div>div,
.line-two-list>div>div {
  color: #333333;
  font-size: 14px;
}

/* 右边 */
.rightCoutinuer {
  width: 22rem;

}

.right_header {
  width: 22rem;
  display: flex;
  border-bottom: 1px solid #E6E6E6;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0px;
}

.MJ_Zx {
  display: flex;
  margin: 15px 0px;
  cursor: pointer;
}

.MJ_Zx .imgs img {
  height: 5.625rem;
  width: 90px;
}

.rightListWz {
  width: 100%;
}

.title_date {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 15px;
}

.imgs img {
  vertical-align: middle;
}

.gzs_datax {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.jieshao1 {
  font-size: 14px;
  color: #333333;
  overflow: hidden;
  height: 70px;
	line-height: 1.4rem;
	font-weight: bold;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.gzs {
  font-size: 12px;
  color: #333333;
}

.timer {
  color: #999999;
  font-size: 12px;
}

.guanggao {
  position: relative;
  width: 100%;
  height: 138px;
}

.guanggao img {
  height: 100%;
  width: 100%;
}

.guanggao>div {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px 10px;
  color: #FFFFFF;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.4);
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.zhibo {
  width: 22rem;
}

.zhiboList {
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
}

.zhiboList>img {
  width: 100%;
  height: 220px;
}

.zhiboList>div {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 7px;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  background: rgba(201, 196, 196, 0.4);
}

.divJiangtang {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
}

.zanting {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mp4s {
  width: 100%;
  height: 13.75rem;
}

.jiandingBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.jianding {
  width: 100%;
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;
}

.jianding div img {
  width: 110px;
  height: 130px;
  /* white-space: nowrap; */
}

.jdName {
  text-align: center;
  font-size: 14px;
}

.myJd {
  width: 100%;
  font-size: 14px;
  color: #A53124;
  background: #F4E6E4;
  border-radius: 4px;
  padding: 14px 0px;
  text-align: center;
}

.elZt {
  margin-bottom: 10px;
  cursor: pointer;
}

.elZt img {
  width: 100%;
  height: 13.75rem;
}

.el_title {
  color: #333;
  font-weight: bold;
  font-size: 14px;
  padding: 15px 0px;
	line-height: 1.6rem;
}

.el_content {
  font-size: 12px;
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shopBox {
  width: 74.6875rem;
  margin: 0 auto;
}

.shop_header {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #E6E6E6;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0px;
}

.shopList {
  margin: 25px 0px;
  display: flex;
  justify-content: space-between;
}

.line-shop {
  width: 284px;
  height: 380px;
  background: #F6F6F6;
}

.shopImg {
  width: 100%;
}

.shopImg img {
  height: 17.75rem;
  width: 100%;
}

.shopName {
  padding: 20px;
  font-size: 18px;
  color: #333333;
}

.money {
  padding-left: 20px;
  display: flex;
}

.yj {
  color: #E21F3E;
}

.zh {
  margin-left: 20px;
  color: #999999;
  text-decoration: line-through;
}</style>