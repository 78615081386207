<template>
    <div>
        <button @click="getCurrentLocation">获取当前位置</button>
        <p v-if="location">当前位置：{{ location }}</p>
    </div>
</template>
  <!-- <script src="https://map.qq.com/api/gljs?v=1.exp&key=IQSBZ-OUNEQ-CPP5K-4TJ4R-XMMGT-XDF7B"></script> -->

<script>

export default {
    data() {
        return {
            location: null,
        };
    },
    methods: {
        getCurrentLocation() {
            this.$getLocation().then(location =>{
            console.log(location);
            }).catch(error =>{
            console.log(error);
            });
        },
    },
};
</script>
  