<template>
	<div class="content">
		<div class="gobacksty">
			<div @click="tztop" class="gobacksty-top">资讯</div>
			<div class="gobacksty-detail">资讯详情</div>
		</div>
		<div class="zxcont">
			<div class="wzcont">
				<div class="wzcontent-top" @click="copy()">
					<div>分享</div>
					<img src="http://ryfw51f4p.hn-bkt.clouddn.com/wzDetail/fenxiang.png" />
				</div>
				<el-divider></el-divider>
				<div class="wzcontent-title">{{zxDetail.title}}</div>
				<div class="wzcontent-jj">
					<div>{{zxDetail.write_time.split(' ')[0]}}</div>
					<div>来源：{{zxDetail.source}}</div>
					<!-- <div>阅读：331</div>
              <div>编辑：付园园</div> -->
				</div>
				<div class="wzcontent-cont" v-html="zxDetail.content">

					<!-- // console.log(res.data.data.activity.data[0].content.replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "")) -->
				</div>
			</div>
			<!-- 热门资讯 -->
			<div class="rmzx">
				<div class="rmzx-tit">热门资讯</div>
				<el-divider></el-divider>
				<div class="rmzx-list" v-for="(item,index) in rmzxList.slice(0,3)" :key="index"
					@click="zxDetails(item)">
					<div class="rmzx-list-img">
						<img :src="item.res_image" />
					</div>
					<div class="rmzx-list-content">
						<div class="rmzx-list-content-tit">{{item.title}}</div>
						<div class="rmzx-list-content-jj">
							<div class="rmzx-list-c-jj-come">{{item.source}}</div>
							<div class="rmzx-list-c-jj-date">{{item.write_time.split(' ')[0]}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				id: "",
				zxDetail: {},
				rmzxList: [],
				content: ''
			}
		},
		created() {
			this.id = this.$route.params.id
			this.getDetail(this.id); //获取文章详情
			this.getlist()
		},
		methods: {
			getDetail(id) {
				this.$http.get(this.constant.loadurl.get_article_info, {
					params: {
						id: id
					}
				}).then(res => {
					if (res.data.code == 0) {
						this.zxDetail = res.data.data
					} else {
						this.$message({
							message: res.data.msg || res.data.message,
							type: "warning"
						})
					}
				});
			},
			getlist() {
				this.$http.get(this.constant.loadurl.get_article).then(res => {
					if (res.data.code == 0) {
						this.rmzxList = res.data.data.activity.data
					} else {
						this.$message({
							message: res.data.msg || res.data.message,
							type: "warning"
						})
					}
				});
			},
			// 复制当前网页链接
			copy() {
				let _this = this;
				var domUrl = document.createElement("input");
				domUrl.value = window.location.href.split('#')[0] + '#/inforDetail/' + _this.id
				domUrl.id = "creatDom";
				document.body.appendChild(domUrl);
				domUrl.select(); // 选择对象
				document.execCommand("Copy"); // 执行浏览器复制命令
				let creatDom = document.getElementById("creatDom");
				creatDom.parentNode.removeChild(creatDom);
				this.$message({
					message: "链接已复制",
					type: "success"
				})
			},
			// 返回上一页
			tztop() {
				this.$router.push({
					name: 'information'
				})
			},
			// 点击跳转资讯详情
			zxDetails(item) {
				let _this = this;
				_this.getDetail(item.id)
			}
		}
	}
</script>

<style scoped>
	.content {
		padding: 0px 0px 50px 0px;
		max-width: 1200px;
	}

	.gobacksty {
		max-width: 1200px;
		/* margin: 30px auto; */
		margin: 30px auto;
		height: 21px;
		line-height: 21px;
		display: flex;
		font-size: 14px;
		cursor: pointer;
	}

	.gobacksty-top {
		background-color: #F6F6F6;
		color: #000;
		padding: 0px 20px;
	}

	.gobacksty-detail {
		background: url("https://mingjiahome.zhyell.com/flag.png") no-repeat;
		background-size: 100% 100%;
		width: 114px;
		height: 21px;
		text-align: center;
		color: #fff;
	}

	.zxcont {
		display: flex;
		justify-content: space-between;
	}

	.wzcont {
		width: 745px;
		padding: 30px 30px 100px 30px;
		/* margin: 0 auto; */
		background-color: #F5F5F5;
	}

	.wzcontent-top {
		color: #333;
		font-size: 14px;
		line-height: 14px;
		display: flex;
		justify-content: flex-end;
		cursor: pointer;
	}

	.wzcontent-top img {
		width: 14px;
		height: 14px;
		margin-left: 10px;
	}

	.wzcontent-title {
		color: #333333;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
		margin: 20px 0px;
	}

	.wzcontent-jj {
		color: #777;
		font-size: 14px;
		display: flex;
		justify-content: center;
	}

	.wzcontent-jj div {
		margin: 0px 20px;
	}

	.wzcontent-cont {
		color: #333;
		font-size: 14px;
		line-height: 24px;
		text-indent: 30px;
		margin-top: 30px;
	}

	.wzcontent-cont ::v-deep img {
		/* border:1px solid red; */
		width: 90% !important;
	}

	.wzcontent-cont ::v-deep iframe {
		/* border:1px solid red; */
		width: 700px !important;
		height: 300px;
		margin: 0 auto;
	}

	.ql-video {
		width: 500px;
		height: 300px;
		display: block;
	}

	/* 热门资讯 */
	.rmzx {
		width: 320px;
		padding: 20px;
		max-height: 410px;
		background: #FFFFFF;
		box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.15);
	}

	.rmzx-tit {
		color: #333;
		font-weight: bold;
		font-size: 20px;
		line-height: 25px;
	}

	.rmzx-list {
		display: flex;
		margin: 20px 0px;
	}

	.rmzx-list-img img {
		width: 90px;
		height: 90px;
		object-fit: cover;
	}

	.rmzx-list-content {
		margin-left: 10px;
		width: 100%;
	}

	.rmzx-list-content-tit {
		color: #333;
		font-size: 14px;
		line-height: 24px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	.rmzx-list-content-jj {
		display: flex;
		justify-content: space-between;
		margin-top: 23px;
	}

	.rmzx-list-c-jj-come {
		color: #333;
		font-size: 12px;
	}

	.rmzx-list-c-jj-date {
		color: #999;
		font-size: 12px;
	}
</style>