import Vue from 'vue'
import axios from 'axios'
import VueRouter from 'vue-router'
import HomeView from '../views/index/index.vue'
import MingRen from '../views/MingRen.vue'
// 个人官网
import FamousHome from "../views/Famous/Famous_Nav"
import FamousIndex from "../views/Famous/Famous_index"
import mjfiles from "@/views/Famous/mjfiles"
import mjhall from "@/views/Famous/mjhall"
import mjzx from "@/views/Famous/mjzx"
import mjzxDetail from '@/views/Famous/mjzxDetail'
// 官网
import Home from '../views/index/home.vue'
import copyright from '@/views/appraisalFiling/copyright.vue'
import copyrightDetail from '@/views/appraisalFiling/copyrightDetail.vue'
import worklist from '@/views/worklist/worklist.vue'
import cloudHall from '@/views/cloudHall/cloudHall.vue'
import worksAppreciate from '@/views/worklist/worksAppreciate.vue'
import worksAppDetail from '@/views/worklist/worksAppDetail.vue'
import ztactivities from '@/views/ztactivities/ztactivities.vue'
import information from '@/views/information/information.vue'
import liveBroadcast from '@/views/liveBroadcast/liveBroadcast.vue'
import shoppingmall from '@/views/shoppingmall/shoppingmall.vue'
import Works from '@/views/Famous/Works.vue'
import workdetail from '@/views/worklist/workdetail.vue'
import ztDetail from '@/views/ztactivities/ztDetail.vue'
import inforDetail from '@/views/information/inforDetail.vue'
import MallDetail from '@/views/shoppingmall/Mall_details.vue'
import city from '@/views/city.vue'
import shopList from '@/views/shoppingmall/shopList.vue'
import fangdajing from '@/views/index/test.vue'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: Home,
    component: Home,
    children: [{
			path: '/fangdajing',
			name: 'fdj',
			component: fangdajing
		},{
     path: 'MingRen',
     name: 'MingRen',
     component: MingRen
      },
      {
        path: '/',
        name: 'home',
        component: HomeView,
      },
      {
        path: '/copyright', //鉴定备案
        name: 'copyright',
        component: copyright
      },
      {
        path: '/copyrightDetail', //鉴定备案详情
        name: 'copyrightDetail',
        component: copyrightDetail
      },
      {
        path: '/worklist', //作品欣赏
        name: 'worklist',
        component: worklist
      },
      {
        path: '/workdetail', //作品欣赏详情
        name: 'workdetail',
        component: workdetail,
				meta:{title:'worklist'}
      },
      {
        path: '/cloudHall', //云展馆
        name: 'cloudHall',
        component: cloudHall
      },
      {
        path: '/worksAppreciate', //名家讲堂
        name: 'worksAppreciate',
        component: worksAppreciate,
      },
      {
        path: '/worksAppDetail/:id?', //名家讲堂详情
        name: 'worksAppDetail',
        component: worksAppDetail,
				 meta: { title: '/worksAppreciate' }
      },
      {
        path: '/ztactivities', //专题活动
        name: 'ztactivities',
        component: ztactivities
      },
      {
        path: '/ztDetail/:detailId?', //专题活动详情
        name: 'ztDetail',
        component: ztDetail
      },{
        path: '/information', //资讯
        name: 'information',
        component: information
      },{
        path: '/inforDetail/:id?', //资讯详情
        name: 'inforDetail',
        component: inforDetail
      },{
        path: '/liveBroadcast', //直播
        name: 'liveBroadcast',
        component: liveBroadcast
      },{
        path: '/shoppingmall', //线上商城
        name: 'shoppingmall',
        component: shoppingmall
      }, {
        path:'/MallDetail/:id?',// 商城详情
        name:'MallDetail',
        component:MallDetail
      }, {
        path:'/shopList/:id?',// 商城分类列表详情
        name:'shopList',
        component:shopList
      }
    ],
  },
  {
    path:'/city',// 商城详情
    name:'city',
    component:city
  },
 {
    path: '/FamousHome',
    name: 'FamousHome',
    component: FamousHome,
    children: [{
      path: '/FamousIndex',//名家个人首页
      name: 'FamousIndex',
      component: FamousIndex
    },{
      path:'/Works',
      name:'Works',
      component:Works
    },
    {
      path: '/mjfiles',//名家档案
      name: 'mjfiles',
      component: mjfiles
    },{
      path: '/mjhall',//名家讲堂
      name: 'mjhall',
      component: mjhall
    },{
      path: '/mjzx',//相关资讯
      name: 'mjzx',
      component: mjzx
    },{
      path: '/mjzxDetail',//相关资讯详情
      name: 'mjzxDetail',
      component: mjzxDetail
    }]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;

//路由拦截器,全局前置守卫
router.beforeEach((to, from, next) => {
	if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
		if (localStorage.getItem('token')) { // 通过localStorage获取当前的token是否存在
			next();
		} else {
			next({
				path: '/userlist',
				// query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
			})
		}
	} else {
		window.scrollTo(0, 0);
		next();
	}
	/*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
	if (to.fullPath == "/login") {
		if (localStorage.getItem('token')) {
			if (from.fullPath == "/") {
				next({
					path: "/userlist", //跳首页
				});
			} else {
				next({
					path: from.fullPath
				});
			}

		} else {
			window.scrollTo(0, 0);
			next();
		}
	}
})
// 请求超时时间
axios.defaults.timeout = 120000;
// http请求拦截
axios.interceptors.request.use(config => {
	// console.log("请求拦截器", config);
	//如果存在token，请求携带这个token
	// windows.sessionStorage.getItem()
	if (localStorage.getItem("token")) {
		config.headers['Authorization'] = "Bearer " + localStorage.getItem("token");
	}
	return config;
}, error => {
	// 对请求错误做些什么
	Vue.prototype.$message.error('请求超时')
	return Promise.reject(error)
});

let msgFlag = null;
// http响应拦截
axios.interceptors.response.use(response => {
	// res是响应的结果
	switch (response.data.code) {
		case 1001:
			// 缺少token
			// 响应成功的拦截
			Vue.prototype.$message.error(response.data.msg || response.data.message)
			localStorage.removeItem('Authorization')
			router.push({
				name: 'login'
			})
			break
		case 1002:
			// 强制下线token无效
			// 响应成功的拦截
			Vue.prototype.$message.error(response.data.msg || response.data.message)
			localStorage.removeItem('Authorization')
			router.push({
				name: 'login'
			})
			break
		case 1003:
			// 强制下线token过期
			// 响应成功的拦截
			Vue.prototype.$message.error(response.data.msg || response.data.message)
			localStorage.removeItem('Authorization')
			router.push({
				name: 'login'
			})
			break
		case 404:
			if (response.data.msg !== null) {
				Vue.prototype.$message.error(response.data.msg || response.data.message)
			} else {
				Vue.prototype.$message.error('未知错误')
			}
			break
		case 500:
			// 错误
			if (response.data.msg !== null) {
				Vue.prototype.$message.error(response.data.msg)
			} else {
				Vue.prototype.$message.error('未知错误')
			}
			break
		default:
			return response
	}
	return response
}, error => {
	if (error.response.data.code == 4001) {
		Vue.prototype.$message.error("此账号已在其他设备登录，五秒后返回登录页面")
		localStorage.removeItem('Authorization')
		setTimeout(() => {
			router.push({
				name: 'login'
			})
		}, 5000)
	} else {
		if (error.response.data.msg) {
			if (!msgFlag) {
				msgFlag = Vue.prototype.$message({
					message: error.response.data.msg,
					onClose: () => {
						msgFlag = null
					},
					type: 'error'
				})
			}
			return Promise.reject(error.response.data.msg) // 返回接口返回的错误信息
		} else {
			// 返回状态码不为200时候的错误处理
			Vue.prototype.$message.error(error.toString())
			return Promise.resolve(error)
		}
	}

});

