 <template>
 	<div id="goodsPics">
 		<!--当前查看商品图区域-->
 		<div id="imgPre" style="position: relative;" @mouseleave="seeEnd">
 			<div ref="imgPre">
 				<img :src="url" alt="picNow">
 			</div>
 			<div class="topMask" @mouseenter="seeBegin($event)" @mousemove="move($event)"></div>
 			<!--鼠标放大镜模块-->
 			<div ref="move" v-show="isShow" class="move" :style="cursorMask">
 			</div>
 		</div>
 		<!--放大镜区域 —— 查看商品图放大后的效果-->
 		<div v-if="isShow" id="seeDetail" ref="bigImg">
 			<img :src="Bigurl" alt="" :style="imgMove">
 		</div>
 	</div>
 </template>
 <script>
 	export default {
 		data() {
 			return {
 				url: 'https://img1.baidu.com/it/u=1122829308,2218000143&fm=253&fmt=auto&app=138&f=JPEG?w=499&h=378',
 				Bigurl: 'https://img1.baidu.com/it/u=1122829308,2218000143&fm=253&fmt=auto&app=138&f=JPEG?w=499&h=378', //放大镜区域的图片资源
 				//上面两个是初始值
 				urlIndex: -1, //urlIndex用于在图片列表中
 				isShow: 0,
 				cursorMask: {
 					transform: ''
 				}, //使用CSS3控制原图上鼠标预览区域的移动
 				imgMove: {
 					transform: ''
 				}, //使用CSS3控制放大镜区域图片的移动
 			}
 		},
 		methods: {
 		
 			move(e) {
 				/*  效果实现的核心，计算光标位置的坐标，通过修改鼠标预览区域的坐标来实现放大区域的移动
 				 *  注意这里使用的 offsetX 和 offsetY，是获取当前元素相对于其父元素的对应偏移量
 				 */
 				var x = e.offsetX - 75;
 				var y = e.offsetY - 75;
 				//放置鼠标预览区域移动到原图区域左边以外
 				if (x < 0) {
 					x = 0;
 				} else if (x > 300) {
 					//防止预览区域移动到右边以外
 					//300 = 450 - 150
 					x = 300;
 				}

 				if (y < 0) {
 					y = 0;
 				} else if (y > 300) {
 					y = 300;
 				}

 				//使用模板字符串和变量拼接
 				//这里注意: 根据实际上的效果，我们的预览区域左移时，放大镜查看区域的大图相当于等比例地右移，所以我们这里应当设置大图的移动方向与预览区相反，移动距离与预览区成比例
 				//比例计算：放大倍数 = 大图所在div的宽高 / 原图所在div的宽高
 				this.cursorMask.transform = `translate(${x}px, ${y}px)`;
 				this.imgMove.transform = `translate(-${2*x}px, -${2*y}px)`; //向反方向成比例移动
 			},
 			seeBegin(e) {
 				//move(e); 这里也可以不用
 				this.Bigurl = this.url;
 				this.isShow = 1;
 			},
 			seeEnd() {
 				//鼠标移出原图区域时，清空相关信息
 				this.Bigurl = "";
 				this.left = 0;
 				this.top = 0;
 				this.isShow = 0;
 			}
 		},
 	}
 </script>
 <style>
 	#page {
 		position: relative;
 		height: 700px;
 		width: 1246px;
 		left: 19.3%;
 		top: 111px;
 		background-color: aqua;
 	}

 	/*商品图*/
 	#goodsPics {
 		width: 452px;
 		height: 626px;
 		/* background-color: blue; */
 		position: relative;
 		top: 10px;
 	}

 	/*当前所查看的图片，也即所需要局部放大查看的图片*/
 	.imgNow {
 		position: relative;
 		top: 15px;
 		left: 37px;
 	}

 	.imgNow li {
 		/*CSS3给盒子:hover添加border时，盒子发生抖动的解决方案之一: 给盒子设置初始透明边框*/
 		display: block;
 		float: left;
 		height: 54px;
 		width: 54px;
 		margin-left: 15px;
 		border: 2px solid transparent
 	}

 	.imgNow li:hover,
 	#sign {
 		border: 2px solid red;
 	}

 	#seeDetail {
 		/*注意定位，父相子绝*/
 		position: absolute;
 		background-color: aliceblue;
 		width: 600px;
 		height: 600px;
 		top: 0%;
 		left: 101%;
 		overflow: hidden;
 		/*隐藏图片溢出查看框之外的部分*/
 		border: 2px solid #f90;
 		background-position: 0 0;
 		background-repeat: no-repeat;
 	}

 	#seeDetail img {
 		position: absolute;
 		/*这里注意下，初始时候将查看框内待放大图的左上角定位与查看框的左顶点处，也即其定位原点处*/
 		top: 0;
 		left: 0;
 		background-repeat: no-repeat;

 		/*我自己的图片设置的宽高是450×450，所以这里放大部分的图片的宽高设置为原图的四倍*/
 		/*这个倍数需要注意，后面进行坐标变换的时候要用上*/
 		width: 1920;
 		height: 1000px;
 	}

 	/*鼠标查看区域 —— 也即需要放大局部放大查看的区域*/
 	.move {
 		position: absolute;

 		/*初始定位*/
 		left: 0;
 		top: 0;

 		width: 150px;
 		height: 150px;
 		cursor: move;
 		background: rgba(142, 223, 255, 0.407);
 	}

 	.topMask {

 		/*覆盖在放大图的原图表面上的一个遮罩层*/
 		width: 452px;
 		height: 454px;
 		position: absolute;
 		top: 0;
 		left: 0;
 		z-index: 5;
 	}
 </style>