<template>
  <div class="content">
    <div class="wzcontent">
      <div class="wzcontent-one">
        <div @click="goback" class="wzcontent-one-back">{{"<"}}返回</div>
        <div class="wzcontent-top">
          <div>分享</div>
          <img src="http://ryfw51f4p.hn-bkt.clouddn.com/wzDetail/fenxiang.png"/>
        </div>
      </div>

      <el-divider></el-divider>
      <div class="wzcontent-title">{{MjDetail.title}}</div>
      <div class="wzcontent-jj">
        <div>{{MjDetail.write_time}}</div>
        <div>来源：{{MjDetail.source}}</div>
      <!--  <div>阅读：331</div>
        <div>编辑：付园园</div> -->
      </div>
      <p class="wzcontent-cont" v-html='MjDetail.content'></p>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
				mId:'',
				MjDetail:{}
				
      }
    },
		mounted(){
			if(this.$route.query.id ){
				this.mId = this.$route.query.id
			}
			this.mjzxDetail()
		},
    methods: {
		// 名家资讯接口
		 mjzxDetail(){
			 this.$http.get(this.constant.loadurl.get_ming_article_info + '?id=' + this.mId).then(res=>{
				 console.log(res.data,'res.dat')
				 if(res.data.code == 0){
					 this.MjDetail = res.data.data
				 }
			 })
		 },
    // 返回上一页
     goback() {
			 this.$router.go(-1);
       // this.$router.push({
       //   name: 'mjzx'
       // })
     },
    }
  }
</script>

<style scoped>
  .content{
    padding: 0px 0px 50px 0px;
    max-width: 1200px;
  }
  .wzcontent{
    width: 100%;
    padding: 30px 30px 100px 30px;
    margin: 0 auto;
    background-color:#F5F5F5;
  }
  .wzcontent-one{
    display: flex;
    justify-content: space-between;
  }
  .wzcontent-one-back{
    color: #333;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
  }
  .wzcontent-top{
    color: #333;
    font-size: 14px;
    line-height: 14px;
    display: flex;
  }
  .wzcontent-top img{
    width: 14px;
    height: 14px;
    margin-left: 10px;
  }
  .wzcontent-title{
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0px;
  }
  .wzcontent-jj{
    color: #777;
    font-size: 14px;
    display: flex;
    justify-content: center;
  }
  .wzcontent-jj div{
    margin: 0px 20px;
  }
  .wzcontent-cont{
    color: #333;
    font-size: 14px;
    line-height: 24px;
    text-indent: 30px;
  }
</style>