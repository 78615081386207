import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import constant from "./config/constant.js"
import VueGeolocation from 'vue-browser-geolocation';
// import dayjs from "dayjs"
const moment = require('moment')
require('moment/locale/zh-cn')
// 将constant挂在在prototype上挂载到Vue实例上面
Vue.prototype.constant=constant;
import qs from "qs"
Vue.prototype.qs=qs;
Vue.prototype.$bus = new Vue();
//全局引入axios
import axios from 'axios';
// import "./config/axios.js";
//注册axios组件
Vue.prototype.$http = axios;
// Vue.prototype.$dayjs = dayjs;
import {postRequest} from "./config/api.js"
import {getRequest} from "./config/api.js"
import {putRequest} from "./config/api.js"
import {deleteRequest} from "./config/api.js"
// 挂载到prototype的vue实例上面
Vue.prototype.postRequest = postRequest;
Vue.prototype.getRequest = getRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.deleteRequest = deleteRequest;

Vue.use(VueGeolocation);
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(require('vue-moment'),{
  moment
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
