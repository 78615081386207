<template>
	<div class="content">
		<div class="gobacksty">
			<div @click="tztop" class="gobacksty-top">首页</div>
			<div class="gobacksty-detail">名家讲堂</div>
		</div>
		<div class="vedioxhsty" v-if="hall_course.length != 0">
			<div class="vedioxhsty-left">
				<video class="vediosty-video" controls>
					<source class="" :src="selectVedio.video_url" type="video/mp4">
				</video>
				<div class="vedioxhsty-left-tit">{{hall.title}}</div>
			</div>
			<div class="vedioxhsty-rig">
				<div class="vedioxhsty-rig-tit">{{hall.title}}</div>
				<div class="vedioxhsty-rig-num">共{{hall_course.length}}节</div>
				<div class="hengxian"></div>
				<div class="vedioxhsty-rig-xh">
					<div class="vedioxhsty-rig-xhlist"
						v-for="(item,index) in hall_course.slice((currentPage - 1) * 2,currentPage * 2)" :key="index"
						@click="selectVdetail(item)">
						<video :class="tabindex==item.index ? 'vedioxhsty-rig-imgwei' : 'vedioxhsty-rig-img'">
							<source :src="item.video_url" type="video/mp4">
						</video>
						<div class="vedioxhsty-rig-tit">{{item.title}}</div>
					</div>
					<div style="margin: 0 10px;">
						<el-button @click="vedioup" class="vedioxhsty-btn" type="primary"
							icon="el-icon-arrow-up"></el-button>
						<el-button @click="vediodown" class="vedioxhsty-btn" type="primary"
							icon="el-icon-arrow-down"></el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 讲堂介绍 -->
		<div class="jtjssty">
			<div class="liststy-top-left">
				<img class="liststy-top-left-img"
					src="https://mingjiahome.zhyell.com/cloudhall/lunbohover.png" />
				<div class="liststy-top-left-tit">讲堂介绍</div>
			</div>
			<div class="jtjssty-grjj">
				<img class="jtjssty-grjj-img" :src="hall.master_image" alt="">
				<div class="jtjssty-grjj-rig">
					<!-- <div class="jtjssty-grjj-rig-top">aaa</div> -->
					<div class="jtjssty-grjj-rig-cont" v-html="hall.master_description">

					</div>
				</div>
			</div>
			<div style="font-size: 18px;font-weight: bold;text-align: left;line-height: 40px;">{{hall.title}}</div>
			<p style="text-indent: 35px;text-align: left;line-height: 40px;font-size: 18px;" v-html="hall.course_description">
			</p>

		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				videoSrc:'',
				hall:{},//详情
				hall_course:[],//视频列表
				selectVedio: {},
				tabindex: 0, //选中高亮index
				currentPage: 1, //当前页数
				vediolist: [{
					index: 0,
					vedioimg: "../../assets/ceshi.mp4",
					name: "第一节：一錾一锤 匠心打造"
				}, {
					index: 1,
					vedioimg: "../../assets/ceshi.mp4",
					name: "第二节：一錾一锤 匠心打造"
				}, {
					index: 2,
					vedioimg: "../../assets/ceshi.mp4",
					name: "第三节：一錾一锤 匠心打造"
				}, {
					index: 3,
					vedioimg: "../../assets/ceshi.mp4",
					name: "第四节：一錾一锤 匠心打造"
				}, {
					index: 4,
					vedioimg: "../../assets/ceshi.mp4",
					name: "第五节：一錾一锤 匠心打造"
				}, ],
				detailId: ''
			}
		},
		created() {
			this.selectVedio = this.vediolist[this.tabindex]
		},
		mounted() {
			let _this = this;
			_this.detailId = this.$route.params.id;
			_this.getDetail()
		},
		methods: {
			getDetail(){
				let _this = this;
				this.$http.get(this.constant.loadurl.get_hall_info, {
					params: {
						id: _this.detailId
					}
				}).then(res => {
					if (res.data.code == 0) {
						_this.hall = res.data.data.hall
						_this.hall_course = res.data.data.hall_course
						_this.selectVedio = _this.hall_course[0]
						// _this.videoSrc = _this.hall_course[0].video_url
						// _this.ztLists = res.data.data.activity
						// console.log(_this.ztLists)
					} else {

					}
				});
			},
			// 返回上一页
			tztop() {
				this.$router.push({
					name: 'worksAppreciate'
				})
			},
			//  选中视频详情
			selectVdetail(item) {
				this.tabindex = item.index
				this.selectVedio = this.vediolist[this.tabindex]
			},
			//点击视频列表向上选择
			vedioup() {
				if (this.currentPage == 1) {
					this.currentPage = Math.ceil(this.vediolist.length / 2)
				} else {
					this.currentPage = this.currentPage - 1
				}
			},
			//点击视频列表向下选择
			vediodown() {
				if (this.currentPage == Math.ceil(this.vediolist.length / 2)) {
					this.currentPage = 1
				} else {
					this.currentPage = this.currentPage + 1
				}
			}
		}
	}
</script>

<style scoped>
	.content {
		padding: 0px 0px 50px 0px;
	}

	.gobacksty {
		max-width: 1200px;
		/* margin: 30px auto; */
		margin: 30px auto;
		height: 21px;
		line-height: 21px;
		display: flex;
		font-size: 14px;
		cursor: pointer;
	}

	.gobacksty-top {
		background-color: #F6F6F6;
		color: #000;
		padding: 0px 20px;
	}

	.gobacksty-detail {
		background: url("https://mingjiahome.zhyell.com/flag.png") no-repeat;
		background-size: 100% 100%;
		width: 114px;
		height: 21px;
		text-align: center;
		color: #fff;
	}

	/* 视频播放css */
	.vedioxhsty {
		max-width: 1200px;
		margin: 30px auto;
		background-color: #333333;
		/* height: 617px; */
		/* padding: 30px; */
		display: flex;
		justify-content: space-between;
	}

	.vedioxhsty-left {
		text-align: left;
		margin: 30px;
	}

	.vediosty-video {
		/* margin-bottom: 200px; */
		width: 870px;
		height: 487px;
		border: 1px solid #fff;
	}

	.vedioxhsty-left-tit {
		color: #fff;
		font-size: 18px;
		margin-top: 20px;
	}

	.vedioxhsty-rig {
		text-align: left;
		background-color: #484848;
		padding: 20px;
	}

	.vedioxhsty-rig-tit {
		font-size: 16px;
		color: #fff;
	}

	.vedioxhsty-rig-num {
		color: #999999;
		font-size: 16px;
		margin: 10px 0px 18px 0px;
	}

	.hengxian {
		width: 239px;
		height: 1px;
		background-color: #4E4E4E;
	}

	.vedioxhsty-rig-xh {
		/* margin-top: 20px; */
	}

	.vedioxhsty-rig-xhlist {
		margin: 20px 0px;
	}

	.vedioxhsty-rig-img {
		width: 231px;
		height: 129px;
		border: 1px solid #fff;
	}

	.vedioxhsty-rig-imgwei {
		width: 231px;
		height: 129px;
		border: 1px solid red;
	}

	.vedioxhsty-rig-tit {
		font-size: 16px;
		color: #fff;
		margin-top: 5px;
	}

	.vedioxhsty-btn {
		/* margin: 0px 5px; */
		background-color: #222222;
		border: none;
		border-radius: 15px;
		width: 100px;
		margin-top: 30px;
	}

	/* 讲堂介绍 */
	.jtjssty {
		max-width: 1200px;
		margin: 0 auto;
	}

	.liststy-top-left {
		display: flex;
	}

	.liststy-top-left-img {
		width: 30px;
		height: 30px;

	}

	.liststy-top-left-tit {
		color: #333;
		font-size: 24px;
		line-height: 30px;
		font-weight: bold;
		margin-left: 10px;
	}

	/* 简介 */
	.jtjssty-grjj {
		display: flex;
		width: 100%;
		margin: 30px 0px;

	}

	.jtjssty-grjj-img {
		width: 295px;
		height: 263px;
		object-fit: contain;
	}

	.jtjssty-grjj-rig {
		background-color: #F4F4F4;
		color: #333;
		text-align: left;
		padding: 20px;
		width: calc(100% - 295px - 40px);
	}

	.jtjssty-grjj-rig-top {
		font-size: 18px;
		margin-bottom: 20px;
	}

	.jtjssty-grjj-rig-cont {
		font-size: 16px;
		line-height: 35px;
	}
</style>