<template>
  <div class="contenterp">
    <!-- 目录 -->
    <div class="mulusty">
      <div class="mulusty-top">
        <div class="shu"></div>
        <div class="mulusty-top-tit">目录</div>
      </div>
      <div class="mulusty-bot" @click="tzDetail(item)" :class="tabindex==item.index ? 'mulusty-botwei' : ''"
        v-for="(item,index) in mululist" :key="index">{{item.name}}</div>
    </div>
    <!-- 档案详情 -->
    <div class="detailSty">
      <!-- 个人简介 -->
      <div class="detailSty-jj">
        <div class="detailSty-jj-top" id="mjjj">
          <div class="detailSty-jj-toptit">名家简介</div>
        </div>
        <div class="detailSty-jj-cont" v-if='danganList.master'>
          <img class="jj-cont-img" :src="danganList.master[0].profiles_img" />
          <div class="jj-cont-detail">
            <div>{{danganList.master[0].name}}</div>
            <div v-html="danganList.master[0].profiles">

            </div>
          </div>
        </div>
      </div>
      <!-- 名家相册 -->
      <div class="detailSty-jj">
        <div class="detailSty-jj-top" id="mjphoto">
          <div class="detailSty-jj-toptit">名家相册</div>
        </div>
        <div class="detailSty-lunb">
          <el-carousel class="lunboSty" height='400px'>
            <el-carousel-item v-for="(item,index) in danganList.album" :key="index">
              <img :src="item.image_url" style='width: 100%;'/>
              <div class="lunboSty-item-tit">{{ item.title }}</div>
            </el-carousel-item>
						<div v-show='danganList.album.length < 1' style='text-align: center;width: 100%;'>
							 <el-empty description="暂无数据"></el-empty>
						</div>
          </el-carousel>
        </div>
      </div>
      <!-- 获得奖项 -->
      <div class="detailSty-jj">
				
        <div class="detailSty-jj-top" id="hdjx">
          <div class="detailSty-jj-toptit">获得奖项</div>
          <div class="toptit-rig"> {{danganList.awards.length}}项</div>
        </div>
        <div class="detacont">
         <div class="detacont-list" v-for="(item,index) in danganList.awards" :key="index">
             <div class="detacont-list-top">
              <img :src="item.image_url" />
            </div> 
            <div class="detacont-list-tit">{{ item.title }}</div>
          </div>
					<div v-show='danganList.awards.length < 1' style='text-align: center;width: 100%;'>
						 <el-empty description="暂无数据"></el-empty>
					</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tabindex: 0,
        danganList:[],
        mululist: [{
          index: 0,
          name: "名家简介",
          idname:"mjjj"
        }, {
          index: 1,
          name: "名家相册",
          idname:"mjphoto"
        }, {
          index: 2,
          name: "获得奖项",
          idname:"hdjx"
        }],
        id:''
      }
    },
    mounted(){
     this.id =  this.$route.query.id
      this.$http.get(this.constant.loadurl.get_ming_da+'?m_id=' + this.id).then(res => {
          // console.log(res.data.data,'名家档案--->');
          this.danganList = res.data.data
					console.log(res.data.data,'-data')
      })
    },
    methods: {

      //点击目录跳转相关简介
      tzDetail(item) {
				console.log(item)
        this.tabindex = item.index;
        this.scrollToView(item.idname)
      },
      scrollToView(name) {
        this.$nextTick(() => {
          let toElement = document.getElementById(name);
          //锚点存在跳转
          if (name) {
            toElement.scrollIntoView();
          }
        })
      },
    }
  }
</script>
<style>
  .contenterp {
    max-width: 1200px;
    margin: 25px auto;
    display: flex;
    justify-content: space-between;
  }

  .mulusty {
    background-color: #F7F7F7;
    padding: 0px 20px;
    height: 320px;
    /* width: 310px; */
  }

  .mulusty-top {
    display: flex;
    line-height: 60px;

  }

  .shu {
    width: 3px;
    height: 18px;
    background-color: #D6D6D6;
    margin-right: 10px;
    margin-top: 23px;
  }

  .mulusty-top-tit {
    width: 270px;
    color: #333;
    font-size: 18px;
    font-weight: bold;
  }

  .mulusty-bot {
    color: #666;
    background-color: #fff;
    padding: 0px 20px;
    line-height: 60px;
    margin-bottom: 20px;
		cursor: pointer;
  }

  .mulusty-botwei {
    background-color: #B62726;
    color: #fff;
  }

  /* 标头 */
  .detailSty {
    width: 850px;
    /* max-height: 900px; */
    overflow: auto;
    overflow-y: scroll;
  }

  /* 设置滚动条的样式 */
  .detailSty::-webkit-scrollbar {
    width: 0px;
  }

  .detailSty-jj {
    margin-bottom: 30px;
  }

  .detailSty-jj-top {
    border-bottom: 1px solid #333333;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
  }

  .detailSty-jj-toptit {
    background-color: #333;
    color: #fff;
    font-size: 18px;
    text-align: center;
    line-height: 60px;
    width: 140px;
  }

  .toptit-rig {
    color: #333;
    font-size: 14px;
    line-height: 60px;
  }

  /* 内容 */
  .detailSty-jj-cont {
    display: flex;
  }

  .jj-cont-img {
    object-fit:fill;
    width: 240px;
    height: 317px;
  }

  .jj-cont-detail {
    color: #333;
    margin-left: 30px;
  }

  .jj-cont-detail div {
    color: #333;
    font-size: 18px;
    font-weight: bold;
  }

  .jj-cont-detail p {
    text-indent: 32px;
    color: #000;
    font-size: 14px;
    line-height: 30px;
  }

  /* 轮播 */
  .detailSty-lunb {
    width: 100%;
    height: 400px;
  }

  .lunboSty {
    margin: 0 auto;
    width: 100%;
    cursor: pointer;
  }

  .lunboSty-item-tit {
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    height: 50px;
    line-height: 50px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    text-align: left;
    font-size: 18px;
    width: 100%;
    padding-left: 20px;
  }

  .detailSty-lunb .el-carousel__button {
    width: 16px !important;
    height: 16px !important;
    background-color: #fff !important;
    position: relative;
    right: -360px;
    bottom: 5px;
    background-image: none !important;
  }

  .detailSty-lunb .el-carousel__indicator.is-active button {
    width: 16px !important;
    height: 16px !important;
    background-color: #006675 !important;
    background-image: none !important;
    position: relative;
    right: -360px;
    bottom: 5px;
  }

  .detailSty-lunb .el-carousel__arrow {
    display: none;
  }

  /* 获得奖项 */
  .detacont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    flex-wrap: wrap; 
  }
  .detacont-list {
    margin-bottom: 30px;
  }

  .detacont-list-top {
    background-color: #ECECEC;
    margin: 0 auto;
    justify-content: center;
    width: 400px;
    height: 246px;
    align-items: center;
    display: flex;
    /* padding: 20px 40px; */
  }
	.detacont-list-top img{
		height:209px;
		width:299px;
		object-fit: contain;
	}
  .detacont-list-tit {
    font-size: 14px;
    color: #333;
    margin-top: 10px;
		text-align: center;
  }
</style>
