var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',[_vm._m(0),_c('div',{staticClass:"liststy"},[_vm._m(1),_c('div',{staticClass:"liststy-bot"},[_c('div',{staticClass:"liststy-bot-xh"},_vm._l((15),function(item,index){return _c('div',{key:index,staticClass:"liststy-bot-sty",on:{"click":function($event){return _vm.qzDetail(item)}}},[_c('img',{staticClass:"liststy-bot-xh-img",attrs:{"src":"https://mingjiahome.zhyell.com/jdba/ban1.png","alt":""}}),_vm._m(2,true),_vm._m(3,true)])}),0)])]),_c('div',{staticClass:"liststy"},[_vm._m(4),_c('div',{staticClass:"liststy-bot"},[_c('div',{staticClass:"liststy-bot-xh"},_vm._l((12),function(item,index){return _c('div',{key:index,staticClass:"liststy-bot-mrmj"},[_c('img',{staticClass:"liststy-bot-imgmrmj",attrs:{"src":"https://mingjiahome.zhyell.com/jdba/ming2.png","alt":""}}),_c('div',{staticClass:"liststy-bot-mrmj-tit"},[_vm._v("许荷英")]),_vm._m(5,true),_vm._m(6,true)])}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-top"},[_c('div',{staticClass:"content-top-con"},[_c('div',{staticClass:"dhksty1"},[_vm._v("司法认证，权威有效")]),_c('div',{staticClass:"dhksty2"},[_vm._v("区块链+可信时间戳技术")]),_c('div',{staticClass:"dhksty3"},[_vm._v("可追溯，防篡改")]),_c('img',{staticClass:"content-top-con-img",attrs:{"src":"https://mingjiahome.zhyell.com/jdba/shuziren.png"}}),_c('div',{staticClass:"content-top-con-btn"},[_vm._v("我要鉴定")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"liststy-top"},[_c('div',{staticClass:"liststy-top-left"},[_c('div',{staticClass:"shutiao"}),_c('div',{staticClass:"liststy-top-left-tit"},[_vm._v("版权作品")])]),_c('div',{staticClass:"liststy-top-rig"},[_vm._v("查看更多>>")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"liststy-bot-xh-tit"},[_c('span',{staticStyle:{"font-size":"15px"}},[_vm._v("《南北合》")]),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("许荷英饰碧莲公主")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"liststy-bot-xh-con"},[_c('div',{staticStyle:{"color":"#E21F3E","font-size":"15px","margin-right":"10px"}},[_vm._v("已上链")]),_c('div',{staticStyle:{"color":"#999999","font-size":"12px","line-height":"23px"}},[_vm._v("编号：767867878")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"liststy-top"},[_c('div',{staticClass:"liststy-top-left"},[_c('div',{staticClass:"shutiao"}),_c('div',{staticClass:"liststy-top-left-tit"},[_vm._v("名人名家")])]),_c('div',{staticClass:"liststy-top-rig"},[_vm._v("查看更多>>")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"liststy-bot-mrmj-cont"},[_vm._v("鉴定备案作品 "),_c('span',{staticClass:"liststy-bot-mrmj-num"},[_vm._v("82")]),_vm._v("件")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"liststy-bot-mrmj-cont"},[_vm._v("在线鉴定作品 "),_c('span',{staticClass:"liststy-bot-mrmj-num"},[_vm._v("12")]),_vm._v("件")])
}]

export { render, staticRenderFns }