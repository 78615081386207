<template>
  <div class="conter">
    <!-- 直播顶部切换 -->
    <div class="zhibtop">
      <div class="zhibtop-cen">
        <div class="zhibtop-cen-left" v-if='zhiboDetail'>
          <img :src="zhiboDetail.broadcast_iamge" @click="qzDetail(zhiboDetail)"/>
          <!-- 判断当前视频状态  当前时间>结束时间-->
          <div v-show="nowDate>zhiboDetail.broadcast_end" class="huifang">
            回放
          </div>
          <div v-show="nowDate<=zhiboDetail.broadcast_end" class="zhibo">
            <img src="https://mingjiahome.zhyell.com/cloudhall/zhibo.gif" />
            <div>直播中</div>
          </div>
          <div class="zhibtop-cen-left-tit">{{zhiboDetail.title}}</div>
        </div>
        <div class="zhibtop-cen-rig">
          <div @click="selectde(itemj,indexj)" class="zhibtop-cen-rig-xh" v-for="(itemj,indexj) in zhibolist"
            :key="indexj">
            <div style="display: flex;align-items: center;position: relative;">
              <i :class="tabindex==indexj ? 'iconwei' : ''" class="el-icon-caret-left"></i>
              <img :class="tabindex==indexj ? 'zhibtop-cen-rig-xh-imgwei' : 'zhibtop-cen-rig-xh-img'"
                class="zhibtop-cen-rig-xh-img" :src="itemj.broadcast_iamge" />
              <!-- 判断当前视频状态  当前时间>结束时间-->
              <div v-show="nowDate>itemj.broadcast_end" class="huifangsmall">
                回放
              </div>
              <div v-show="nowDate<=itemj.broadcast_end" class="zhibosmall">
                <img src="https://mingjiahome.zhyell.com/cloudhall/zhibo.gif" />
                <div>直播中</div>
              </div>
            </div>
            <div class="zhibtop-cen-rig-xh-tit">{{itemj.title}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 直播推荐列表 -->
    <div class="liststy">
      <div class="liststy-top-left">
        <img class="liststy-top-left-img" src="https://mingjiahome.zhyell.com/cloudhall/lunbohover.png" />
        <div class="liststy-top-left-tit">直播推荐</div>
      </div>
      <div class="liststy-bot">
        <div class="liststy-bot-sty" v-for="(item,index) in zbtjList" :key="index" @click="qzDetail(item)">
          <img class="liststy-bot-xh-img" :src="item.broadcast_iamge" alt="">
          <!-- 判断当前视频状态  当前时间>结束时间-->
          <div v-show="nowDate>item.broadcast_end" class="huifang">
            回放
          </div>
          <!-- 当前时间<=结束时间 -->
          <div v-show="nowDate<=item.broadcast_end" class="zhibo">
            <img src="https://mingjiahome.zhyell.com/cloudhall/zhibo.gif" />
            <div>直播中</div>
          </div>
          <div class="liststy-bot-xh-name">{{item.title}}</div>
        </div>
      </div>
      <el-pagination background layout="total, prev, pager, next" :total="total" :page-size="pageSize"
        @current-change="currentChange" @size-change="handleSizeChange" :current-page="currentPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        nowDate: "", //当前时间
        tabindex: 0,
        zhiboDetail: {},
        zbtjList: [], //直播推荐list
        zhibolist: [],
        pageSize: 15,
        currentPage: 1,
				total:0
      }
    },
    created() {
      this.formatDate()
      this.getlist()
    },
    methods: {
      getlist() {
        // this.zhibolist = [];
        this.$http.get(this.constant.loadurl.get_live_list).then(res => {
          if (res.data.code == 0) {
            this.zbtjList = res.data.data
            this.zbtjList.forEach((item, index) => {
              // 根据is_carousel判断是否为轮播图列表
              if (item.is_carousel == 1) {
                this.zhibolist.push(item)
              }
            })
            this.zhiboDetail = this.zhibolist[this.tabindex];
						this.total = res.data.data.length
        	 } else {
            this.$message({
              message: res.data.msg || res.data.message,
              type: "warning"
            })
          }
        });
      },
      // 点击跳转
      qzDetail(item) {
        window.open(item.broadcast_url, '_blank')
      },
      // 点击直播进行切换直播
      selectde(item,index) {
        this.tabindex = index
        this.zhiboDetail = item
      },
      currentChange(e) {
        this.currentPage = e;
      },
      handleSizeChange(val) {
        this.pageSize = val;
      },
      // 获取当前时间
      formatDate() {
        let date = new Date();
        let year = date.getFullYear(); // 年
        let month = date.getMonth() + 1; // 月
        month = month < 10 ? "0" + month : month; // 如果只有一位，则前面补零
        let day = date.getDate(); // 日
        day = day < 10 ? "0" + day : day; // 如果只有一位，则前面补零
        let week = date.getDay(); // 星期
        let weekArr = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
        let hour = date.getHours(); // 时
        hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
        let minute = date.getMinutes(); // 分
        minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
        let second = date.getSeconds(); // 秒
        second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
        this.nowDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      },
    }
  }
</script>

<style scoped>
  /* 分页 */
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #333333 !important;
    color: #fff !important;
  }

  .conter {
    width: 100%;
  }

  /* 直播顶部切换 */
  .zhibtop {
    width: 100%;
    background-color: #F4F4F4;
  }

  .zhibtop-cen {
    max-width: 1200px;

    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    color: #333;
  }

  .zhibo {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 80px;
    border-radius: 20px;
    display: flex;
    background-color: #FF3355;
    line-height: 26px;
    color: #fff;
    font-size: 12px;
  }

  .zhibo img {
    width: 14px;
    height: 13px;
    margin: 5px 5px 0px 10px;
  }

  .huifang {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 50px;
    border-radius: 20px;
    background-color: #8C8C8C;
    line-height: 26px;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }

  /* 左边 */
  .zhibtop-cen-left {
    width: 870px;
    height: 487px;
    margin: 25px 0px;
    position: relative;
  }

  .zhibtop-cen-left-tit {
    font-size: 18px;
    text-align: left;
    margin-top: 20px;
  }

  /* 右边 */
  .zhibosmall {
    position: absolute;
    top: 5px;
    left: 20px;
    width: 70px;
    border-radius: 20px;
    display: flex;
    background-color: #FF3355;
    line-height: 22px;
    color: #fff;
    font-size: 12px;
  }

  .zhibosmall img {
    width: 14px;
    height: 13px;
    margin: 3px 2px 0px 8px;
  }

  .huifangsmall {
    position: absolute;
    top: 5px;
    left: 20px;
    width: 50px;
    border-radius: 20px;
    background-color: #8C8C8C;
    line-height: 22px;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }

  .zhibtop-cen-rig {
    background-color: #E0E0E0;
    height: 590px;
    overflow: auto;
    overflow-y: scroll;
  }
  /* 设置滚动条的样式 */
  .zhibtop-cen-rig::-webkit-scrollbar {
  	width: 0px;
  }
  .zhibtop-cen-rig-xh {
    margin: 25px 30px;
  }

  .el-icon-caret-left {
    color: #E0E0E0;
  }

  .iconwei {
    color: #FF3355;
  }

  .zhibtop-cen-rig-xh-img {
    width: 231px;
    height: 129px;
    border: 2px solid #E0E0E0;
  }

  .zhibtop-cen-rig-xh-imgwei {
    width: 231px;
    height: 129px;
    border: 2px solid #FF3355;
  }

  .zhibtop-cen-rig-xh-tit {
    font-size: 16px;
    text-align: left;
    margin-left: 20px;
    margin-top: 10px;
  }

  /* 直播推荐列表 */
  .liststy {
    margin: 50px auto;
    max-width: 1200px;
    text-align: center;
  }

  .liststy-top-left {
    display: flex;
    /* justify-content: space-between; */
    border-bottom: 2px solid #E6E6E6;
    padding-bottom: 20px;
  }

  .liststy-top-left-img {
    width: 30px;
    height: 30px;

  }

  .liststy-top-left-tit {
    color: #333;
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    margin-left: 10px;
  }

  .liststy-bot {
    margin-top: 30px;
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    text-decoration: none;
  }

  .liststy-bot-sty {
    /* background-color: #F6F6F6; */
    /* border-radius: 10px; */
    width: 380px;
    color: #333;
    margin-bottom: 20px;
    margin: 0px 10px;
    position: relative;
  }

  .liststy-bot-xh-img {
    width: 100%;
    height: 220px;
  }

  .liststy-bot-xh-name {
    color: #333;
    font-size: 16px;
    text-align: left;
    margin: 10px 0px 20px 5px;
    width: 100%;
    overflow: hidden;
    /*超出隐藏*/
    white-space: nowrap;
    /*强制不换行*/
    text-overflow: ellipsis;
    /*隐藏后超出部分以省略号显示*/

  }
</style>
