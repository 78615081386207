<template>
	<div>
		<div class="JianJieBox">
			<div class="leftContinuer">
				<div class="UserMsg">
					<div class="ysj">
						<span class="icon_colun">|</span>
						<span>艺术家简介</span>
					</div>
					<div class="imgs_jieshao" v-if="homeMjList.master">
						<img :src="homeMjList.master[0].profiles_img" alt="">
						<div class="userName">{{ homeMjList.master[0].name }}</div>
						<div class="js" v-html="homeMjList.master[0].profiles"></div>
					</div>
				</div>
				<div class="UserMsg">
					<div class="ysj">
						<span class="icon_colun">|</span>
						<span>名家档案</span>
					</div>
					<div class="imgs_jieshao" v-if="homeMjList.master">
						<img :src="homeMjList.master[0].archives_img" alt="">
						<div class="userName danganName">{{ homeMjList.master[0].lable }}</div>
						<!-- <div class="js" v-html="homeMjList.master[0].archives.slice(0, 100) + '...'"></div> -->
						<div class="js" v-html="homeMjList.master[0].archives"></div>
					</div>
				</div>
			</div>
			<div class="rightContinuer">
				<div class="rightView">
					<div class="S_box">
						<el-carousel indicator-position="outside" height="400px">
							<el-carousel-item v-for="(item, index) in homeMjList.cover" :key="index">
								<img :src="item.image_url" alt="" style='object-fit: contain;'>
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="inZt" @click='vrView'>
						<div  class='names_zuozhe' v-if='homeMjList.master'>{{homeMjList.master[0].name}}</div>
						<img src="https://mingjiahome.zhyell.com/MJHome/rukou2.png" alt="">
					</div>
					<div>
						<div class="top_header">
							<div class="titleNickname">
								<div><img src="https://mingjiahome.zhyell.com/index/icon-11.png" alt=""></div>
								<div class="g_famousFamilyArchives">作品<span>({{ (homeMjList.works &&
                                    homeMjList.works.length) || 0 }})</span></div>
							</div>
							<div @click='more_zuopin'>
								<div class="More">查看更多>></div>
							</div>
						</div>
						<div class="zuopinBox">
							<div class="mySwiper swiper">
								<div class="swiper-wrapper swiper-wrapper-zuopin">
									<div class="swiper-slide sListZp sListZp2" v-for="(item, index) in homeMjList.works" :key="index" @click='home_zp(item)'>
										<img :src="item.images[0]" alt="" class='zpimgs'>
										<div class="ZuoPinName">{{ item.title }}</div>
										<!-- <div class="ChiCun">{{ item.size }}</div> -->
									</div>
									<div v-show='homeMjList.works.length < 1' style='text-align: center;width: 100%;'>
										 <el-empty description="暂无作品"></el-empty>
									</div>
								</div>
								<div class="swiper-button-next" v-show="homeMjList.works.length > 1 "></div>
								<div class="swiper-button-prev" v-show="homeMjList.works.length > 1 "></div>
							</div>
						</div>
						<div class="top_header">
							<div class="titleNickname">
								<div><img src="https://mingjiahome.zhyell.com/index/icon-11.png" alt=""></div>
								<div class="g_famousFamilyArchives">名家讲堂</div>
							</div>
							<div @click='more_jt'>
								<div class="More">查看更多>></div>
							</div>
						</div>
						<div class="BgColor">
							<!-- 		<div class="swiper">
								<div class="swiper-wrapper viewBox">
							<div class="swiper-slide jtDiv" v-for="(item, index) in homeMjList.hall" :key="index">
										<div class="jtimgBox">
											<img :src="item.master_image" class="jtImg" alt="">
											<img src="https://mingjiahome.zhyell.com/MJHome/bofang.png" class="plays" alt="">
											<div class="viewSize">{{ item.title.slice(0, 10) }}</div>
										</div>
									</div>
								</div>
								<div class="swiper-button-next jt_next"></div>
								<div class="swiper-button-prev jt_prev"></div>
							</div> -->
							<div class="homeJt">
							  <div class="homeJiangtang" v-for="(item, index) in homeMjList.hall" :key="index"  @click="mjjthome(item)" v-show='index < 2'>
							    <img class="zanting" src="https://mingjiahome.zhyell.com/index/btn-bofang.png" alt="">
							    <img class="home_cover" :src="item.cover" alt="">
									<div class='title-cover'>{{item.title}}</div>
							  </div>
							</div>
						</div>
				<!-- 		<div class="top_header">
							<div class="titleNickname">
								<div><img src="https://mingjiahome.zhyell.com/index/icon-11.png" alt=""></div>
								<div class="g_famousFamilyArchives">线上商城</div>
							</div>
							<div @click='All_shop'>
								<div class="More">查看更多>></div>
							</div>
						</div> -->
				<!-- 		<div class="shopMj-List" v-if="homeMjList.works_shop">
							<div class="lines-shop" v-for="(item, index) in homeMjList.works_shop.slice(0, 4)" :key="index">
								<div>
									<div class="shopImgs">
										<img :src="item.images[0]" alt="">
									</div>
									<div class="shopNames">{{ item.title }}</div>
									<div class="money_shop">
										<div class="yj-shop">￥ {{ item.original_price }}</div>
										<div class="zh-shop">￥ {{ item.current_price }}</div>
									</div>
								</div>
							</div>
						</div> -->
						<div class="top_header">
							<div class="titleNickname">
								<div><img src="https://mingjiahome.zhyell.com/index/icon-11.png" alt=""></div>
								<div class="g_famousFamilyArchives">相关资讯</div>
							</div>
							<div>
								<div class="More" @click='Dgzx'>查看更多>></div>
							</div>
						</div>
						<div class='ZiXunBox'>
							<div class="ZixunDiv" v-for="(item, index) in homeMjList.article" :key="index" @click='mjzs(item)'>
								<div class="zxImg">
									<img :src="item.res_image" alt="">
								</div>
								<div class="rightziXun">
									<div class="ZxName">
										<div class="Zname">{{ item.title }}</div>
										<div class="zxDetail">详情>></div>
									</div>
									<div class="jianjieBoxs">
										<div class="zxJianjie" v-html="item.content.substr(0, 90) + '....'">
										</div>
										<div class="create_date">{{item.write_time}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footnote></Footnote>
	</div>
</template>
<script>
	import Swiper from 'swiper/bundle'
	import 'swiper/swiper-bundle.css'
	import Footnote from '@/components/Footnote.vue'
	export default {
		components: {
			Footnote
		},
		data() {
			return {
				mId: '',
				homeMjList: []
			}
		},
		mounted() {

			// 接受参数
			this.mId = this.$route.query.id
			this.$http.get(this.constant.loadurl.get_ming_index + '?m_id=' + this.mId).then(res => {
				if (res.data.code == 0) {
					this.homeMjList = res.data.data;
					this.$nextTick(() => {
						var swiper = new Swiper('.swiper', {
							slidesPerView: 4,
							// slidesPerView: 4.5,
							spaceBetween: 10,
							navigation: {
								nextEl: '.swiper-button-next',
								prevEl: '.swiper-button-prev',
							},

						});
					})
				}
			})
		},
		methods: {
			All_shop() {
				this.$router.push({path:'/shoppingmall',query:{id:this.mId}})
			},
			vrView(){
				window.location.href = this.homeMjList.master[0].pavillion_url
			} ,
			home_zp(r){
				console.log('详情')
			},
			// 更多作品
			more_zuopin(){
				this.$router.push({path:'/Works',query:{id:this.mId}})
			},
			mjjthome(res){
				console.log(res,'---res')
				this.$router.push({path:'/mjhall',query:{id:res.id}})
			},
			// 名家讲堂更多
			more_jt(){
				this.$router.push({path:'/mjhall',query:{id:this.mId}})
			},
			//名家资讯详情
			mjzs(i){
				this.$router.push({path:'/mjzxDetail',query:{id:i.id}})
			},
			// 相关资讯更多
			Dgzx(){
				this.$router.push({path:'/mjzx',query:{id:this.mId}})
			}
		}
	}
</script>
<style>
	.JianJieBox {
		display: flex;
		/* padding: 0 22.9375rem;*/
		max-width: 1200px;
		margin:40px auto 0px auto;
		margin-top: 40px;
	}

	.leftContinuer {
		width: 19.375rem;
		background: #F7F7F7;
	}


	.ysj {
		display: flex;
		align-items: center;
		font-weight: bold;
	}

	.icon_colun {
		padding: 15px;
		font-weight: 0 !important;
		color: #D6D6D6;
	}

	.imgs_jieshao {
		position: relative;
		padding: 15px 0px;
		margin: 0 auto;
		width: 16.875rem;
		text-align: center;
		background-color: #FFFFFF;
	}

	.imgs_jieshao img {
		width: 15rem;
		height: 317px;
	}

	.userName,
	.js {
		padding: 0px 15px;
	}
	.js p{
		line-height: 1.5rem;
	}

	.userName {
		margin: 20px 0px;
		text-align: left;
		font-weight: bold;
	}

	.js {
		margin: 20px 0px;
		font-size: 14px;
		text-align: left;
		text-indent: 1em;
	}

	.js p {
		margin: 0;
		padding: 0;
	}

	.danganName {
		position: absolute;
		top: 275px;
		left: 15px;
		z-index: 2;
		width: 14rem;
		font-size: 14px;
		font-weight: lighter !important;
		padding: 8px;
		color: #FFFFFF;
		background-color: rgba(0, 0, 0, .5);
	}

	.rightContinuer {
		width: 53.75rem;
		background-color: #FFFFFF;
	}

	.rightView {
		padding-left: 20px;
	}

	.S_box {
		position: relative;
		height: 25rem;
	}
	.S_box img{
		height: 25rem;
		width: 100%;
		object-fit: fill;

	}
	.jsImgs>img {
		width: 53.75rem;
		height: 25rem;
	}

	.inZt {
		margin: 40px 0px;
		height: 9.6875rem;
		width: 100%;
		position: relative;
	}
	.inZt > .names_zuozhe{
		position: absolute;
		top:35px;
		left:45px;
		color: #fff;
		font-size:24px;
		font-family:'宋体';
		font-weight: 600;
	}
	.inZt img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.top_header {
		width: 100%;
		display: flex;
		border-bottom: 1px solid #E6E6E6;
		align-items: center;
		justify-content: space-between;
		margin: 30px 0px;
	}

	.titleNickname {
		display: flex;
		align-items: center;
		padding-bottom: 15px;
	}

	.titleNickname img {
		height: 30px;
		width: 30px;
	}

	.g_famousFamilyArchives {
		font-size: 22px;
		font-family: Source Han Sans SC;
		font-weight: bold;
		color: #333333;
		padding-left: 10px;
	}

	.g_famousFamilyArchives span {
		font-size: 16px;
	}

	.More {
		white-space: nowrap;
		font-size: 14px;
		color: #333333;
	}

	.zuopinBox {
		overflow: hidden;
		position: relative;
		background-color: #F7F7F7;
		padding:30px 0px;
	}

	.sListZp {
		width: 10rem !important;
		/* height: 200px !important; */
		text-align: center;
		background-color: #FFFFFF;
	}
	.sListZp2{
		display: block !important; 
		margin:0 1.9rem;
		padding:.8rem 0!important;
	}
	.sListZp img {
		padding-top: 0 .5rem;
		width: 8.75rem;
		height: 8.125rem;
	}

	.ZuoPinName {
		padding-top: 5px;
		font-size: 12px;
	}

	.ChiCun {
		padding-top: 5px;
		font-size: 12px;
	}

	.BgColor {
		position: relative;
		overflow: hidden;
		background: #FFFFFF;
	}
	.homeJt{
		display: flex;
		justify-content: space-between;
		background-color: #F7F7F7;
		padding: 1.25rem 1.5rem;
	}
	.homeJiangtang{
		position: relative;
		cursor: pointer;
	}
	.home_cover{
		width: 23.75rem;
		height: 13.75rem;
	}
	.title-cover{
		font-size: 0.875rem;
		padding-top: 0.625rem;
	}
	.JtBox {
		display: flex;
		width: 100%;
		padding: 15px 20px;
		background-color: #F7F7F7;
		justify-content: space-between;
		white-space: nowrap;
		/* background-color: #f7f7f7; */
	}

	.jtDiv {
		width: 180px;
		display: inline-block;
	}

	.jtimgBox {
		position: relative;
		height: 13.75rem;
		width: 160px;
	}

	.jtimgBox .jtImg {
		height: 140px;
		width: 130px;
	}

	.viewSize {
		/* text-align: center; */
		font-size: 12px;
	}

	.plays {
		position: absolute;
		object-fit: fill;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.jtJs {
		padding: 10px 0px;
		font-size: 14px;
		color: #333333;
	}

	.shopMj-List {
		margin: 25px 0px;
		display: flex;
		justify-content: space-between;
	}

	.lines-shop {
		width: 12.6875rem;
		height: 271px;
		background: #F6F6F6;
		/* margin: 0 1.5rem; */
	}

	.lines-shop:nth-child(1) {
		margin-left: 0;
	}

	.shopImgs {
		width: 100%;
	}

	.shopImgs img {
		height: 194px;
		width: 204px;
	}

	.shopNames {
		padding: 10px;
		font-size: 13px;
		color: #333333;
	}

	.money_shop {
		padding-left: 10px;
		display: flex;
	}

	.yj-shop {
		font-size: 14px;
		color: #E21F3E;
	}

	.zh-shop {
		font-size: 13px;
		margin-left: 10px;
		color: #999999;
		text-decoration: line-through;
	}

	.ZiXunBox {
		margin-bottom: 50px;
		background: #F7F7F7;
	}

	.ZixunDiv {
		display: flex;
		padding: 20px;
	}

	.zxImg {
		height: 5.625rem;
		width: 5.625rem;
	}

	.zxImg img {
		height: 100%;
		width: 100%;
	}

	.rightziXun {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-left: 20px;
	}

	.ZxName {
		display: flex;
		justify-content: space-between;
	}

	.Zname {
		font-size: 14px;
		font-weight: bold;
	}

	.zxDetail {
		font-size: 12px;
		cursor: pointer;
	}

	.jianjieBoxs {
		/* max-width: 42.5rem; */
		width: 42.5rem;
		color: #787878;
	}

	.zxJianjie {
		height: 2.4375rem;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 14px;
	}

	.zxJianjie p {
		margin: 0;
		padding: 0;
	}

	.create_date {
		font-size: 12px;
	}

	.swiper {
		width: 100%;
		height: 100%;
	}

	.swiper-slide {
		/* text-align: center; */
		font-size: 18px;
		background: #fff;

		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	}

	@media (max-width: 760px) {
		.swiper-button-next {
			right: 20px;
			transform: rotate(90deg);
		}

		.swiper-button-prev {
			left: 20px;
			transform: rotate(90deg);
		}
	}

	.swiper-wrapper {
		/* left: 0px; */
		top: 40px;
	}
	.swiper-wrapper-zuopin{
		top:0px;
		left:15px;
		
	}
</style>