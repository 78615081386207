<template>
  <div >
		<div style='display: flex;'>
    <div class="shopDetailsBox">
      <div class="gobacksty">
        <div @click="tztop" class="gobacksty-top">线上商城</div>
        <div class="gobacksty-detail">商品详情</div>
      </div>
      <div class="SpDetail">
        <div class="topShopMsg">
          <div class="shopImgList">
            <!-- <div class="maxImgBox">
              <img :src="bigimg" alt="">
            </div> -->
						<div style='position: relative;'>
							<div class="zpdetop-left" id="imgPre"  @mouseleave="seeEnd">
								<!-- 原图 -->
								<div class="zpdetop-l-bot maxImgBox" ref="imgPre" >
									<img  :src="bigimg"   />
									<!-- 遮罩层 -->
									<div class="topMask2" @mouseenter="seeBegin($event)" @mousemove="move($event)"></div>
									<!--鼠标放大镜模块-->
									<div ref="move" v-show="isShow" class="move" :style="cursorMask">
								</div>
								
								</div>
							</div>
							<!-- 放大区域 -->
							<div v-if="isShow" id="seeDetail2" ref="bigImg">
								<img :src="Bigurl" alt="" :style="imgMove">
							</div>
						</div>
            <div class="img-items">
              <img v-for="(item,index) in workDetail.images" :key="index" :src="item" alt="">
            </div>
          </div>
          <div class="Product-jieshao">
            <div class="product-title">{{workDetail.title}}</div>
            <div class="product-code">编号：{{workDetail.number}}</div>
            <div class="product-zuozhe">作者：{{workDetail.master && workDetail.master.name}}</div>
            <div class="inventory">库存：{{workDetail.stocks}}</div>
            <div class="price">售价：<span>{{workDetail.current_price}}</span></div>
            <div class="give-fuwu">
              <div class="give_phone">卖家服务热线:400-xxx-xxxx</div>
              <div class="give_time">(平台服务时间：周一到周五 9:00-17:00)</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content_boxs">
        <div class="leftBoxMjTj">
          <div class="KaPian">
            <div class="ZuoZhe-com">个人官网></div>
            <div class="imgs-zuozhe">
              <img :src="workDetail.master && workDetail.master.profiles_img" alt="">
            </div>
            <div class="Mz_zuojia">{{workDetail.master && workDetail.master.name}}</div>
            <div class="category">{{workDetail.master && workDetail.master.lable}}</div>
          </div>
          <div class="remen_list">
            <div class="tuijian">热门推荐</div>
            <div v-for="(item,index) in worklist.slice(0,6)" :key="index" @click='shop_list(item)'>
              <div class="item-menu">
                <div class="item-menu-img">
                  <img :src="item.images[0]" alt="">
                </div>
                <div class="item-menu-cp">
                  <div class="menu-name">{{item.title}}</div>
                  <div class="menu-zuozhe">作者:{{item.master && item.master.name}}</div>
                  <div class="menu-jiage">价格:<span>{{item.current_price}}</span></div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="right-menu-box">
          <div class="tab-menu-box">
            <el-radio-group v-model="comm_mall_name" text-color="#fff" fill="#333333" size="medium" :border="false"
              @input="ChangeTitle">
              <el-radio-button label="商品详情"></el-radio-button>
              <el-radio-button label="其他作品"></el-radio-button>
            </el-radio-group>
          </div>
          <div v-if="comm_mall_name == '商品详情'">
            <div class="item-menu-one">
              <div class="item-module">
                <div>商品名称: {{workDetail.title}}</div>
                <div>尺寸:{{workDetail.size}}</div>
                <div>创作年代:{{workDetail.creation_time}}</div>
              </div>
              <div class="item-module">
                <div>作品分类:{{workDetail.works_cate && workDetail.works_cate.name}}</div>
                <div>材质:{{workDetail.material}}</div>
              </div>
              <div class="item-module">
                <div>题材:{{workDetail.theme}}</div>
                <div>风格:{{workDetail.style}}</div>
              </div>
            </div>
            <div class="item-menu-two">
              <div class="item-menu-twoImg">
                <img :src="workDetail.master && workDetail.master.archives_img" alt="">
              </div>
              <div class="item-menu-jianjie" v-html="workDetail.master && workDetail.master.profiles"></div>
            </div>
            <div class="item-menu-three">
              <div class="max-imgs" v-for="(item,index) in workDetail.images" :key="index">
                <img :src="item" alt="">
              </div>
            </div>
          </div>
          <div v-if="comm_mall_name == '其他作品'" class="shangpin-item">
            <div class="Menu-shopList">
              <div class="item-line-shop" v-for="(item,index) in workDetail.master.other_work" @click='shop_list(item)' :key="index">
                <div class="item-shopImg">
                  <img :src="item.images[0]">
                </div>
                <div class="item-shopName">{{item.title}}</div>
                <div class="item-money">
                  <div class="item-yj">￥ {{item.current_price}}</div>
                  <div class="item-zh" v-if='item.original_price'>￥ {{item.original_price}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
		</div>
    <Footnote></Footnote>
  </div>
</template>
<script>
  import Footnote from '@/components/Footnote.vue'

  export default {
    components: {
      Footnote
    },
    data() {
      return {
        comm_mall_name: '商品详情',
        bigimg:"",
        id: '',
        workDetail: {},
        worklist:[],
				url: '',
				Bigurl: '', //放大镜区域的图片资源
				//上面两个是初始值
				urlIndex: -1, //urlIndex用于在图片列表中
				isShow: 0,
				cursorMask: {
					transform: ''
				}, //使用CSS3控制原图上鼠标预览区域的移动
				imgMove: {
					transform: ''
				}, 
      }
    },
    created() {
      this.id = this.$route.params.id
      this.getworkDetail(this.id);
      this.getlist();//获取线上商城作品列表
    },
    methods: {
      // 获取作品详情
      getworkDetail(id) {
        this.$http.get(this.constant.loadurl.get_shop_info, {
          params: {
            shop_id: id
          }
        }).then(res => {
          if (res.data.code == 0) {
            this.workDetail=res.data.data
            this.bigimg=this.workDetail.images[0]
          } else {
            this.$message({
              message: res.data.msg || res.data.message,
              type: "warning"
            })
          }
        });
      },
      // 获取线上商城作品列表
      getlist(){
        this.$http.get(this.constant.loadurl.get_shop_list).then(res => {
          if (res.data.code == 0) {
            this.worklist=res.data.data
        	 } else {
            this.$message({
              message: res.data.msg || res.data.message,
              type: "warning"
            })
          }
        });
      },
			shop_list(r){
				this.workDetail = []
				this.getworkDetail(r.id)
			},
      ChangeTitle(name) {
        this.comm_mall_name = name
      },
			move(e) {
					var x = e.offsetX -75;
					var y = e.offsetY - 75;
					if (x < 0) {
						x = 0;
					} else if (x > 430) {
						x = 430;
					}
				
					if (y < 0) {
						y = 0;
					} else if (y > 430) {
						y = 430;
					}
					this.cursorMask.transform = `translate(${x}px, ${y}px)`;
					this.imgMove.transform = `translate(-${2*x}px, -${2*y}px)`; //向反方向成比例移动
				},
			seeBegin(e) {
				this.Bigurl = this.bigimg ;
				this.isShow = 1;
				console.log('鼠标移入')
			},
			seeEnd() {
				console.log('鼠标移除')
				//鼠标移出原图区域时，清空相关信息
				this.Bigurl = " ";
				this.left = 0;
				this.top = 0;
				this.isShow = 0;
			},
      // 返回上一页
      tztop() {
        this.$router.push({
          name: 'shoppingmall'
        })
      },
    }
  }
</script>
<style>
  .shopDetailsBox {
		max-width: 1200px; 
		margin: 0 auto;
		/* width:100%; */
		/* margin:0 auto; */
    /* padding: 0 22rem; */
  }
	.leftBoxMjTj {  margin-right: 30px; }
	.right-menu-box { 
	 max-width: calc(100% - 19.1875rem - 30px); }
  .gobacksty {
    max-width: 75rem;
    /* margin: 30px auto; */
    margin: 30px auto;
    height: 21px;
    line-height: 21px;
    display: flex;
    font-size: 14px;
    cursor: pointer;
  }

  .gobacksty-top {
    background-color: #F6F6F6;
    color: #000;
    padding: 0px 20px;
  }

  .gobacksty-detail {
    background: url("https://mingjiahome.zhyell.com/flag.png") no-repeat;
    background-size: 100% 100%;
    width: 114px;
    height: 21px;
    text-align: center;
    color: #fff;
  }

  .SpDetail {}

  .topShopMsg {
    display: flex;
  }

  .shopImgList .maxImgBox img {
    height: 580px;
    width: 580px;
  }

  .shopImgList .img-items img {
    height: 6.5rem;
    width: 104px;
  }

  .img-items :nth-child(1) {
    margin-left: 0;
  }

  .img-items img {
    margin: 0px 10px;
  }

  .img-items {
    padding: 20px 0px;
  }

  .Product-jieshao {
    margin-left: 30px;
  }

  .product-title {
    font-size: 24px;
    font-weight: bold;
  }

  .product-code,
  .product-zuozhe,
  .product-zuozhe,
  .inventory,
  .price {
    margin: 15px 0px;
  }

  .price span {
    font-size: 20px;
    color: red;
  }

  .give-fuwu {
    width: 560px;
    background-color: #F8F8F8;
    border-radius: 10px;
    padding: 25px 0px 25px 40px;
  }

  .give_phone {
    font-size: 20px;
    padding-bottom: 15px;
  }

  .give_time {
    color: #999999;
  }

  .content_boxs {
    justify-content: space-between;
    display: flex;
    width: 100%;
  }

  .KaPian {
    width: 19.1875rem;
    text-align: center;
    padding: 30px 0px;
    box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.15);
  }

  .ZuoZhe-com {
    width: 81px;
    height: 28px;
    background-color: #e78484;
    color: #BA3635;
    /* color: #BA3635; */
    margin-left: 13rem;
    line-height: 28px;
    font-size: 15px;
  }

  .imgs-zuozhe {

    /* img{
        border-radius: 50%;
    } */
  }

  .imgs-zuozhe>img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
  }

  .Mz_zuojia {
    margin: 20px 0px 10px 0px;
    font-size: 18px;
    font-weight: bold;
  }

  .category {
    margin: 20px;
    font-size: 14px;
  }

  .remen_list {
    padding: 20px 0px;
    margin-top: 30px;
    width: 310px;
    box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.15);
  }

  .tuijian {
    font-size: 18px;
    font-weight: bold;
    padding-left: 15px;
    margin-left: 20px;
    border-left: 3px solid #ccc;
  }

  .item-menu {
    margin: 20px 0px 0px 20px;
    display: flex;
  }

  .item-menu-img img{
    width: 75px;
    height: 75px;
  }

  .item-menu-cp {
    margin-left: 15px;
  }

  .menu-name {
    font-size: 18px;
    font-weight: bold;
  }

  .menu-zuozhe {
    padding-top: 6px;
    font-size: 14px;
    color: #666666;
  }

  .menu-jiage {
    padding-top: 6px;
    font-size: 14px;
    color: #666666;
  }

  .menu-jiage span {
    color: red;
  }

  .right-menu-box {
    width: 53.7rem;
  }

  .tab-menu-box {
		width: 100%;
    border-bottom: 2px solid #000;
  }

  .item-menu-one {
    width: 93%;
    display: flex;
    margin: 20px 0px;
    padding: 20px 30px;
    background: #F7F7F7;
  }

  .item-module {
    font-size: 18px;
  }

  .item-module :nth-child(2) {
    margin: 20px 0px;
  }

  .item-module>div {
    margin-right: 30px;
  }

  .item-menu-two {
    width: 93%;
    display: flex;
    padding: 1.875rem;
    background: #F4F4F4;
  }

  .item-menu-twoImg img {
    width: 13.375rem;
    height: 19.8125rem;
  }

  .item-menu-jianjie {
    margin-left: 2.5rem;
    width: 32.25rem;
    text-align: justify;
    letter-spacing: 2px;
  }

  .item-menu-three {
    margin-top: 30px;
  }

  .max-imgs img {
		max-width: 100%;
    /* width: 53.7rem; */
    height: 580px;
		object-fit: contain;
  }

  .Menu-shopList {
    margin: 25px 0px;
    display: flex;
    /* justify-content: space-between; */
  }

  .item-line-shop {
    width: 203px;
    margin: 0px 5px;
    padding: 20px 0px;
    background: #F6F6F6;
  }

  .item-shopImg {
    width: 100%;
    text-align: center;
  }

  .item-shopImg img {
    height: 151px;
    width: 151px;
  }

  .item-shopName {
    padding: 20px;
    font-size: 12px;
    color: #333333;
  }

  .item-money {
    font-size: 12px;
    padding-left: 20px;
    display: flex;
  }

  .item-yj {
    color: #E21F3E;
  }

  .item-zh {
    margin-left: 20px;
    color: #999999;
    text-decoration: line-through;
  }
	#seeDetail2 {
		/*注意定位，父相子绝*/
		position: absolute;
		background-color: aliceblue;
		width: 580px;
		height: 580px;
		top: 0%;
		left: 102%;
		overflow: hidden;
		z-index: 111;
		/*隐藏图片溢出查看框之外的部分*/
		/* border: 2px solid #f90; */
		background-position: 0 0;
		background-repeat: no-repeat;
	}
	
	#seeDetail2 img {
		position: absolute;
		/*这里注意下，初始时候将查看框内待放大图的左上角定位与查看框的左顶点处，也即其定位原点处*/
		top: 0;
		left: 0;
		background-repeat: no-repeat;
		/* object-fit: contain; */
		/*我自己的图片设置的宽高是450×450，所以这里放大部分的图片的宽高设置为原图的四倍*/
		/*这个倍数需要注意，后面进行坐标变换的时候要用上*/
		width:1560px;
		height: 1560px;
		object-fit: contain;
	}
	
	/*鼠标查看区域 —— 也即需要放大局部放大查看的区域*/
	.move {
		position: absolute;
	
		/*初始定位*/
		left: 0;
		top: 0;
	
		width: 150px;
		height: 150px;
		/* cursor: move; */
		/* background: rgba(142, 223, 255, 0.407); */
		/* background: rgba(142, 223, 255, 0.407); */
	}
	
	.topMask2 {
	
		/*覆盖在放大图的原图表面上的一个遮罩层*/
		width: 580px;
		height:580px;
		position: absolute;
		top: 0px;
		left:0px;
		z-index: 5;
	}
</style>
