<template>
  <div class="contenter">
    <el-carousel height="500px" :autoplay="false" style="margin-top: 30px;">
      <el-carousel-item v-for="(item,index ) in cover" :key="index">
        <img class="appcarous-img"
          :src="item.image"  @click='qzDetail(item.id)'/>
      </el-carousel-item>
    </el-carousel>
    <div class="liststy">
      <div class="liststy-top-left">
        <img class="liststy-top-left-img"
          src="https://mingjiahome.zhyell.com/cloudhall/lunbohover.png" />
        <div class="liststy-top-left-tit">专题活动</div>
      </div>
      <div class="liststy-bot">
        <div class="liststy-bot-sty" v-for="(item,index) in ztLists" :key="index" @click="qzDetail(item.id)">
          <img class="liststy-bot-xh-img"
            :src="item.image"
            alt="">
          <div class="liststy-bot-xh-tit">{{item.title}}</div>
          <!-- <div class="liststy-bot-xh-name" v-html="item.content"></div> -->
        </div>
      </div>
      <el-pagination background layout="total, prev, pager, next" :total="total" :page-size="pageSize"
        @current-change="currentChange" @size-change="handleSizeChange" :current-page="currentPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
			cover:[],
			total:0,
			ztLists:{},
			pageSize: 8,
			currentPage: 1,
      }
    },
		created() {
			let _this = this;
			_this.getList()
		},
    methods: {
			getList(){
				let _this = this;
				this.$http.get(this.constant.loadurl.get_activity,{
					params: {
					 	page: this.currentPage,
					 	limit: this.pageSize
					}
				}
				
				).then(res => {
				    if (res.data.code == 0) {
						_this.cover = res.data.data.cover
						_this.ztLists = res.data.data.activity.data
						_this.total =res.data.data.activity.total
				    } else {
				    }
				});
			},
      //点击作品进入详情页
      qzDetail(id) {
		  let _this = this
		  _this.$router.push({name: 'ztDetail',params: {detailId:id}});
      },
      currentChange(e) {
        this.currentPage = e;
		this.getList()
      },
      handleSizeChange(val) {
        this.pageSize = val;
		this.getList()
      },
    }
  }
</script>

<style scoped>
	.contenter{
		display: block !important;
	}
  /* 分页 */
  .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #333333 !important;
    color: #fff !important;
  }
  .contenter{
    max-width: 1200px;
    margin: 0 auto;
  }
  .appcarous-img {
    height: 500px;
	width: 100%;
	display: block;
  }

  .liststy {
    margin: 50px auto;
    max-width: 1200px;
    text-align: center;
  }

  .liststy-top-left {
    display: flex;
    /* justify-content: space-between; */
    border-bottom: 2px solid #E6E6E6;
    padding-bottom: 20px;
  }

  .liststy-top-left-img {
    width: 30px;
    height: 30px;

  }

  .liststy-top-left-tit {
    color: #333;
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    margin-left: 10px;
  }

  .liststy-bot {
    margin-top: 30px;
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    text-decoration: none;
	height: 564px;
  }

  .liststy-bot-sty {
    /* background-color: #F6F6F6; */
    /* border-radius: 10px; */
    width: 286px;
    color: #333;
    margin-bottom: 20px;
    margin-left: 10px;
	cursor: pointer;
  }

  .liststy-bot-xh-img {
    width: 100%;
    height: 200px;
  }

  .liststy-bot-xh-tit {
    margin: 10px;
    font-size: 14px;
    text-align: left;
    color: #333;
	overflow:hidden; 
	text-overflow:ellipsis;
	display:-webkit-box; 
	-webkit-box-orient:vertical;
	-webkit-line-clamp:2; 
	/* width: 100%;
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis; */
  }

  .liststy-bot-xh-name {
    color: #999;
    font-size: 12px;
    text-align: left;
    margin: 10px;
    width: 90%;
    overflow: hidden;
    /*超出隐藏*/
    /* white-space: nowrap; */
    /*强制不换行*/
    text-overflow: ellipsis;
    /*隐藏后超出部分以省略号显示*/
    height: 30px;
  }

</style>
