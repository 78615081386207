<template>
  <div style="padding-top: 20px;">
    <!-- 全部分类 -->
    <div class="typesty">
      <!-- 分类 -->
      <div class="typesty-left">
        <div class="typesty-left-tit">全部分类</div>
        <div class="typesty-left-xh" v-for="(item,index) in typelist" :key="index">
          <div class="typesty-left-xh-tit">{{item.name}}</div>
          <div class="typesty-left-xh-child">
            <div v-for="(itemj,indexj) in item.pid_list" :key="indexj" class="wraps"  @click='typeZp(itemj,indexj)'>{{itemj.name}}</div>
          </div>
        </div>
      </div>
      <!-- 轮播 -->
      <div class="typesty-rig">
        <el-carousel class="shoplb" height='500px'>
          <el-carousel-item class="shoplb-item" v-for="(item,index) in lunbolist" :key="index">
            <img :src="item" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 精品推荐 -->
    <div class="jpliststy">
      <div class="jpliststy-top">
        <div class="jpliststy-top-left">
          <img class="jpliststy-top-left-img" src="https://mingjiahome.zhyell.com/cloudhall/lunbohover.png"/>
          <div class="jpliststy-top-left-tit">精品推荐</div>
        </div>
        <div @click="tzmore(' ')" class="jpliststy-top-rig">更多>></div>
      </div>
      <div class="jpliststy-bot">
        <div class="jpliststy-bot-sty" v-for="(item,index) in worklist.slice(0,5)" :key="index" @click="qzDetail(item)">
          <img class="jpliststy-bot-xh-img"
            :src="item.images[0]"
            alt="">
          <div class="jpliststy-bot-xh-tit">{{item.title}}</div>
          <div class="jpliststy-bot-xh-name">
            <div class="bot-xh-name-pri">￥{{item.current_price}}</div>
            <div class="bot-xh-name-scx" v-if='item.original_price'>￥{{item.original_price}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 1F -->
    <div class="typesty " id='scroll_one' style="border-top: 1px solid #F6F6F6;">
      <div class="typesty-left">
        <div class="typesty-left-tit">1F  {{firstname}}</div>
        <img class="typesty-left-img" :src="firstimg"/>
        <div class="typesty-left-bai">
          <div class="typesty-left-bai-tit">{{fisrttj.title}}</div>
          <div class="typesty-left-bai-tit">作者：{{fisrttj.master && fisrttj.master.name}}</div>
          <div class="jpliststy-bot-xh-name">
            <div class="bot-xh-name-pri">￥ {{fisrttj.current_price}}</div>
            <div class="bot-xh-name-scx" v-if='fisrttj.original_price'>￥{{fisrttj.original_price}}</div>
          </div>
        </div>
      </div>
      <div class="tjtypesty">
        <div style="display: flex;justify-content: space-between;">
          <div class="tjtypesty-left">
            <div @click="djtype(item,index)" :class="tabindex==index ? 'tjtypesty-left-tit' : 'tjtypesty-left-titwei'" v-for="(item,index) in tjtype1" :key="index">
              {{item.name}}
              <div class="shux" v-show="index<tjtype1.length-1"></div>
            </div>
          </div>
          <div @click="tzmore(typelist[0].id)" class="tjtypesty-rig">更多>></div>
        </div>
        <div class="jpliststy-bot">
          <div class="tjtypesty-bot-sty" v-for="(item,index) in firstList" :key="index" @click="qzDetail(item)">
            <img class="tjtypesty-bot-xh-img"
              :src="item.images[0]"
              alt="">
            <div class="jpliststy-bot-xh-tit">{{item.title}}</div>
            <div class="jpliststy-bot-xh-name">
              <div class="bot-xh-name-pri">￥ {{item.current_price}}</div>
              <div class="bot-xh-name-scx" v-if='item.original_price'>￥{{item.original_price}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2F油画与当代艺术 -->
    <div class="typesty" style="border-top: 1px solid #F6F6F6;margin: 50px auto;">
      <div class="typesty-left">
        <div class="typesty-left-tit">2F  {{secondname}}</div>
        <img class="typesty-left-img" :src="secondimg"/>
        <div class="typesty-left-bai">
          <div class="typesty-left-bai-tit">{{secondtj.title}}</div>
          <div class="typesty-left-bai-tit">作者：{{secondtj.master && secondtj.master.name}}</div>
          <div class="jpliststy-bot-xh-name">
            <div class="bot-xh-name-pri">￥ {{secondtj.current_price}}</div>
            <div class="bot-xh-name-scx" >￥{{secondtj.original_price}}</div>
          </div>
        </div>
      </div>
      <div class="tjtypesty">
        <div style="display: flex;justify-content: space-between;">
          <div class="tjtypesty-left">
            <div @click="djtype2(item,index)" :class="tabindexsec==index ? 'tjtypesty-left-tit' : 'tjtypesty-left-titwei'" v-for="(item,index) in tjtype2" :key="index">
              {{item.name}}
              <div class="shux" v-show="index<tjtype2.length-1"></div>
            </div>
          </div>
          <div @click="tzmore(typelist[1].id)" class="tjtypesty-rig">更多>></div>
        </div>
        <div class="jpliststy-bot">
          <div class="tjtypesty-bot-sty" v-for="(item,index) in secondList" :key="index" @click="qzDetail(item)">
            <img class="tjtypesty-bot-xh-img"
              :src="item.images[0]"
              alt="">
            <div class="jpliststy-bot-xh-tit">{{item.title}}</div>
            <div class="jpliststy-bot-xh-name">
              <div class="bot-xh-name-pri">￥ {{item.current_price}}</div>
              <div class="bot-xh-name-scx" v-if='item.original_price'>￥{{item.original_price}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tabindex:0,//1F
        tabindexsec:0,//2F
        tjtype1:[],//1F
        tjtype2:[],//2F
        firstname:"",
        firstimg:'',
        fisrttj:{},
        secondname:"",
        secondimg:"",
        secondtj:{},
        typelist: [],//分类
        worklist:[],//精品推荐
        lunbolist:[],//轮播
        firstList:[],//1F
        secondList:[],//2F
      }
    },
    created() {
      this.gettypelist();//获取线上商城分类列表
      this.getlist();//获取线上商城作品列表
      this.getlunbolist();//获取商城/首页轮播图列表

    },
    methods: {
      // 获取线上商城分类列表
      gettypelist(){
        this.$http.get(this.constant.loadurl.get_shop_cate_list).then(res => {
          if (res.data.code == 0) {
            this.typelist=res.data.data
            this.firstname=this.typelist[0].name;
            // 1F切换数据拼接
            this.tjtype1=[
              {
                id:this.typelist[0].id,
                name:"精品推荐"
              }
            ]
            this.typelist[0].pid_list.forEach((item,index)=>{
              this.tjtype1.push(item)
            })
            // this.typelist[0].
            this.secondname=this.typelist[1].name;
            //2F 切换数据拼接
            this.tjtype2=[
              {
                id:this.typelist[1].id,
                name:"精品推荐"
              }
            ]
            this.typelist[1].pid_list.forEach((item,index)=>{
              this.tjtype2.push(item)
            })
            this.getfirstlist(this.typelist[0].id);//1F
            this.getsecondlist(this.typelist[1].id);//2F
        	 } else {
            this.$message({
              message: res.data.msg || res.data.message,
              type: "warning"
            })
          }
        });
      },
      // 获取线上商城作品列表
      getlist(){
        this.$http.get(this.constant.loadurl.get_shop_list).then(res => {
          if (res.data.code == 0) {
            this.worklist=res.data.data
        	 } else {
            this.$message({
              message: res.data.msg || res.data.message,
              type: "warning"
            })
          }
        });
      },
      // 获取1F
      getfirstlist(id){
        this.$http.get(this.constant.loadurl.get_shop_list, {params:{
            cate_id:id
          }}).then(res => {
          if (res.data.code == 0) {
            this.firstList=res.data.data
            if(this.firstList.length!=0){
              this.fisrttj=this.firstList[0];
              this.firstimg=this.firstList[0].images[0]
            }
        	 } else {
            this.$message({
              message: res.data.msg || res.data.message,
              type: "warning"
            })
          }
        });
      },
      // 获取2F
      getsecondlist(id){
        this.$http.get(this.constant.loadurl.get_shop_list, {params:{
            cate_id:id
          }}).then(res => {
          if (res.data.code == 0) {
            this.secondList=res.data.data
            if(this.secondList.length!=0){
              this.secondtj=this.secondList[0];
              this.secondimg=this.secondList[0].images[0]
            }

        	 } else {
            this.$message({
              message: res.data.msg || res.data.message,
              type: "warning"
            })
          }
        });
      },
      // 获取商城/首页轮播图列表
      getlunbolist(){
        this.$http.get(this.constant.loadurl.get_banner_images, {params:{
					
						type:0,
						is_mobile:1
					
          }}).then(res => {
          if (res.data.code == 0) {
						
            this.lunbolist=res.data.data[0].url.split(",")
        	 } else {
            this.$message({
              message: res.data.msg || res.data.message,
              type: "warning"
            })
          }
        });
      },
      // 1F 切换
      djtype(item,index){
				console.log(item,'item')
        this.tabindex=index;
        this.getfirstlist(item.id)
      },
      // 2F切换
      djtype2(item,index){
        this.tabindexsec=index;
        this.getsecondlist(item.id)
      },
			typeZp(item,index){
				this.$router.push({
					name:'shopList',
					params:{
						id:item.id
					}
				})
				 // const scrollTop = document.getElementById("scroll_one");
				// scrollTop.scrollIntoView();
				// this.djtype(item,index)
				
			},
      qzDetail(item){
        this.$router.push({
          name:'MallDetail',
          params:{
            id:item.id
          }
        })
      },
      // 点击更多跳转新页面
      tzmore(id){
        this.$router.push({
          name:'shopList',
          params:{
            id:id
          }
        })
      }
    }
  }
</script>

<style>
  /* 全部分类 */
  .typesty {
    /* padding-top: 20px; */
    max-width: 1200px;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
  }

  .typesty-left {
    background-color: #F6F6F6;
    width: 310px;

  }

  .typesty-left-tit {
    background-color: #000000;
    color: #fff;
    text-align: left;
    font-size: 18px;
    line-height: 53px;
    padding-left: 20px;
  }

  .typesty-left-xh {
    color: #333;
    font-size: 16px;
    margin: 0px 0 20px 0;
    padding: 15px 0px;
    display: flex;
    border-bottom: 1px solid #C8C8C8;
  }

  .typesty-left-xh-tit {
    font-weight: bold;
    width: 100px;
    text-align: left;
    cursor: pointer;
		margin-left: 20px	;
  }

  .typesty-left-xh-child {
    display: flex;

  }

  .typesty-left-xh-child .wraps {
		font-family: 'Courier New', Courier, monospace;
    margin-right: 15px;
    text-align: left;
    cursor: pointer;
		/* width: 2rem; */
  }
	 
  /* 轮播 */
  .typesty-rig {}

  .shoplb {
    width: 860px;
    height: 500px;
  }

  .typesty-rig .el-carousel__button {
    width: 12px !important;
    height: 12px !important;
    background-color: #999;
    position: relative;
    border-radius: 6px;
    left: -370px;
    bottom: 5px;
    opacity: 1;
  }

  .typesty-rig .el-carousel__indicator.is-active button {
    width: 12px !important;
    height: 12px !important;
    background-color: #fff;
    border-radius: 6px;
    position: relative;
    left: -370px;
    bottom: 5px;
    opacity: 1;
  }

  .typesty-rig .el-carousel__arrow {
    display: none;
  }

  /* 精品推荐 */
  .jpliststy {
    margin: 50px auto;
    max-width: 1200px;
  }

  .jpliststy-top {
    display: flex;
    justify-content: space-between;
    /* border-bottom: 2px solid #E6E6E6; */
    padding-bottom: 10px;
  }

  .jpliststy-top-left {
    display: flex;
  }
  .jpliststy-top-left-img {
    width: 30px;
    height: 30px;
  }

  .jpliststy-top-left-tit {
    color: #333;
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    margin-left: 10px;
  }

  .jpliststy-top-rig {
    color: #666;
    font-size: 16px;
    line-height: 40px;
  }

  .jpliststy-bot {
    /* margin-top: 10px; */
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    text-decoration: none;
  }

  .jpliststy-bot-sty {
    background-color: #F6F6F6;
    /* border-radius: 10px; */
    width: 226px;
    color: #333;
    margin-bottom: 20px;
    margin-left: 14px;
  }

  .jpliststy-bot-xh-img {
    width: 100%;
    height: 214px;
		object-fit: contain;
  }

  .jpliststy-bot-xh-tit {
    margin: 10px;
    font-size: 14px;
    text-align: left;
    color: #333;
  }
  .jpliststy-bot-xh-name{
    display: flex;
    margin: 0px 0px 10px 10px;
  }
  .bot-xh-name-pri{
    color: #E21F3E;
    font-size: 14px;
    font-weight: bold;
    margin-right: 20px;
  }
  .bot-xh-name-scx{
    font-size: 12px;
     text-decoration: line-through;
     color: #999;
     margin-top: 2px;
  }
  /* 1F  2F等样式 */
  .typesty-left-img{
    width: 266px;
    height: 390px;
		object-fit: contain;
    margin: 10px 20px 0px 20px;
  }
  .typesty-left-bai{
    background-color: #fff;
    margin: 0px 20px;
    padding: 10px 0px;
    text-align: left;
  }
  .typesty-left-bai-tit{
    color: #333;
    font-size: 14px;
    margin: 10px;
  }
  .tjtypesty{
    width: 870px;

  }
  .tjtypesty-left{
    display: flex;
    margin-left: 20px;
  }
  .tjtypesty-left-tit{
    margin-top: 20px;
    color: #A53124;
    font-size: 18px;
    /* margin: 0px 10px; */
    display: flex;
    cursor: pointer;
    font-weight: bold;
  }
  .tjtypesty-left-titwei{
    margin-top: 20px;
    color: #333;
    font-size: 18px;
    /* margin: 0px 10px; */
    display: flex;
    cursor: pointer;

  }
  .shux{
    margin: 0px 20px 0px 20px;
    width: 1px;
    height: 25px;
    background-color: #CBCBCB;
  }
  .tjtypesty-rig{
    color: #666;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
  }
  .tjtypesty-bot-sty{
    background-color: #F6F6F6;
    /* border-radius: 10px; */
    width: 205.2px;
    color: #333;
    margin-top: 20px;
    margin-left: 10px;
  }
  .tjtypesty-bot-xh-img{
    width: 100%;
    height: 194.3px;
		object-fit: contain;
  }
</style>
