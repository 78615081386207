<template>
	<div class="content">
		<!-- 顶部 -->
		<div class="huodongsty">
			<!-- 轮播 -->
			<div class="huodongsty-left">
				<el-carousel class="lunboSty" height='487px'>
					<el-carousel-item class="lunboSty-item" v-for="(item,index) in coverList" :key="index" >
						<img :src="item.res_image" class="lunboSty-img" @click="zxDetail(item)"/>
						<div class="lunboSty-item-tit" @click="zxDetail(item)">{{item.title}}</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<!-- 公告活动 -->
			<div class="huodongsty-rig">
				<div class="huodongsty-rig-tit">活动公告</div>
				<ul class="huodongsty-rig-list">
					<li v-for="(item,index) in activeList" :key="index" @click="zxDetail(item)">
						<div class="huodongsty-rig-list-div">
							<span style="font-weight: bold;">{{item.type}}丨</span>
							{{item.title}}
						</div>

					</li>
				</ul>
			</div>
		</div>
		<!-- 热门资讯 -->
		<div class="rmzxsty">
			<div class="liststy-top-left">
				<img class="liststy-top-left-img" src="https://mingjiahome.zhyell.com/cloudhall/lunbohover.png" />
				<div class="liststy-top-left-tit">热门咨讯</div>
			</div>
			<div class="rmzxsty-list">
				<!-- 循环 -->
				<div v-for="(item,index) in rmzxList" :key="index" class="rmzxsty-list-xh">
					<div class="rmzxsty-list-xh-left">
						<img :src="item.res_image" />
					</div>
					<div class="rmzxsty-list-xh-rig">
						<!-- 第一行 -->
						<div class="rmzxsty-list-xh-one">
							<div style="display: flex;" @click="zxDetail(item)">
								<!-- <div class="xh-one-left">{{item.type}}</div> -->
								<div class="xh-one-tit">{{item.title}}| {{item.source}}</div>
							</div>
							<div style="display: flex;">
								<div @click="copy(item.id)" class="xh-one-rig">分享<i
										class="el-icon-share el-icon--right"></i></div>
								<div class="xh-one-rig" @click="zxDetail(item)">查看详情></div>
							</div>
						</div>
						<!-- 第二行 -->
						<div class="rmzxsty-list-xh-two" @click="zxDetail(item)">
							<div>{{item.write_time.split(' ')[0]}}</div>
							<div>来源：{{item.source}}</div>
							<!-- <div>阅读：332</div> -->
							<!-- <div>编辑：付园园</div> -->
						</div>
						<!-- 第三行 -->
						<div @click="zxDetail(item)" class="rmzxsty-list-xh-thr" v-html="item.content.replace(/<[^<>]+>/g, '').replace(/&nbsp;/gi, '')"></div>
					</div>
				</div>
			</div>
			<el-pagination background layout="total, prev, pager, next" :total="tatol" :page-size="pageSize"
				@current-change="currentChange" @size-change="handleSizeChange" :current-page="currentPage">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				pageSize: 10,
				currentPage: 1,
				rmzxList: [], //热门资讯列表
				activeList: [], //活动公告列表
				coverList: [], //轮播列表
				tatol: 0
			}
		},
		created() {
			this.getlist()
		},
		methods: {
			getlist() {
				this.$http.get(this.constant.loadurl.get_article, {
					params: {
						page: this.currentPage,
						limit: this.pageSize
					}
				}).then(res => {
					if (res.data.code == 0) {
						this.rmzxList = res.data.data.activity.data
						this.tatol = res.data.data.activity.total
						this.activeList = res.data.data.activity_notice
						this.coverList = res.data.data.cover
					} else {
						this.$message({
							message: res.data.msg || res.data.message,
							type: "warning"
						})
					}
				});
			},
			// 复制当前网页链接
			copy(id) {
				console.log(id)
				var domUrl = document.createElement("input");

				// domUrl.value = window.location.href;
				domUrl.value = window.location.href.split('#')[0] + '#/inforDetail/' + id

				console.log(domUrl.value)
				domUrl.id = "creatDom";

				document.body.appendChild(domUrl);

				domUrl.select(); // 选择对象

				document.execCommand("Copy"); // 执行浏览器复制命令

				let creatDom = document.getElementById("creatDom");

				creatDom.parentNode.removeChild(creatDom);

				this.$message({
				  message: "链接已复制",
				  type: "success"
				})
			},
			currentChange(e) {
				this.currentPage = e;
				this.getlist()
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.getlist()
			},
			// 点击跳转资讯详情
			zxDetail(item) {
				this.$router.push({
					name: 'inforDetail',
					params: {
						id: item.id
					}
				})
			}
		}
	}
</script>

<style>
	/* 分页 */
	.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #333333 !important;
		color: #fff !important;
	}

	.content {
		max-width: 1200px;
		margin: 30px auto;
	}

	.huodongsty {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.huodongsty-left {
		width: 870px;
		height: 487px;
	}

	.lunboSty {
		margin: 0 auto;
		width: 100%;
		cursor: pointer;
	}

	.lunboSty-item {
		width: 100%;
	}

	.lunboSty-img {
		width: 100%;
	}

	.lunboSty-item-tit {
		background: rgba(0, 0, 0, 0.5);
		color: #fff;
		height: 50px;
		line-height: 50px;
		position: absolute;
		bottom: 0px;
		left: 0px;
		text-align: left;
		font-size: 18px;
		width: 100%;
		padding-left: 20px;
	}

	.huodongsty-left .el-carousel__button {
		width: 16px !important;
		height: 16px !important;
		background-color: #999;
		position: relative;
		right: -360px;
		bottom: 5px;
	}

	.huodongsty-left .el-carousel__indicator.is-active button {
		width: 16px !important;
		height: 16px !important;
		background-color: #fff;
		position: relative;
		right: -360px;
		bottom: 5px;
	}

	.huodongsty-left .el-carousel__arrow {
		display: none;
	}

	.huodongsty-rig {
		width: 312px;
		text-align: left;
		background-color: #F7F7F7;
	}

	.huodongsty-rig-tit {
		background-color: #333333;
		color: #fff;
		font-size: 18px;
		line-height: 53px;
		padding-left: 20px;
	}

	.huodongsty-rig-list {
		font-size: 14px;
		color: #333;
		padding-right: 25px;
	}

	.huodongsty-rig-list li {
		/* margin: 20px 0px; */
		line-height: 25px;
		cursor: pointer;

	}

	.huodongsty-rig-list-div {
		overflow: hidden;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}

	/* 热门资讯 */
	.rmzxsty {
		margin: 30px 0px;
		text-align: center;
	}

	.liststy-top-left {
		display: flex;
		/* justify-content: space-between; */
		/* border-bottom: 2px solid #E6E6E6; */
		padding-bottom: 20px;
	}

	.liststy-top-left-img {
		width: 30px;
		height: 30px;

	}

	.liststy-top-left-tit {
		color: #333;
		font-size: 24px;
		line-height: 30px;
		font-weight: bold;
		margin-left: 10px;
	}

	.rmzxsty-list {}

	.rmzxsty-list-xh {
		/* min-width: 1200px; */
		border-top: 1px solid #E6E6E6;
		padding: 40px 0px;
		display: flex;
		cursor: pointer;
	}

	.rmzxsty-list-xh-left {
		width: 260px;
		height: 170px;
	}

	.rmzxsty-list-xh-left img {
		width: 260px;
		height: 170px;
	}

	.rmzxsty-list-xh-rig {
		margin-left: 30px;
		width: 1000px;

	}

	.rmzxsty-list-xh-one {
		display: flex;
		justify-content: space-between;
		height: 19px;
		line-height: 19px;
	}

	.xh-one-left {
		/* width: 36px; */
		color: #fff;
		background-color: #000;
		font-size: 12px;
		text-align: center;
	}

	.xh-one-tit {
		text-align: left;
		font-weight: bold;
		color: #333;
		font-size: 16px;
		margin-left: 0px;
		cursor: pointer;
		width: 700px;
		overflow: hidden;
		/*超出隐藏*/
		white-space: nowrap;
		/*强制不换行*/
		text-overflow: ellipsis;
		/*隐藏后超出部分以省略号显示*/
	}

	.xh-one-rig {
		color: #333;
		font-size: 14px;
		margin-left: 20px;
		cursor: pointer;
	}

	.rmzxsty-list-xh-two {
		color: #333;
		font-size: 14px;
		line-height: 20px;
		display: flex;
		margin: 15px 0px;
	}

	.rmzxsty-list-xh-two div {
		margin-right: 30px;
	}

	.rmzxsty-list-xh-thr {
		color: #333;
		/* height: 120px; */
		font-size: 14px;
		line-height: 25px;
		text-align: left;
		overflow: hidden;
		-webkit-line-clamp: 3;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		width: 880px;
		margin-top: 30px;
	}
</style>