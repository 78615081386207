<template>
	<div>
		<div class="worksBox">
			<div class="leftSortBox">
				<div class="yearDiv">
					<div class="NianFen">
						<span class="icon_Column">|</span>
						<span class="yearFen">年份</span>
					</div>
					<div class="radioDiv">

						<el-radio-group v-model="years" text-color="#fff" fill="#A53124" size="mini" :border="false"
							@input="changeYear">
							<el-radio-button label="全部"></el-radio-button>
							<el-radio-button :label="item" v-for='(item,index) in WorkArr.creation' :key="index"></el-radio-button>
						</el-radio-group>
					</div>
				</div>
				<div class="sortdiv">
					<!--     <div class="sorts">
                        <span class="icon_Column">|</span>
                        <span class="allSort">全部分类</span>
                    </div>
                    <div class="radioDiv zuopinRadio">
                        <el-radio-group v-model="works" text-color="#fff" fill="#A53124" size="medium " :border="false" @input="changeWorks(item)"
                         >
                            <el-radio-button :label="item.id" v-for="(item,index) in WorkArr.works_cate" :key="index" >
                                {{item.name}}
                            </el-radio-button>
                        </el-radio-group>
                    </div> -->
				</div>
			</div>
			<div class="rightListBox">
				<div class="search_sort">
					<div class="search">
						<div class="mrmj">
							<el-dropdown trigger="click" @command="names">
								<span class="el-dropdown-link">
									<span style="display: inline-block; min-width:3.125rem; color: #000000;">{{ userName
                                    }}</span>
									<i class="el-icon-caret-bottom el-icon--right" style="color:#000000; padding-left:5px;"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="作品名称">作品名称</el-dropdown-item>
									<el-dropdown-item command="其他">其他</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="BtnDivZp">
							<el-input placeholder="请输入内容" v-model="searchStr" class="input-with-select">
								<div slot="append" class="BtnClick" @click="work_search ">搜索</div>
							</el-input>
						</div>
					</div>
					<div class="SortDivEnd">
						<div>排序:</div>
						<el-radio-group v-model="SortStr" text-color="#fff" fill="#A53124" size="mini " :border="false"
							@input="changeSort">
							<el-radio-button label="默认"></el-radio-button>
							<el-radio-button label="最新上传"></el-radio-button>
						</el-radio-group>
					</div>
				</div>
				<div class="liebiaoZp">
					<div class="ListZps" v-for="(item,index) in WorkArr.works.data" :key="index" @click="zp_detail(item)">
						<img :src="item.images[0]" alt="">
						<div class="ZuoPinName">{{ item.title }}</div>
						<div class="ChiCun">{{item.size}}</div>
					</div>
					<div v-show='WorkArr.works.data.length < 1' style='text-align: center;width: 100%;margin:100px 0px 0 200px;'>
						<el-empty description="暂无数据"></el-empty>
					</div>
				</div>
			</div>
		</div>

		<div class="FenYe">
			<el-pagination layout="prev, pager, next,total" prev-text="上一页" :background="true" next-text="下一页"
				:page-size="limit" :page-sizes="pageCount" :current-page="page" @current-change="currentChange" :total="total">
			</el-pagination>
		</div>
		<div class="detailPuP" v-if="swiperShow && detailMsg">
			<div class="pupDivBox">
				<div class="icon_div_clear">
					<i class="el-icon-close" @click="swiperShow =false"></i>
				</div>
				<div>
					<div class="swiper mySwiper">
						<div class="swiper-wrapper">
							<div class="swiper-slide swiper-slide2">
								<div class="SwImg">
									<img :src="detailMsg.images[0]">
								</div>
								<div class="xinxi">
									<div class="titleName">标题:{{ detailMsg.title }}</div>
									<div class="zuozhes">作者:{{detailMsg.master.name}}</div>
									<div class="shijian">时间:{{ detailMsg.creation_time }}</div>
								</div>
								<div class="xinxi">
									<div class="jcxx">基础信息</div>
									<div class="tc">题材:{{detailMsg.theme}}</div>
									<!-- <div class="cz">材质:{{ detailMsg.material }}</div>
                                    <div class="chicun">尺寸:{{ detailMsg.size }}</div> -->
								</div>
							</div>
						</div>
						<!-- <div class="swiper-button-next next_div"></div>
                        <div class="swiper-button-prev prev_div"></div> -->
					</div>
				</div>
			</div>
		</div>
		<Footnote></Footnote>
	</div>
</template>
<script>
	import Swiper from 'swiper/bundle'
	import 'swiper/swiper-bundle.css'
	import Footnote from '@/components/Footnote.vue'
	export default {
		components: {
			Footnote
		},
		data() {
			return {
				pageCount: [2],
				radio1: '上海',
				years: '全部',
				works: '全部',
				searchStr: '',
				userName: '作品名称',
				SortStr: '',
				swiperShow: false,
				id: '',
				WorkArr: [],
				works_cate_id: '',
				titile: '',
				creation_time: '',
				order: '',
				total: '',
				limit: 10,
				page: 1,
				detailMsg: {}
			}
		},
		mounted() {
			var swiper = new Swiper(".mySwiper", {
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
			});
			if (this.$route.query.id) {
				this.id = this.$route.query.id
			}
			this.worksList()
		},
		methods: {
			worksList() {
				this.$http.get(this.constant.loadurl.get_ming_works + '?m_id=' + this.id + '&works_cate_id=' + this
					.works_cate_id + '&title=' + this.searchStr + '&order=' + this.order + '&creation_time=' + this
					.creation_time + '&limit=' + this.limit + '&page=' + this.page
				).then(res => {
					if (res.data.code == 0) {
						this.WorkArr = res.data.data
					
						this.total = res.data.data.works.total
					}
				})
			},
			//年份
			changeYear(y) {
				if (y == '全部') {
					this.creation_time = ''
				} else {
					this.creation_time = y
				}
				this.worksList()
			},
			//作品
			changeWorks(w) {
				this.works_cate_id = w
				this.worksList()
			},
			work_search() {

				this.worksList()
			},
			//
			names(res) {},
			//排序
			changeSort(sort) {
				this.order = sort
				if (sort == '最新上传') {
					this.order = 1
				} else {
					this.order = 0
				}
				this.worksList()
			},

			currentChange(page) {
				this.page = page
				this.worksList()
			},
			zp_detail(res) {
				this.swiperShow = true
				this.detailMsg = res
				// this.$http.get(this.constant.loadurl.get_ming_works_info+'?works_id=' + res.id).then(res=>{
				//     console.log(res.data,'作品详情');
				// })
				// this.$router.push({path:'/workdetail',query:{id:res.id}})
			}
		}
	}
</script>
<style>
	.worksBox {
		display: flex;
		padding: 0 22.9375rem;
		margin-top: 40px;
		/* justify-content: space-between; */
	}

	.leftSortBox {
		width: 19.375rem;
		height: 38.6rem;
		padding: 30px 0px;
		background: #F7F7F7;
	}

	.yearDiv,
	.sortdiv {
		margin: 0px auto;
		width: 16.875rem;
	}

	.sortdiv {
		margin-top: 30px;
		background-color: none !important;
	}

	.NianFen,
	.sorts {
		color: #000;
		padding: 10px 0px;
	}

	.icon_Column {
		color: #060606;
	}

	.yearFen,
	.allSort {
		font-size: 18px;
		padding-left: 20px;
	}

	.yearDiv .radioDiv {
		margin: auto;
		padding: 10px 3px;
		width: 15rem;
		background-color: #ffffff;
	}

	.radioDiv>,
	.el-radio-group {
		display: block;
	}

	.zuopinRadio {
		height: 460px;
		overflow-y: auto;
	}

	.zuopinRadio>.el-radio-group {
		display: flex;
		flex-direction: column;
		text-align: center;
	}

	.zuopinRadio>.el-radio-group label {
		margin: 8px 0px;
		width: 100%;
	}

	.zuopinRadio>.el-radio-group label span {
		width: 90%;
	}

	.search_sort {
		display: flex;
		justify-content: space-between;
	}

	.rightListBox {
		margin-left: 20px;
	}

	.rightListBox .search_logoCity {
		display: flex;
		align-items: center;
	}

	.mrmj {
		padding: 0 10px;
	}

	.BtnDivZp .el-input-group__append {
		background: #000000;
		color: #ffffff;
	}

	.SortDivEnd {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.SortDivEnd>div {
		white-space: nowrap;
	}

	.liebiaoZp {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.ListZps {
		width: 15rem;
		padding: 10px;
		margin: 20px 0px;
		text-align: center;
		background-color: #E9E9E9;
	}

	.ListZps img {
		width: 14.625rem;
		height: 13.625rem;
	}

	.ZuoPinName {
		padding-top: 5px;
		font-size: 12px;
	}

	.ChiCun {
		padding-top: 5px;
		font-size: 12px;
	}

	.detailPuP {
		position: fixed;
		z-index: 999;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background-color: rgba(0, 0, 0, .5);
	}

	.swiper-slide2 {
		display: block !important;
	}

	.pupDivBox {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 50rem;
		height: 50rem;
		background-color: #ffffff;
	}

	.icon_div_clear {
		position: absolute;
		font-size: 30px;
		right: -40px;
		top: -30px;
		color: #ffffff;
	}

	.SwImg {
		padding-top: 20px;
		text-align: center;
	}

	.SwImg img {
		width: 46.875rem;
		height: 30rem;
		object-fit: contain;
	}

	.titleName {
		margin: 20px 0px;
		font-size: 1.125rem;
		font-weight: bold;
		text-align: left;
	}

	.jcxx {
		margin: 20px 0px 10px 0px;
		font-weight: bold;
		font-size: 1.125rem;
	}

	.xinxi {
		padding-left: 25px;
	}

	.xinxi>div {
		font-family: 'Courier New', Courier, monospace;
	}

	.xinxi>div {
		font-size: 16px;
	}

	.zuozhes,
	.cz {
		margin: 5px 0px;
	}
</style>