<template>
  <div class="content">
    <div class="gobacksty">
      <div @click="tztop" class="gobacksty-top">版权作品</div>
      <div class="gobacksty-detail">作品详情</div>
    </div>
    <div class="workDetail">
      <!-- 左边 -->
      <div class="workDetail-left">
        <div class="workDetail-left-imgzt">
          <div class="left">
                <img class="leftImg" :src="img" alt="" />
                <!-- 鼠标层罩 -->
                <div v-show="topShow" class="top" :style="topStyle"></div>
                <!-- 最顶层覆盖了整个原图空间的透明层罩 -->
                <div
                  class="maskTop"
                  @mouseenter="enterHandler"
                  @mousemove="moveHandler"
                  @mouseout="outHandler"
                ></div>
              </div>
              <div v-show="rShow" class="right">
                <img
                  :style="r_img"
                  class="rightImg"
                  :src="img"
                  alt=""
                />
              </div>
          <!-- <img class="workDetail-left-img" :src="img" /> -->
          <div class="workDetail-left-type">书画</div>
          <div class="workDetail-left-big">
            <img class="workDetail-left-big-img"
              src="http://ryfw51f4p.hn-bkt.clouddn.com/jdba/icon-22.png?e=1690859798&token=MRD8xdDk5PZlBXaqUXd2Bsb9UpawP7NevFYBSWW5:88eNtv_SK_7rYC3YvRRElHQAplU=" />
            <div>悬停图片放大</div>
          </div>
        </div>
        <div class="workDetail-left-de">详情请拨打电话400-888-8888或点击 <span style="color: #2789EE;">在线客服</span></div>
      </div>
      <!-- 右边 -->
      <div class="workDetail-rig">
        <div class="workDetail-rig-big">
          <img class="workDetail-rig-big-img"
            src="http://ryfw51f4p.hn-bkt.clouddn.com/jdba/icon-lian.png?e=1690865145&token=MRD8xdDk5PZlBXaqUXd2Bsb9UpawP7NevFYBSWW5:N4oTQc2cYBAcOxQZHtKwkOAUADo=" />
          <div>《甘珠尔》铁板浮雕艺术</div>
        </div>
        <div class="workDetail-rig-titimg">
          <img class="workDetail-rig-img"
            src="http://ryfw51f4p.hn-bkt.clouddn.com/jdba/icon-rz.png?e=1690866135&token=MRD8xdDk5PZlBXaqUXd2Bsb9UpawP7NevFYBSWW5:LoaGJYqEZ37xic307EdvHj1kS1M=" />
          <div>已区块链认证</div>
        </div>
        <div class="workDetail-rig-qkl">
          <div class="workDetail-rig-qkl-left">
            <div>大小：</div>
            <div>分类：</div>
            <div>标签：</div>
            <div>登记时间：</div>
          </div>
          <div class="workDetail-rig-qkl-right">
            <div>120cmx100cm</div>
            <div>书画</div>
            <div>书画</div>
            <div>2023-04-23 0:00:00</div>
          </div>
        </div>
        <div class="workDetail-rig-sqsqbtn">申请授权</div>
        <div class="workDetail-rig-detail">基本信息</div>
        <div class="workDetail-rig-jbxx">
          <div class="workDetail-rig-qkl-left">
            <div>著作权人/作者：</div>
            <div>存证编号：</div>
            <div>尺寸：</div>
            <div>创作时间：</div>
            <div>制作方式：</div>
            <div>权利取得方式：</div>
            <div>权利拥有方式：</div>
            <div>电子数摆指纹：</div>
          </div>
          <div class="workDetail-rig-qkl-right">
            <div>郭海博</div>
            <div>1234879089</div>
            <div>120cmx100cm</div>
            <div>2021年5月6日</div>
            <div>铁板浮雕</div>
            <div>fsdfds</div>
            <div>{}</div>
            <div>rfsdfrsfdsfsdcxvcvcxvxv</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 推荐作品 -->
    <div class="liststy">
      <div class="liststy-top-left">
        <div class="shutiao"></div>
        <div class="liststy-top-left-tit">推荐作品</div>
      </div>
      <div class="liststy-bot">
        <div class="liststy-bot-xh">
          <div class="liststy-bot-sty" v-for="(item,index) in 5" :key="index" @click="qzDetail(item)">
            <img class="liststy-bot-xh-img"
              src="http://ryfw51f4p.hn-bkt.clouddn.com/jdba/ban1.png?e=1690794661&token=MRD8xdDk5PZlBXaqUXd2Bsb9UpawP7NevFYBSWW5:Hho-nlPDPFHlkatAMotjy_rjLsA="
              alt="">
            <div class="liststy-bot-xh-tit"><span style="font-size: 15px;">《南北合》</span><span
                style="font-size:12px;">许荷英饰碧莲公主</span></div>
            <div class="liststy-bot-xh-con">
              <div style="color: #E21F3E;font-size: 15px;margin-right: 10px;">已上链</div>
              <div style="color: #999999;font-size: 12px;line-height: 23px;">编号：767867878</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
         topStyle: { transform: "" },
              r_img: {},
              topShow: false,
              rShow: false,
        img: "http://ryfw51f4p.hn-bkt.clouddn.com/jdba/datu.png?e=1690857592&token=MRD8xdDk5PZlBXaqUXd2Bsb9UpawP7NevFYBSWW5:c8KnfyvKSqQcChia6guSuER67BA=",
      }
    },
    methods: {
      tztop() {
        this.$router.push({
          name: 'copyright'
        })
      },
      // 鼠标进入原图空间函数
          enterHandler() {
            // 层罩及放大空间的显示
            this.topShow = true;
            this.rShow = true;
          },
          // 鼠标移动函数
          moveHandler(event) {
            // 鼠标的坐标位置
            let x = event.offsetX;
            let y = event.offsetY;
            // console.log(x)
            // console.log(y)
            // 层罩的左上角坐标位置，并对其进行限制：无法超出原图区域左上角
            let topX = x - 100 < 0 ? 0 : x - 100;
            let topY = y - 100 < 0 ? 0 : y - 100;
            // 对层罩位置再一次限制，保证层罩只能在原图区域空间内
            if (topX > 570) {
              topX = 570;
            }
            if (topY > 330) {
              topY = 330;
            }
            // 通过 transform 进行移动控制
            this.topStyle.transform = `translate(${topX}px,${topY}px)`;
            this.r_img.transform = `translate(-${2 * topX}px,-${2 * topY}px)`;
          },
          // 鼠标移出函数
          outHandler() {
            // 控制层罩与放大空间的隐藏
            this.topShow = false;
            this.rShow = false;
          },

    }
  }
</script>

<style scoped>
  .content {
    /*  max-width: 1200px;
    margin: 0 auto; */
  }

  .gobacksty {
    max-width: 1200px;
    /* margin: 30px auto; */
    margin: 30px auto;
    height: 21px;
    line-height: 21px;
    display: flex;
    font-size: 14px;
    cursor: pointer;
  }

  .gobacksty-top {
    background-color: #F6F6F6;
    color: #000;
    padding: 0px 20px;
  }

  .gobacksty-detail {
    background: url("https://mingjiahome.zhyell.com/flag.png") no-repeat;
    background-size: 100% 100%;
    width: 114px;
    height: 21px;
    text-align: center;
    color: #fff;
  }

  /* 详情 */
  .workDetail {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }

  .workDetail-left {}

  .workDetail-left-de {
    font-size: 16px;
    color: #333;
    text-align: left;
    margin-top: 530px;
  }

  .workDetail-left-imgzt {
    position: relative;
  }

  .workDetail-left-img {
    width: 770px;
    height: 524px;
  }

  .workDetail-left-type {
    background: rgba(29, 31, 25, 0.4);
    border: 1px solid #515151;
    color: #fff;
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 22px;
    padding: 5px 10px;
  }

  .workDetail-left-big {
    position: absolute;
    top: 500px;
    left: 10px;
    color: #fff;
    display: flex;
    font-size: 14px;
    line-height: 14px;
    z-index: 999;
  }

  .workDetail-left-big-img {
    margin-right: 10px;
    width: 14px;
    height: 14px;
  }

  .workDetail-rig {
    /* border: 1px solid red; */
    width: 370px;
  }

  .workDetail-rig-big {
    line-height: 20px;
    font-weight: bold;
    color: #333;
    font-size: 24px;
    display: flex;
    margin: 20px 0px;
  }

  .workDetail-rig-big-img {
    width: 20px;
    height: 20px;
  }

  .workDetail-rig-titimg {
    color: #EF2A2A;
    background: #FFE8E8;
    line-height: 14px;
    font-size: 14px;
    font-weight: bold;
    padding: 15px 20px;
    display: flex;
    /* width: 100%; */
    border-radius: 5px;

  }

  .workDetail-rig-img {
    width: 13px;
    height: 14px;
    margin-right: 10px;
  }

  .workDetail-rig-qkl {
    background-color: #FAFAFA;
    color: #333;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }

  .workDetail-rig-jbxx {
    color: #333;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }

  .workDetail-rig-qkl-left {
    text-align: left;
    line-height: 30px;
    margin: 10px 0px 20px 45px;
    width: 50%;
  }

  .workDetail-rig-qkl-right {
    text-align: left;
    line-height: 30px;
    margin: 10px 0px 20px 0px;
    width: 50%;
  }

  .workDetail-rig-sqsqbtn {
    background-color: #C33232;
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding: 15px 0px;
    margin: 30px 0px;
    border-radius: 10px;
  }

  .workDetail-rig-detail {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin-left: 15px;
  }

  /* 推荐作品 */
  .liststy {
    margin: 80px auto;
    max-width: 1200px;
  }

  .liststy-top-left {
    display: flex;
    border-bottom: 2px solid #E6E6E6;
    padding-bottom: 20px;
  }

  .shutiao {
    width: 5px;
    height: 22px;
    background-color: #000000;

  }

  .liststy-top-left-tit {
    color: #333;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    margin-left: 10px;
  }

  .liststy-bot {
    margin-top: 30px;
  }

  .liststy-bot-xh {

    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    text-decoration: none;


  }

  .liststy-bot-sty {
    background-color: #F6F6F6;
    border-radius: 20px;
    width: 226px;
    color: #333;
    margin-bottom: 20px;
    margin-left: 12px;
  }

  .liststy-bot-xh-img {
    width: 226px;
    height: 214px;
  }

  .liststy-bot-xh-tit {
    margin: 10px 0px;
    text-align: left;
  }

  .liststy-bot-xh-con {
    display: flex;
    margin-left: 10px;
    margin-bottom: 10px;
  }



/* 放大的图片，通过定位将左上角定位到(0,0) */
.rightImg {
  display: inline-block;
  width: 1540px;
  height: 1060px;
/*  width: 800px;
  height: 800px; */
  position: absolute;
  top: 0;
  left: 0;
} /* 右边的区域图片放大空间 */
.right {
  margin-left: 770px;
  width: 400px;
  height: 400px;
  /* border: 1px solid red; */
  position: fixed;
  overflow: hidden;
} /* 一个最高层层罩 */
.maskTop {
  width: 770px;
  height: 524px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
} /* 层罩，通过定位将左上角定位到(0,0) */
.top {
  width: 200px;
  height: 200px;
  background-color: lightcoral;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
} /* 原图的显示 */
.leftImg {
  width: 770px;
  height: 524px;
  display: inline-block;
} /* 原图的容器 */
.left {
  width: 770px;
  height: 524px;
  /* border: 1px solid teal; */
  float: left;
  position: relative;
}

</style>
