<template>
  <div>
    <div class="mjliststy">
      <div class="mjliststy-top">
        <div class="mjliststy-top-left">
          <div class="shutiao"></div>
          <div class="mjliststy-top-left-tit">名家作品</div>
        </div>
        <div class="mjliststy-top-rig">
          <div v-for="(item, index) in worktypelist" :key="index" @click="typework(item,index)"
            :class="tabindex == index ? 'mjliststy-top-rig-tjlsitwei' : 'mjliststy-top-rig-tjlsit'">{{ item.name }}</div>
        </div>
      </div>
      <div class="mjliststy-bot">
        <div class="mjliststy-bot-sty" v-for="(item, index) in worklist" :key="index" @click="qzDetail(item)">
          <img class="mjliststy-bot-xh-img" :src="item.images[0]" alt="">
          <div class="mjliststy-bot-xh-tit">{{ item.title }}</div>
        </div>
				<div v-show='worklist.length < 1' style='text-align: center;width: 100%;'>
							 <el-empty description="暂无数据"></el-empty>
				</div>
      </div>
      <el-pagination background layout="total, prev, pager, next" :total="total" :page-size="pageSize"
        @current-change="currentChange" @size-change="handleSizeChange" :current-page="currentPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabindex: 0,
			cate_id:'',
      worktypelist: [],
      worklist:[],
      pageSize: 20,
      currentPage: 1,
      total:'0',
    }
  },
  mounted(){
    this.cate_list()
    this.works_list('')
  },
  methods: {
    //分类
    cate_list() {
      this.$http.get(this.constant.loadurl.get_shop_cate_list, {

      }).then(res => {
        if (res.data.code == 0) {
          this.worktypelist = res.data.data
          var tj = {
            name:"推荐",
            id:'',
          }
          this.worktypelist.unshift(tj)
        }
      })
    },
    // 名家列表 
    works_list(id){
    
      this.$http.post(this.constant.loadurl.get_works_list, {
        cate_id:id,
        page:this.currentPage,
        limit:this.pageSize,
      }).then(res => {
        this.worklist = res.data.data
        this.total = res.data.total
      })
    },
    //点击作品进入详情页
    qzDetail(r) {
      // this.$router.push({
      //   name: 'workdetail'
      // })
      this.$router.push({path:'/workdetail',query:{id:r.id}}) 
    },
    // 点击作品类型进行切换
    typework(r,index) {
			this.cate_id = r.id
      this.works_list(r.id)
      this.tabindex = index
    },
    currentChange(e) {
      this.currentPage = e;
			this.works_list(this.cate_id)
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
  }
}
</script>

<style>
/* 分页 */
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #333333 !important;
  color: #fff !important;
}

.mjliststy {
  margin: 80px auto;
  max-width: 1200px;
  text-align: center;
}

.mjliststy-top {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #E6E6E6;
  padding-bottom: 20px;
}

.mjliststy-top-left {
  display: flex;
}

.shutiao {
  width: 5px;
  height: 22px;
  background-color: #000000;

}

.mjliststy-top-left-tit {
  color: #333;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  margin-left: 10px;
}

.mjliststy-top-rig {
  display: flex;
}

.mjliststy-top-rig-tjlsit {
  color: #333;
  font-size: 14px;
  line-height: 22px;
  background: rgba(0, 0, 0, 0.08);
  margin: 0px 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.mjliststy-top-rig-tjlsitwei {
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  background-color: #252525;
  margin: 0px 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.mjliststy-bot {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: inherit;
  flex-wrap: wrap;
  text-decoration: none;
}

.mjliststy-bot-sty {
  background-color: #F6F6F6;
  border-radius: 10px;
  width: 284px;
  color: #333;
  margin-bottom:20px;
  margin-left: calc((100% - (284px * 4) ) / 3);
}
.mjliststy-bot-sty:nth-child(4n+1){
	margin-left: 0;
}
.mjliststy-bot-xh-img {
  width: 284px;
  height: 270px;
	object-fit: contain;
  border-radius: 10px 10px 0 0;
}

.mjliststy-bot-xh-tit {
  margin: 10px 0px;
  font-size: 18px;
  text-align: left;
}
</style>
