<template>
	<div class="content">
		<div class="gobacksty">
			<div @click="tztop" class="gobacksty-top">专题活动</div>
			<div class="gobacksty-detail">活动详情</div>
		</div>
		<div class="wzcontent">
			<div class="wzcontent-top" @click="copy()">
				<div>分享</div>
				<img src="http://ryfw51f4p.hn-bkt.clouddn.com/wzDetail/fenxiang.png" />
			</div>
			<el-divider></el-divider>
			<div class="wzcontent-title">{{wzDetail.title}}</div>
			<div class="wzcontent-jj">
				<div>{{wzDetail.write_time}}</div>
				<div>来源：{{wzDetail.source}}</div>
			<!-- 	<div>阅读：331</div>
				<div>编辑：付园园</div> -->
			</div>
			<p class="wzcontent-cont" v-html="wzDetail.content"></p>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				detailId: '',
        wzDetail:{}
			}
		},
		created() {

		},
		mounted() {
			this.detailId = this.$route.params.detailId;
			this.getDetail(this.detailId)
			
		},
		methods: {
      getDetail(id){
        this.$http.get(this.constant.loadurl.get_activity_info,{params:{id:id}}).then(res => {
            if (res.data.code == 0) {
        			this.wzDetail=res.data.data
            } else {
              this.$message({
                message: res.data.msg || res.data.message,
                type: "warning"
              })
            }
        });
      },
      // 复制当前网页链接
      copy() {
        var domUrl = document.createElement("input");
      
        domUrl.value = window.location.href;
      
        domUrl.id = "creatDom";
      
        document.body.appendChild(domUrl);
      
        domUrl.select(); // 选择对象
      
        document.execCommand("Copy"); // 执行浏览器复制命令
      
        let creatDom = document.getElementById("creatDom");
      
        creatDom.parentNode.removeChild(creatDom);
      
        this.$message({
          message: "链接已复制",
          type: "success"
        })
      },
			// 返回上一页
			tztop() {
				this.$router.push({
					name: 'ztactivities'
				})
			},
		}
	}
</script>

<style scoped>
	.content {
		padding: 0px 0px 50px 0px;
		max-width: 1200px;
	}

	.gobacksty {
		max-width: 1200px;
		/* margin: 30px auto; */
		margin: 30px auto;
		height: 21px;
		line-height: 21px;
		display: flex;
		font-size: 14px;
		cursor: pointer;
	}

	.gobacksty-top {
		background-color: #F6F6F6;
		color: #000;
		padding: 0px 20px;
	}

	.gobacksty-detail {
		background: url("https://mingjiahome.zhyell.com/flag.png") no-repeat;
		background-size: 100% 100%;
		width: 114px;
		height: 21px;
		text-align: center;
		color: #fff;
	}

	.wzcontent {
		width: 100%;
		padding: 30px 30px 100px 30px;
		margin: 0 auto;
		background-color: #F5F5F5;
	}

	.wzcontent-top {
		color: #333;
		font-size: 14px;
		line-height: 14px;
		display: flex;
		justify-content: flex-end;
	}

	.wzcontent-top img {
		width: 14px;
		height: 14px;
		margin-left: 10px;
	}

	.wzcontent-title {
		color: #333333;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
		margin: 20px 0px;
	}

	.wzcontent-jj {
		color: #777;
		font-size: 14px;
		display: flex;
		justify-content: center;
	}

	.wzcontent-jj div {
		margin: 0px 20px;
	}

	.wzcontent-cont {
		color: #333;
		font-size: 14px;
		line-height: 24px;
		text-indent: 30px;
	}
</style>