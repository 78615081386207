<!-- 顶部组件 -->
<template>
	<div>
<!-- 		<nav class="topNavBox">
			<div class="left_box">
				<div>设为首页</div>
				<span class="icon_clumn">|</span>
				<div>加入收藏</div>
				<span class="icon_clumn">|</span>
				<div>联系我们</div>
				<span class="icon_clumn">|</span>
				<div>我的订单</div>
			</div>
			<div class="right_box">
				<div>简体</div>
				<span class="icon_clumn">|</span>
				<div>繁体</div>
				<span class="icon_clumn">|</span>
				<div>数字人讲解</div>
				<span class="icon_clumn">|</span>
				<div>无障碍浏览</div>
				<span class="icon_clumn">|</span>
				<div>登录</div>
			</div>
		</nav> -->
		<div class="header_two" v-if='homeMjList.master'
			:style="{backgroundImage: 'url('+ homeMjList.master[0].official_background_img +')' }">
			<!-- <img :src=" homeMjList.master[0].pavillion_url " alt=""> -->
			<div class="zuozhe">
				<!-- <div class="Zname" v-if='homeMjList.master'>{{ homeMjList.master[0].name }}</div> -->
				<!-- <span class="divCom">官方网站</span> -->
			</div>
			<div class="https" v-if='homeMjList.master'>
				
				<!-- https://lcdianlidev.zhyell.com?id={{$route.query.id}} -->
				<!-- {{ homeMjList.master[0].pavillion_url }} -->
			</div>
		</div>
		<div class="navigationContainer">
			<el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" @select="handleSelect"
				background-color="#000" text-color="#fff" active-text-color="#ffffff" :router="true">
				<!--   <el-menu-item index="/FamousIndex"  >
                   名家首页
                </el-menu-item>
                <el-menu-item index="/mjfiles">
                   名家档案
                </el-menu-item>
                <el-menu-item index="/Works">作品</el-menu-item>
                <el-menu-item index="/mjhall">名家讲堂</el-menu-item>
                <el-menu-item index="/mjzx">相关资讯</el-menu-item>
                <el-menu-item index="/shoppingmall">线上商城</el-menu-item> -->
				<el-menu-item v-for="item in NavList" :key='item.index' :index='item.index'
					:route="{ path: item.index, query: { id: item.id } }">{{item.name}}</el-menu-item>
			</el-menu>
		</div>
		<div>
			<router-view></router-view>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				msg: 'jaja',
				cityName: '石家庄',
				userName: '名人名家',
				activeIndex2: '',
				input3: '',
				id: '',
				NavList: [{
					index: '/FamousIndex',
					name: '首页',
					id: this.$route.query.id || ''
				}, {
					index: '/mjfiles',
					name: '名家档案',
					id: this.$route.query.id || ''
				}, {
					index: '/Works',
					name: '作品',
					id: this.$route.query.id || ''
				}, {
					index: '/mjhall',
					name: '名家讲堂',
					id: this.$route.query.id || ''
				}, {
					index: '/mjzx',
					name: '相关资讯',
					id: this.$route.query.id || ''
				// }, {
				// 	index: '/shoppingmall',
				// 	name: '线上商城',
				// 	id: this.$route.query.id || ''
				// }],
				}],
				homeMjList: [],
				imgsrc: 'https://mingjiaoss.zhyell.com/2023-10-25_1698199932_6538797c86f09.png'
			}
		},
		mounted() {
			this.id = this.$route.query.id;
			this.mjhome()
		},
		methods: {
			// 名家首页数据
			mjhome() {
				this.$http.get(this.constant.loadurl.get_ming_index + '?m_id=' + this.id).then(res => {
					if (res.data.code == 0) {
						this.homeMjList = res.data.data;
					}
				})
			},
			handleSelect(r, e) {
				this.activeIndex2 = r
			},
			names(res) {
				console.log(res, '选中');
			}
		}
	}
</script>
<style>
	.topNavBox {
		/* min-width: 40rem; */
		padding: .5rem 24rem;
		display: flex;
		justify-content: space-between;
		background: #EBEBEB;
	}

	.left_box,
	.right_box {
		display: flex;
		font-size: .875rem;
		color: #333333;
	}

	.left_box,
	.right_box div {
		white-space: nowrap;
	}

	.icon_clumn {
		padding: 0rem .5rem;
	}

	.header_two {
		display: flex;
		flex-direction: column;
	 
		min-height: 13.75rem;
		max-width: 110rem;
		padding: 0rem 22.8rem;
		background-image: url('https://mingjiaoss.zhyell.com/2023-10-25_1698199932_6538797c86f09.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		justify-content: space-evenly;
	}

	.zuozhe {
		display: flex;
		align-items: baseline;
		color: #ffffff;
	}

	.Zname {
		font-size: 18px;
		font-weight: bold;
	}

	.divCom {
		margin-left: 20px;
		font-size: 14px;
	}

	.https {
		color: #ffffff;
	}

	.el-input-group__append {
		/* width: 5rem !important; */
		height: 2.7rem !important;
		color: #ffffff !important;
		background-color: #BB2929 !important;
	}

	.city {
		min-width: 8.4375rem;
		height: 2.5rem;
		margin-left: 6rem;
		line-height: 2.5rem;
		border: 1px solid #E7E6E6;
		border-radius: 20px;
	}

	.logo img {
		width: 20.4375rem;
		height: 4.6875rem;
		object-fit: fill;
	}
	.navigationContainer {
		 /* width: 100%; */
		/* padding:0rem 22.9375rem ; */
		display: flex;
		justify-content: center;
	}
	.navigationContainer .el-menu-demo {
		width: 100%; 
		/* margin:auto; */
		padding: 0rem 21rem;
	}
	.el-menu-item.is-active {
		background-color: #B62726 !important;
		border-bottom: 0px !important;
	}

	.el-menu-item {
		padding: 0 2.25rem;
	}

	.el-dropdown-link {
		cursor: pointer;
		color: #409EFF;
	}

	.el-icon-arrow-down {
		font-size: 12px;
	}

	.demonstration {
		display: block;
		color: #8492a6;
		font-size: 14px;
		margin-bottom: 20px;
	}

	.el-dropdown {
		vertical-align: top;
	}

	.el-dropdown+.el-dropdown {
		margin-left: 15px;
	}

	.el-icon-arrow-down {
		font-size: 12px;
	}

	.el-input__inner {
		border-radius: 0 !important;
		border: 0 !important;
	}

	.el-radio-button {
		padding: 0 !important;
	}
</style>